import axios from 'axios'
import { request } from '@/common/async.js'

/**
 * @description fetch summary of the project
 * @param {number} projectId id of the project
 * @returns {Promise} axios request promise
 */
export function getProjectSummary(projectId) {
  return axios.get(`/admin/projects/${projectId}/summary`)
}

/**
 * @description edit a new media project
 * @returns {Promise} axios request promise
 */
export function updateProject({ id, name, owner, supports_media_objects, description, type = 'project' }) {
  const projectData = new FormData()
  projectData.append('id', id)
  projectData.append('name', name)
  projectData.append('institution_id', owner)
  projectData.append('source', 'ssimata')

  if (type === 'project') {
    projectData.append('description', description)
    projectData.append('supports_media_objects', supports_media_objects)
  }
  return request({ url: `/admin/projects/${id}`, method: 'PUT', data: projectData })
}
