import bAlert from 'forum-elements/src/components/Alert/Alert'
import bButton from 'forum-elements/src/components/Button/Button'
import bButtonGroup from 'forum-elements/src/components/Button/ButtonGroup'
import bCard from 'forum-elements/src/components/Card/Card'
import bCardBody from 'forum-elements/src/components/Card/CardBody'
import bCardHeader from 'forum-elements/src/components/Card/CardHeader'
import bCardFooter from 'forum-elements/src/components/Card/CardFooter'
import bCollapse from 'forum-elements/src/components/Collapse/Collapse'
import bDropdown from 'forum-elements/src/components/Dropdown/Dropdown'
import bDropdownDivider from 'forum-elements/src/components/Dropdown/DropdownDivider'
import bDropdownItem from 'forum-elements/src/components/Dropdown/DropdownItem'
import bForm from 'forum-elements/src/components/Form/Form'
import bFormCheckbox from 'forum-elements/src/components/Form/FormCheckbox'
import bFormCheckboxGroup from 'forum-elements/src/components/Form/FormCheckboxGroup'
import bFormGroup from 'forum-elements/src/components/Form/FormGroup'
import bFormInput from 'forum-elements/src/components/Form/FormInput'
import bFormInvalidFeedback from 'forum-elements/src/components/Form/FormInvalidFeedback'
import bFormRadio from 'forum-elements/src/components/Form/FormRadio'
import bFormRadioGroup from 'forum-elements/src/components/Form/FormRadioGroup'
import bFormSelect from 'forum-elements/src/components/Form/FormSelect'
import bFormTextarea from 'forum-elements/src/components/Form/FormTextarea'
import bInputGroup from 'forum-elements/src/components/InputGroup/InputGroup'
import bInputGroupAddon from 'forum-elements/src/components/InputGroup/InputGroupPrepend'
import bInputGroupButton from 'forum-elements/src/components/InputGroup/InputGroupAppend'
import bPopover from 'forum-elements/src/components/Popover/Popover'
import bTab from 'forum-elements/src/components/Tabs/Tab.vue'
import bTabs from 'forum-elements/src/components/Tabs/Tabs.vue'
import BProgress from 'forum-elements/src/components/Progress/Progress'

export default function bootstrapInclude(Vue) {
  Vue.component('BAlert', bAlert)
  Vue.component('BButton', bButton)
  Vue.component('BButtonGroup', bButtonGroup)
  Vue.component('BCard', bCard)
  Vue.component('BCardBody', bCardBody)
  Vue.component('BCardHeader', bCardHeader)
  Vue.component('BCardFooter', bCardFooter)
  Vue.component('BCollapse', bCollapse)
  Vue.component('BDropdown', bDropdown)
  Vue.component('BDropdownDivider', bDropdownDivider)
  Vue.component('BDropdownItem', bDropdownItem)
  Vue.component('BForm', bForm)
  Vue.component('BFormCheckbox', bFormCheckbox)
  Vue.component('BFormCheckboxGroup', bFormCheckboxGroup)
  Vue.component('BFormGroup', bFormGroup)
  Vue.component('BFormInput', bFormInput)
  Vue.component('BFormInvalidFeedback', bFormInvalidFeedback)
  Vue.component('BFormRadio', bFormRadio)
  Vue.component('BFormRadioGroup', bFormRadioGroup)
  Vue.component('BFormSelect', bFormSelect)
  Vue.component('BFormTextarea', bFormTextarea)
  Vue.component('BInputGroup', bInputGroup)
  Vue.component('BInputGroupAddon', bInputGroupAddon)
  Vue.component('BInputGroupButton', bInputGroupButton)
  Vue.component('BPopover', bPopover)
  Vue.component('BTab', bTab)
  Vue.component('BTabs', bTabs)
  Vue.component('BProgress', BProgress)
}
