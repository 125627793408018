<template>
  <confirmation-modal
    :loading="isModalLoading"
    :error="modalErrorMessage"
    :title="$t('PERMISSION_MODALS.DELETE_PERMISSION_TITLE')"
    :yes-btn="$t('PERMISSION_MODALS.DELETE_BUTTON')"
    :no-btn="$t('PERMISSION_MODALS.CANCEL_BUTTON')"
    @close="closeModal"
    @yes="handleDeleteClick"
  >
    <div>
      Are you sure you want to delete the following users from <strong>{{ projectName }}</strong
      >:
      <ul>
        <li v-for="(name, index) in userNames" :key="index">{{ name }}</li>
      </ul>
    </div>
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from '@/components/common/modals/ConfirmationModal'
import { deletePermissions } from '@/services/admin/projectPermissions'
import { pushToDatalayer } from '@/common/gtagmanager'
import { showToastSuccess } from '@/utils/toast'

export default {
  name: 'DeleteUserPermissions',
  components: {
    ConfirmationModal
  },
  props: {
    permissionIds: {
      type: Array,
      required: true
    },
    projectName: {
      type: String,
      required: true
    },
    userNames: {
      type: Array,
      required: true
    },
    namespace: {
      type: String,
      default: 'projects'
    }
  },
  data() {
    return {
      isModalLoading: false,
      modalErrorMessage: ''
    }
  },
  computed: {
    category() {
      return this.namespace === 'projects' ? 'Project Permissions' : 'List Permissions'
    }
  },
  mounted() {},
  methods: {
    async handleDeleteClick() {
      this.isModalLoading = true
      const deletingFields = this.permissionIds.map(id => deletePermissions(Number(this.$route.params.id), id))

      try {
        await Promise.all(deletingFields)
        showToastSuccess(this.$t('PERMISSION_MODALS.DELETE_SUCCESS_MSG'))
        this.closeModal()
      } catch (err) {
        this.modalErrorMessage = this.$t('PERMISSION_MODALS.DELETE_FAIL_MSG')
      }
      this.isModalLoading = false
      this.$emit('delete-finished')
      this.trackDeleteUsers(this.permissionIds.length)
    },
    closeModal() {
      this.$emit('close')
    },
    trackDeleteUsers(userCount) {
      pushToDatalayer(this.category, 'Deleting Users', null, userCount)
    }
  }
}
</script>

<style lang="scss" scoped></style>
