import axios from 'axios'

const MAX_FAILURES = 3

// Helper functions
const fetchStatus = async job => {
  return await axios.get(`/jobs/${job}/status`).then(({ data }) => data.status)
}

const fetchResponse = async (job, isExcel) => {
  return await axios.request({ url: `/jobs/${job}`, responseType: isExcel ? 'blob' : '' })
}

const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const appendAsyncToUrl = url => {
  if (url.includes('?')) {
    return url + '&async=true'
  }

  return url + '?async=true'
}

export const makeAsyncRequest = async params => {
  const async = {
    ...params,
    url: appendAsyncToUrl(params.url)
  }

  const { data } = await axios.request(async)

  let { job_id: job, status } = data

  let failureCount = 0
  while (status !== 'complete') {
    await timeout(1000)

    try {
      status = await fetchStatus(job)
    } catch (e) {
      failureCount += 1
      if (failureCount > MAX_FAILURES) {
        throw e
      }
    }
  }

  return job
}

// Wrapper function
export const request = async (params, isExcel) => {
  const job = await makeAsyncRequest(params)
  return await fetchResponse(job, isExcel)
}
