export const defaultTiming = () => ({
  timings: {}
})

const timing = {
  namespaced: true,
  state: defaultTiming(),
  mutations: {
    START_TIMING(state, id) {
      state.timings[id] = { start: new Date() }
    },
    STOP_TIMING(state, id) {
      if (state.timings[id]) {
        state.timings[id].end = new Date()
      }
    },
    RESUME_TIMING(state, id) {
      if (state.timings[id]) {
        delete state.timings[id].end
      }
    },
    CLEAR_TIMING(state, id) {
      delete state.timings[id]
    },
    RESET_STATE(state) {
      Object.assign(state, defaultTiming())
    }
  },
  actions: {
    startTiming(context, id) {
      context.commit('START_TIMING', id)
    },
    stopTiming(context, id) {
      context.commit('STOP_TIMING', id)
    },
    resumeTiming(context, id) {
      context.commit('RESUME_TIMING', id)
    },
    clearTiming(context, id) {
      context.commit('CLEAR_TIMING', id)
    },
    clearTimings(context) {
      context.commit('RESET_STATE')
    },
    getElapsedTime(context, id) {
      const timing = context.state.timings[id]
      if (timing) {
        const start = new Date(timing.start)
        const end = timing.end ? timing.end : new Date()
        return end - start
      }
    }
  },
  getters: {}
}

export default timing
