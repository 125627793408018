function blankOptions({ type, blankValue, nonBlankValue }) {
  return [
    {
      label: 'Blank',
      component: 'label-option',
      filterType: type,
      value: blankValue,
      identifier: 'apply-filter-blank'
    },
    {
      label: 'Non Blank',
      component: 'label-option',
      filterType: type,
      value: nonBlankValue,
      identifier: 'apply-filter-non-blank'
    }
  ]
}

const thumbnailFilter = [
  ...blankOptions({
    type: 'thumbnail',
    blankValue: false,
    nonBlankValue: true
  })
]

const boolFilter = [
  { component: 'label-option', filterType: 'nullbool', label: 'True', value: true, identifier: 'apply-filter-true' },
  { component: 'label-option', filterType: 'nullbool', label: 'False', value: false, identifier: 'apply-filter-false' }
]

const nullableBoolFilter = [
  ...boolFilter,
  ...blankOptions({
    type: 'nullbool',
    blankValue: 'BLANK-BOOL',
    nonBlankValue: 'NON-BLANK-BOOL'
  })
]

const dateFilter = [
  { component: 'date-range-option', label: ['From', 'To'], filterType: 'date', identifier: 'apply-filter-ltgt' }
  // { component: 'date-option', label: 'On', filterType: 'date', identifier: 'apply-filter-eq' }
]

const nullableDateFilter = [
  ...dateFilter,
  ...blankOptions({
    type: 'nulldate',
    blankValue: 'BLANK-DATE',
    nonBlankValue: 'NON-BLANK-DATE'
  })
]

const publishFilter = [
  {
    component: 'label-option',
    filterType: 'list',
    label: 'Published',
    value: ['Published'],
    identifier: 'apply-filter-published'
  },
  {
    component: 'label-option',
    filterType: 'list',
    label: 'Not Published',
    value: ['Not Published'],
    identifier: 'apply-filter-not-published'
  },
  {
    component: 'label-option',
    filterType: 'list',
    label: 'Suppressed',
    value: ['Suppressed'],
    identifier: 'apply-filter-suppressed'
  }
]

const textFilter = [
  { component: 'text-option', filterType: 'string', identifier: 'apply-filter-eq' },
  ...blankOptions({
    type: 'nullstring',
    blankValue: 'BLANK-STR',
    nonBlankValue: 'NON-BLANK-STR'
  })
]

const linkedFieldFilter = [
  { component: 'linked-field-text-option', filterType: 'string', identifier: 'apply-filter-eq' },
  ...blankOptions({
    type: 'nullstring',
    blankValue: 'BLANK-STR',
    nonBlankValue: 'NON-BLANK-STR'
  })
]

const numericFilter = [
  { component: 'numeric-range-option', label: ['<', '>'], filterType: 'numeric', identifier: 'apply-filter-ltgt' },
  { component: 'numeric-option', label: '=', filterType: 'numeric', identifier: 'apply-filter-eq' }
]

const nullableNumericFilter = [
  ...numericFilter,
  ...blankOptions({
    type: 'nullnumeric',
    blankValue: 'BLANK-NUM',
    nonBlankValue: 'NON-BLANK-NUM'
  })
]

const alphaNumericFilter = [
  {
    component: 'numeric-range-option',
    fieldType: 'text',
    label: ['≤', '≥'],
    filterType: 'numeric',
    identifier: 'apply-filter-ltgt'
  },
  { component: 'numeric-option', fieldType: 'text', label: '=', filterType: 'numeric', identifier: 'apply-filter-eq' },
  ...blankOptions({
    type: 'nullnumeric',
    blankValue: 'BLANK-NUM',
    nonBlankValue: 'NON-BLANK-NUM'
  })
]

const filterMap = {
  id: numericFilter,
  sequence_number: numericFilter,
  primary_image: boolFilter,
  media_count: numericFilter,
  list: publishFilter,
  nullstring: textFilter,
  nullnumeric: nullableNumericFilter,
  nullalphanum: alphaNumericFilter,
  nulllink: linkedFieldFilter,
  nullbool: nullableBoolFilter,
  nullablebooleancolumn: nullableBoolFilter,
  date: nullableDateFilter,
  nulldate: nullableDateFilter,
  thumbnailcolumn: thumbnailFilter
}

const FilterConfig = column => {
  let type = column.filter ? column.filter.type : column.xtype || column.dataIndex
  if (['sequence_number', 'primary_image', 'media_count'].indexOf(column.dataIndex) >= 0) {
    type = column.dataIndex
  }

  if (type === 'profile') {
    return { filterComponent: 'profile-filter' }
  } else if (type === 'valueindex') {
    return { filterComponent: 'category-filter' }
  }

  return {
    filterComponent: 'single-select-filter',
    filterOptions: filterMap[type]
  }
}

export default FilterConfig
