<template>
  <div class="thumbnail-item">
    <image-with-loader v-bind="getItemThumbnail(params.data)" />
    <span v-if="isMasterRecord" class="master-record-icon">
      <span data-automation-component="master-record-indicator" data-point="master-record-indicator">★</span>
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
import { getItemThumbnail } from '../../../common/thumbnail'
import ImageWithLoader from '../../../components/common/ImageWithLoader'
import { mapState } from 'vuex'

export default Vue.extend({
  components: {
    ImageWithLoader
  },
  computed: {
    ...mapState({
      masterRecords: state => state.projects.masterRecords
    }),
    projectId() {
      return Number(this.$route.params.id)
    },
    projectMasterRecord() {
      return this.masterRecords[this.projectId]
    },
    isMasterRecord() {
      return !!(this.projectMasterRecord && this.projectMasterRecord.id === this.params.data.id)
    }
  },
  methods: {
    getItemThumbnail
  }
})
</script>

<style scoped lang="scss">
.thumbnail-item {
  width: 80px;
  height: 64px;
  line-height: 64px;
  pointer-events: none;
  position: relative;
  text-align: center;
}

.master-record-icon {
  position: absolute;
  left: -7px;
  top: 0px;
}
</style>
