import axios from 'axios'

export function searchTGN({ name, sort, instituteName, pageNumber, pageSize = 25 }) {
  const form = new URLSearchParams()
  form.append('version', 1.5)
  form.append('name', name)
  form.append('sort', sort)
  form.append('instituteName', instituteName)
  form.append('pageNumber', pageNumber)
  form.append('pageSize', pageSize)
  return axios.post('/tgn/tgn/json/search?authToken=123', form)
}

export function getTgnDetails({ subjectid }) {
  return axios.get(`/tgn/tgn/json/subjectDetail?authToken=123&subjectid=${subjectid}`)
}
