import axios from 'axios'

export function getPresignedSpreadsheetUrl(token, file) {
  const fileExtension = file.name.match(/\.\w+$/)?.toString()
  let fileName = file.name?.replace(fileExtension, '')
  fileName = `${fileName}${fileExtension?.toLowerCase()}`
  return axios.post(
    `/import-workflow/v2/presignedurl/${fileName}`,
    {},
    {
      headers: {
        Authorization: token
      }
    }
  )
}

export function presignedUrlExistingProject({ token, project_id, file }) {
  return axios.post(
    `/v2/import/presignedurl/${file.name}`,
    { project_id: project_id, import_type: 'project' },
    {
      headers: {
        Authorization: token
      }
    }
  )
}

export function importErrorPresignedUrl({ token, job_id }) {
  return axios.get(`/v2/import/error/presignedurl/${job_id}`, {
    headers: {
      Authorization: token
    }
  })
}

/**
 * uploading a file with progress
 * @param {Object} params to post as form data
 * @param {function} onProgress callback for onProgress
 * @param {AxiosCancelToken} cancelToken
 */
export function uploadSpreadsheetFile({ url, fields }, file, onProgress) {
  const formData = new FormData()
  Object.keys(fields).forEach(key => {
    formData.append(key, fields[key])
  })
  formData.append('file', file)

  const options = {
    withCredentials: false,
    onUploadProgress(progressEvent) {
      const percentCompleted = (progressEvent.loaded * 100) / progressEvent.total
      if (onProgress) {
        onProgress(percentCompleted)
      }
    }
  }

  return axios.post(url, formData, options)
}

export async function startImportProcess(token, { key, workflow }) {
  return axios.post(
    `/import-workflow/v2/jobs/${workflow}`,
    {},
    {
      headers: {
        Authorization: token
      },
      params: {
        key
      }
    }
  )
}

export async function getStatusImportExport(token, workflow, existingProject) {
  const url = existingProject ? `/v2/jobs/${workflow}/status` : `/import-workflow/v1/status/${workflow}`
  return axios.get(url, {
    headers: {
      Authorization: token
    }
  })
}

export async function postWorkflowProject(token, workflow, data) {
  return axios.post(`/import-workflow/v1/projects/${workflow}`, data, {
    headers: {
      Authorization: token
    }
  })
}

export async function getError(token, workflow) {
  return axios.get(`/import-workflow/v1/errors/${workflow}`, {
    headers: {
      Authorization: token
    }
  })
}

export async function getMappings(token, workflow) {
  return axios.get(`/import-workflow/v2/mappings/${workflow}`, {
    headers: {
      Authorization: token
    }
  })
}

export async function postMappings(token, workflow, data) {
  const form = new FormData()
  form.append('data', JSON.stringify(data))
  return axios.post(`/import-workflow/v2/mappings/${workflow}`, data, {
    headers: {
      Authorization: token
    }
  })
}
