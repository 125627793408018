import axios from 'axios'

/**
 * @description fetch institutions
 * @returns {Promise} axios request promise
 */
export function getInstitutions() {
  return axios.get('/admin/institutions')
}

/**
 * @description fetch institution accounts
 * @returns {Promise} axios request promise
 * @param institutionId
 * @param {object} options axios get request options
 *   options = { params: {
      skip_meta: Boolean // skip metadata information or not
      sort: String
      dir: String
    }}
 */
export function getInstitutionAccounts(institutionId, options) {
  const queryParams = new URLSearchParams(options.params).toString()
  return axios.get(`/institutions/${institutionId}/accounts?${queryParams}`)
}
