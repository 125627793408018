import axios from 'axios'

export function searchAAT({ name, sort, instituteName, pageNumber, pageSize = 25 }) {
  const params = {
    name,
    sort,
    instituteName,
    pageNumber,
    pageSize,
    version: 1.5,
    authToken: 123
  }
  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/aat/aat/json/search?${queryParams}`)
}

export function getAatDetails({ subjectid }) {
  return axios.get(`/aat/aat/json/subjectDetail?authToken=123&subjectid=${subjectid}`)
}
