import { getVocabProjects, getVocabUsage, getVocabUsageDetails } from '../services/lists.js'
import { getFieldDefinitions, deleteFieldDefinition } from '@/pages/project-settings/services/projectFields.js'
import { getPermissions } from '@/services/admin/projectPermissions'
import { getTerms } from '../services/mfcl'
import {
  createPermission,
  deletePermissions,
  updatePermission,
  addShareListPermissions
} from './../services/listPermissions'

const CONTAINER_LIST_TYPE = 110

const defaultLists = () => ({
  order: 'asc',
  items: [],
  definitions: [],
  permissions: [],
  currentRoute: '/lists'
})

const lists = {
  namespaced: true,
  state: defaultLists(),
  mutations: {
    SET_LIST(state, projects) {
      state.items = projects
    },
    TOGGLE_PROJECT_SORT(state) {
      state.order = state.order === 'asc' ? 'desc' : 'asc'
    },
    SET_DEFINITIONS(state, definitions) {
      state.definitions = definitions
    },
    ADD_DEFINITION(state, definition) {
      state.definitions.push(definition)
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
    SET_CURRENT_ROUTE(state, route) {
      state.currentRoute = route
    },
    RESET_STATE(state) {
      Object.assign(state, defaultLists())
    }
  },
  actions: {
    async fetchLists(context) {
      const res = await getVocabProjects()
      context.commit('SET_LIST', res.data.projects)
    },
    toggleProjectSort(context) {
      context.commit('TOGGLE_PROJECT_SORT')
    },
    async fetchDefinitions(context, options) {
      const { projectId } = options
      const res = await getFieldDefinitions(projectId)
      context.commit('SET_DEFINITIONS', res.data.items)
    },
    setCurrentRoute(context, route) {
      context.commit('SET_CURRENT_ROUTE', route)
    },
    fetchVocabUsage(context, options) {
      const { listId } = options
      return getVocabUsage(listId)
    },
    fetchVocabUsageDetails(context, options) {
      const { listId } = options
      return getVocabUsageDetails(listId)
    },
    async fetchPermissions(context, options) {
      const { projectId } = options
      const res = await getPermissions(projectId)
      context.commit('SET_PERMISSIONS', res.data.project_permissions)
    },
    deleteUserPermissions(context, options) {
      const { projectId, permissionId } = options
      return deletePermissions(projectId, permissionId)
    },
    async deleteDefinition(context, options) {
      const { projectId, fieldId } = options
      await deleteFieldDefinition(projectId, fieldId)
    },
    fetchListTerms(context, options) {
      const { listId, params } = options
      return getTerms(listId, { params })
    },
    updatePermission(context, params) {
      return updatePermission(params)
    },
    createPermission(context, params) {
      return createPermission(params)
    },
    addShareListPermissions(context, params) {
      return addShareListPermissions(params)
    }
  },
  getters: {
    sortedLists: state =>
      [...state.items].sort((a, b) => {
        const sortOrder = state.order === 'asc' ? 1 : -1
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase()) * sortOrder
      }),
    getList: state => projectId => {
      return state.items.find(item => item.id === projectId) || {}
    },
    projectName: (state, getters) => projectId => {
      return getters.getList(projectId).name
    },
    containerList: (state, getters) => projectId => {
      return getters.getList(projectId).list_type_id === CONTAINER_LIST_TYPE
    },
    listScope: (state, getters) => projectId => {
      return getters.getList(projectId).list_scope
    }
  }
}

export default lists
