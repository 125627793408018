function isEscapable(response) {
  return response.headers['content-type'] && !response.headers['content-type'].match('application/octet-stream')
}

// remove once services stop escaping characters
export function replaceEscaped(response) {
  if (response.data && isEscapable(response)) {
    let fixed = JSON.stringify(response.data)
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '\\"')
    response.data = JSON.parse(fixed)
  }
  return response
}
