import axios from 'axios'
import { request } from '@/common/async.js'

/**
 * @description fetch a list of permissions for a project
 * @param {Number} projectId id of the project to add a permissin to
 * @returns {Promise} axios request promise
 */
export function getPermissions(projectId) {
  return request({ url: `/admin/projects/${projectId}/permissions`, method: 'GET' })
}

/**
 * @description get user permission for a given permission id
 * @param {Number} projectId id of the project to remove permission from
 * @param {Number} permissionId id of the permission delete
 * @returns {Promise} axios request promise
 */
export function getPermission(projectId, permissionId) {
  return axios.get(`/admin/projects/${projectId}/permissions/${permissionId}`)
}

/**
 * @description delete user permissions for a project
 * @param {Number} projectId id of the project to remove permission from
 * @param {Number} permissionId id of the permission delete
 * @returns {Promise} axios request promise
 */
export function deletePermissions(projectId, permissionid) {
  return axios.delete(`/admin/projects/${projectId}/permissions/${permissionid}`)
}

function createPermissionFormData(fields) {
  const { role, users, forms, targets, defaults, filter, custom, values, editFilter } = fields
  const form = new FormData()
  form.append('role_id', role)
  form.append('profile_id', users.join('|'))
  if (forms.length > 0) {
    forms.forEach(formId => {
      form.append('form_ids', formId)
    })
  }
  if (targets.length > 0) {
    targets.forEach(targetId => {
      form.append('publishing_target_ids', targetId)
    })
  }
  if (defaults.length > 0) {
    defaults.forEach(targetId => {
      form.append('default_publishing_target_ids', targetId)
    })
  }
  form.append('base_filter', filter)
  form.append('custom_field', custom)
  form.append('custom_value', values.join('|'))
  form.append('edit_filter', editFilter)
  return form
}

/**
 * @description create a new permission
 * @param {Number} projectId id of the project to add permission to
 * @returns {Promise} axios request promise
 */
export function createPermission({ projectId, ...formFields }) {
  return axios.post(`/admin/projects/${projectId}/permissions`, createPermissionFormData(formFields))
}

/**
 * @description edit a given permission
 * @param {Number} projectId id of the project to add permission to
 * @param {Number} permissionId id of the permission to update
 * @returns {Promise} axios request promise
 */
export function updatePermission({ projectId, permissionId, ...formFields }) {
  return axios.put(`/admin/projects/${projectId}/permissions/${permissionId}`, createPermissionFormData(formFields))
}

/**
 * @description fetch profile ids of users in a project
 * @param {Number} projectId id of the project to add permission to
 * @returns {Promise} axios request promise
 */
export function getUsedProfiles(projectId) {
  return axios.get(`/admin/projects/${projectId}/used_profiles`)
}
