import axios from 'axios'

/**
 * @description fetch project list with counts for dashboard
 */
export async function getDashboardProjects(authorization, ids) {
  const params = ids ? { ids: ids.join(',') } : { _all: '' }
  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/scs-lambdas/projects?${queryParams}`, {
    headers: {
      authorization
    }
  })
}

/**
 * @description fetch thumbnail url for a project
 */
export async function getProjectThumbnail(authorization, projectId) {
  return axios.get(`/scs-lambdas/projects/${projectId}/thumbnail`, {
    headers: {
      authorization
    }
  })
}
