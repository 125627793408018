import axios from 'axios'
import { replaceEscaped } from './replaceEscaped'
import * as Sentry from '@sentry/browser'

let uuid = null

export function shouldRedirectToLogin(error) {
  const errorCases = [
    error.request.responseURL.match(/\/state/),
    error.request.responseURL.match(/\/account/),
    error.request.responseURL.match(/\/projects/),
    error.request.responseURL.match(/\/assets/),
    error.request.responseURL.match(/\/vocab-projects/),
    error.request.responseURL.match(/\/institution/)
  ]

  const result = errorCases.some(errorCase => errorCase)
  console.debug(`Should redirect to login returning [${result}]`)
  return result
}

export function setupDistributedTracing(clientId, customClient) {
  const client = customClient ? customClient : axios
  client.interceptors.request.use(config => {
    config.headers['x-jstor-client-id'] = clientId
    if (uuid) {
      config.headers['x-jstor-uuid'] = uuid
    }
    return config
  })

  axios.interceptors.response.use(response => {
    // initialization of uuid, ran before App.vue is created
    if (!uuid && response.config.url === '/account') {
      uuid = response.headers['x-jstor-requestid']
    }
    return response
  })
}

export function setupAxios(vm) {
  // send cookies with http requests
  axios.defaults.withCredentials = true
  // CORS setup
  axios.defaults.crossDomain = true

  // re-route 401/403 to login page
  axios.interceptors.response.use(
    response => {
      const isLoggedIn = vm.$store.state.user.logged_in
      if (response.config.url !== '/account' && !isLoggedIn) {
        document.cookie = 'sharedshelf=;path=/'
      }
      if (response.config.url === '/account') {
        // updates uuid whenever the user logs in/out
        uuid = isLoggedIn ? null : response.headers['x-jstor-requestid']
      }
      if (response.config.url.includes('/media/iiif')) {
        console.log('ismedia')
      }
      return response
    },
    error => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        if (!document.location.href.match('/login') && shouldRedirectToLogin(error)) {
          console.debug('Permission denied, taking to homepage')
          vm.$store
            .dispatch('resetState')
            .catch(err => {
              console.debug(`there was an error: ${err}`)
            })
            .finally(() => {
              window.location.href = window.location.origin
            })
        }
      }

      if (error.response && (error.response.status === 500 || error.response.status === 503)) {
        if (!error.response.config.__isRetryRequest && error.response.status === 503) {
          error.response.config.__isRetryRequest = true
          return axios(error.response.config)
        } else {
          Sentry.withScope(scope => {
            scope.setLevel('error')
            scope.setTag('type', error.response.status.toString())
            scope.setExtra('reponse', error.response)
            Sentry.captureMessage(error.response.status + ' Error')
          })
          const errorMessage =
            'Sorry, your action failed! Please reload your page and try again. If it persists please contact ForumSupport@jstor.org'
          const newError = new Error(errorMessage)
          newError.status = error.response.status
          newError.response = error.response
          throw newError
        }
      }

      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(replaceEscaped)
}
