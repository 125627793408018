import axios from 'axios'

/**
 * @description fetch a list of cataloging forms
 * @returns {Promise} axios request promise
 * @param projectId
 * @param {object} options axios get request options
 *   options = { params: {
      with_meta: Boolean // send metadata information or not
    }}
 */
export function getCatalogingForms(projectId, options) {
  return axios.get(`/admin/projects/${projectId}/forms`, options)
}

/**
 * @description add a cataloging form
 * @returns {Promise} axios request promise
 * @params projectId: project to which to add the form, formName: form name to add
 *
 */
export function addCatalogingForm(projectId, formName) {
  return axios.post(`/admin/projects/${projectId}/forms?name=${encodeURIComponent(formName)}`)
}

/**
 * @description update a cataloging form
 * @returns {Promise} axios request promise
 * @params projectId: project to which to update the form, formId: form id to update
 *
 */
export function updateCatalogingForm(projectId, form) {
  const { id, name, position } = form
  const formData = new FormData()
  formData.append('name', name)
  formData.append('position', position)
  return axios.put(`/admin/projects/${projectId}/forms/${id}`, formData)
}

/**
 * @description delete a cataloging form
 * @returns {Promise} axios request promise
 * @params projectId: project from which to delete the form, formId: form ID to delete
 */
export function deleteCatalogingForm(projectId, formId) {
  return axios.delete(`/admin/projects/${projectId}/forms/${formId}`)
}

/**
 * @description get the fields for a given form
 * @param {string} id form id
 * @returns {Promise} axios request promise
 */
export function getFormFields(id) {
  return axios.get(`/forms/${id}/fields.extjs_components`)
}
