import axios from 'axios'

/**
 * @description login to the art-aa service for user profile actions
 * @param {string} email user's email address
 * @param {string} password user's password
 * @returns {Promise} axios request promise
 */
export function loginToArtstor(email, password) {
  const body = new URLSearchParams()
  body.append('j_username', email)
  body.append('j_password', password)
  return axios.post('/aaa/api/secure/login', body)
}

/**
 * @description login to the art-aa service for user profile actions
 * @param {string} currentPassword user's current password
 * @param {string} newPassword the new password
 * @returns {Promise} axios request promise
 */
export function updatePassword(currentPassword, newPassword) {
  const body = new URLSearchParams()
  body.append('_method', 'updatePassword')
  body.append('oldPassword', currentPassword)
  body.append('password', newPassword)
  body.append('source', 'forum')
  return axios.post('/aaa/api/secure/profile', body)
}
