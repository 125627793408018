import axios from 'axios'
import { decode } from '@/common/filters'

/**
 * @description fetch a list of publishing targets
 * @returns {Promise} axios request promise
 * @param projectId
 * @param {object} options axios get request options
 *   options = { params: {
      with_meta: Boolean // send metadata information or not
    }}
 */
export function getPublishingTargets(projectId) {
  return axios.get(`/admin/projects/${projectId}/publishing-targets`).then(response => {
    if (response.data.publishingTarget?.length > 0) {
      let str = decode(response.data.publishing_targets[0].name)
      response.data.publishing_targets[0].name = str
    }
    return response
  })
}

/**
 * @description add a new publishing target
 * @returns {Promise} axios request promise
 * @param projectId
 */
export function createPublishingTarget(params) {
  // types able to be created are [100, 200, 125]
  const { projectId, fields, collectionFields, targetType } = params
  const existingCollectionName = fields.existingCollection && fields.existingCollection.name
  const existingCollectionId = fields.existingCollection && fields.existingCollection.id

  const target = {
    name: existingCollectionName || collectionFields.name,
    view_size: -1,
    download_size: fields.downloadSize || -1,
    target_id: targetType.id,
    clustering_type: fields.cluster ? 1 : 0,
    target_name: targetType.name,
    routing_identifier: existingCollectionId || collectionFields.id,
    rights_statement: fields.rights,
    default_target: fields.default,
    export_unmapped: true
  }

  const form = new FormData()
  form.append('publishing_targets', JSON.stringify(target))

  return axios.post(`/admin/projects/${projectId}/publishing-targets`, form)
}

/**
 * @description add a new publishing target
 * @returns {Promise} axios request promise
 * @param projectId
 */
export function createJstorPublishingTarget(params) {
  const { projectId, name, targetId, targetName, collectionId } = params

  const target = {
    name,
    view_size: -1,
    download_size: -1,
    target_id: targetId,
    clustering_type: 0,
    target_name: targetName,
    routing_identifier: collectionId,
    export_unmapped: true
  }

  const form = new FormData()
  form.append('publishing_targets', JSON.stringify(target))

  return axios.post(`/admin/projects/${projectId}/publishing-targets`, form)
}

/**
 * @description update an existing publishing target
 * @returns {Promise} axios request promise
 * @param {Object} params a publishing target object
 */
export function updateJstorPublishingTarget(params) {
  const { projectId, name, targetId } = params

  const form = new FormData()
  form.append('name', name)

  return axios.post(`/admin/projects/${projectId}/publishing-targets/${targetId}`, form)
}

/**
 * @description add a new Omeka API publishing target
 * @returns {Promise} axios request promise
 * @param projectId
 */
export function createOmekaApiTarget(params) {
  const { projectId, name, target_id, target_name, routing_identifier, targetConfiguration } = params
  const publishingTarget = {
    name,
    target_id,
    target_name,
    routing_identifier,
    view_size: -1,
    download_size: '1024',
    rights_statement: '',
    target_configuration: targetConfiguration
  }
  const form = new FormData()
  form.append('publishing_targets', JSON.stringify(publishingTarget))

  return axios.post(`/admin/projects/${projectId}/publishing-targets`, form)
}

/**
 * @description update an existing publishing target
 * @returns {Promise} axios request promise
 * @param {Object} params a publishing target object
 */
export function updatePublishingTarget(params) {
  // types able to be edited are [100, 200, 125, 150]
  const { projectId, fields, collectionFields, targetType } = params
  const existingCollectionName = fields.existingCollection && fields.existingCollection.name

  const isPersonal = targetType === 125

  const form = new FormData()
  form.append('download_size', fields.downloadSize)
  form.append('rights_statement', fields.rights)
  if (fields.default) {
    form.append('default_target', fields.default)
  }

  if (!isPersonal) {
    form.append('name', existingCollectionName || collectionFields.name)
    form.append('description', collectionFields.description)
    form.append('image', fields.image)
    form.append('export_unmapped', true)
    form.append('clustering_type', fields.cluster ? 1 : 0)
  }

  return axios.post(`/admin/projects/${projectId}/publishing-targets/${collectionFields.id}`, form)
}

/**
 * @description update an existing omeka api target
 * @returns {Promise} axios request promise
 * @param {Object} params a publishing target object
 */
export function updateOmekaApiTarget(params) {
  const { projectId, targetId, targetConfiguration } = params
  const form = new FormData()
  form.append('target_configuration', JSON.stringify(targetConfiguration))

  return axios.post(`/admin/projects/${projectId}/publishing-targets/${targetId}`, form)
}

/**
 *
 * @param {string} projectId the project to which the target belongs
 * @param {string} collectionId the target collection to remove
 */
export function deletePublishingTarget(projectId, collectionId) {
  return axios.delete(`/admin/projects/${projectId}/publishing-targets/${collectionId}`)
}
