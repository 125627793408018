import axios from 'axios'

export function getLanguages() {
  return [
    'Abkhazian',
    'Afar',
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Assamese',
    'Aymara',
    'Azerbaijani',
    'Bashkir',
    'Basque',
    'Bengali (Bangla)',
    'Bhutani',
    'Bihari',
    'Bislama',
    'Breton',
    'Bulgarian',
    'Burmese',
    'Byelorussian (Belarusian)',
    'Cambodian',
    'Catalan',
    'Chinese',
    'Corsican',
    'Croatian',
    'Czech',
    'Danish',
    'Dutch',
    'English',
    'Esperanto',
    'Estonian',
    'Faeroese',
    'Farsi',
    'Fiji',
    'Finnish',
    'French',
    'Frisian',
    'Galician',
    'Gaelic (Scottish)',
    'Gaelic (Manx)',
    'Georgian',
    'German',
    'Greek',
    'Greenlandic',
    'Guarani',
    'Gujarati',
    'Hausa',
    'Hebrew',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Indonesian',
    'Indonesian',
    'Interlingua',
    'Interlingue',
    'Inuktitut',
    'Inupiak',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Kannada',
    'Kashmiri',
    'Kazakh',
    'Kinyarwanda (Ruanda)',
    'Kirghiz',
    'Kirundi (Rundi)',
    'Korean',
    'Kurdish',
    'Laothian',
    'Latin',
    'Latvian (Lettish)',
    'Limburgish ( Limburger)',
    'Lingala',
    'Lithuanian',
    'Macedonian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Moldavian',
    'Mongolian',
    'Nauru',
    'Nepali',
    'Norwegian',
    'Occitan',
    'Oriya',
    'Oromo (Afan, Galla)',
    'Pashto (Pushto)',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Quechua',
    'Rhaeto-Romance',
    'Romanian',
    'Russian',
    'Samoan',
    'Sangro',
    'Sanskrit',
    'Serbian',
    'Serbo-Croatian',
    'Sesotho',
    'Setswana',
    'Shona',
    'Sindhi',
    'Sinhalese',
    'Siswati',
    'Slovak',
    'Slovenian',
    'Somali',
    'Spanish',
    'Sundanese',
    'Swahili (Kiswahili)',
    'Swedish',
    'Tagalog',
    'Tajik',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tibetan',
    'Tigrinya',
    'Tonga',
    'Tsonga',
    'Turkish',
    'Turkmen',
    'Twi',
    'Uighur',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Vietnamese',
    'Volapuk',
    'Welsh',
    'Wolof',
    'Xhosa',
    'Yiddish',
    'Yiddish',
    'Yoruba',
    'Zulu'
  ]
}

export function list(entryId) {
  return axios.get(`/kts/listcaption?id=${encodeURIComponent(entryId)}`)
}

export function upload(entryId, caption) {
  const form = new FormData()
  form.append('file', caption.file)
  return axios.post(
    `/kts/addcaption?id=${encodeURIComponent(entryId)}&name=${encodeURIComponent(
      caption.name
    )}&language=${encodeURIComponent(caption.language)}`,
    form
  )
}

export function update(entryId, caption) {
  const form = new FormData()
  form.append('id', entryId)
  form.append('caption_id', caption.id)
  form.append('name', caption.name)
  form.append('language', caption.language)
  form.append('format', caption.format)
  return axios.post(`/kts/updatecaption`, form)
}

export function setDefault(entryId, captionId) {
  return axios.get(
    `/kts/defaultcaption?kaltura_id=${encodeURIComponent(entryId.charAt(0))}&caption_id=${encodeURIComponent(
      captionId
    )}`
  )
}

export function remove(entryId, captionId) {
  return axios.get(
    `/kts/removecaption?kaltura_id=${encodeURIComponent(entryId.charAt(0))}&caption_id=${encodeURIComponent(captionId)}`
  )
}
