import cloneDeep from 'lodash/cloneDeep'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import defaults from 'lodash/defaults'
import difference from 'lodash/difference'

import Vue from 'vue'

import { downloadFile } from '../common/downloadFile'
import { transformColumns } from '../components/grid/asset-grid-utils'
import {
  queryAssetGroupAssets,
  saveAssetGroupAddAssets,
  saveAssetGroupAssetsFile,
  deleteMultipleProjectAssetGroup
} from '../services/assetGroups'
import { saveAssetDrsData } from '../services/assets.js'
import { getBulkExport } from '../services/bulkExport.js'
import { queryLinkedTerms } from '../services/controlledLists'
import { getMediaProjects } from '../services/mediaProjects.js'
import {
  compareProjectAssets,
  deleteProjectAssetGroup,
  deleteProjectAssets,
  deleteProjectSavedSearch,
  getProjectAccounts,
  getProjectMediaSources,
  queryProjectAssetGroups,
  queryProjectAssets,
  queryProjectSavedFilters,
  removeFromProjectAssetGroup,
  saveAutoLinkExternalMedia,
  saveControlledListTermsFile,
  saveProjectAssetGroups,
  saveProjectAssets,
  saveProjectAssetsFile,
  saveProjectSavedFilter,
  updateProjectAssetGroups,
  updateProjectAssets,
  updateProjectSavedFilter,
  executeMap
} from '../services/projects.js'
import { querySavedFilterAssets } from '../services/savedFilters'
import { EventBus } from '@/common/eventBus'

let fetchProjectSource = {}
let fetchSetSource = {}
let fetchFilterSource = {}
let fetchLinkedTermsSource = {}

const defaultAssetParams = () => ({
  with_meta: false,
  start: 0,
  limit: 100,
  sort: 'id',
  dir: 'DESC',
  filter: [],
  query: ''
})

const defaultProjects = () => ({
  items: [],
  details: {
    assets: [],
    metaData: {
      columns: []
    },
    total: 0,
    query: {},
    projectId: 0,
    showInvalidNotification: true
  },
  mediaSources: [],
  users: [],
  sets: {
    items: [],
    total: 0,
    selected: {}
  },
  modalSets: {
    items: [],
    total: 0
  },
  filters: {
    items: [],
    total: 0,
    selected: {
      search_parameters: {}
    }
  },
  loading: false,
  columnOrder: [],
  masterRecords: {},
  activityMonitorIndex: 0,
  setsLoading: false,
  assetType: 'projects',
  workRecord: {
    chkValue: false,
    selected_map: 0,
    selected_map_name: ''
  }
})

const projects = {
  namespaced: true,
  state: defaultProjects(),
  mutations: {
    SET_LIST(state, setProjects) {
      state.items = setProjects
    },
    SET_DETAILS(state, project) {
      state.details.assets = project.assets
      state.details.assets.forEach(asset => {
        asset.details = {}
      })

      if (project.metaData) {
        // sometimes with_meta=false is set, in which case we done overwrite the old metaData
        state.details.metaData = project.metaData
      }
      state.details.total = project.total
      state.details.query = project.query

      if (state.details.projectId != project.projectId) {
        state.details.projectId = project.projectId
        state.details.showInvalidNotification = true
      }
    },
    CLEAR_DETAILS(state) {
      state.details.assets.length = 0
      state.details.metaData = { columns: [] }
      state.details.total = 0
      state.details.projects = []
      state.details.query = {}
    },
    CREATED_ASSETS(state, assets) {
      state.details.total += assets.length
    },
    LOADING_TRUE(state) {
      state.loading = true
    },
    LOADING_FALSE(state) {
      state.loading = false
    },
    SET_MEDIA_SOURCES(state, items) {
      state.mediaSources = items
    },
    SET_MASTER_RECORD(state, options) {
      const { projectId, assetData } = options
      const assetDataCopy = cloneDeep(assetData)
      Vue.set(state.masterRecords, projectId, assetDataCopy)
    },
    SET_ACTIVITY_MONITOR_INDEX(state, index) {
      state.activityMonitorIndex = index
    },
    SET_ASSET_TYPE(state, type) {
      state.assetType = type
    },
    SET_PROJECT_SETS(state, set) {
      state.sets.items = set.items
      state.sets.total = set.total
    },
    SET_MODAL_SETS(state, set) {
      state.modalSets.items = set.items
      state.modalSets.total = set.total
    },
    CLEAR_SETS(state) {
      state.sets.items = []
      state.sets.total = 0
      state.sets.selected = {}
    },
    SETS_LOADING_TRUE(state) {
      state.setsLoading = true
    },
    SETS_LOADING_FALSE(state) {
      state.setsLoading = false
    },
    SET_USERS(state, payload) {
      state.users = payload.users
    },
    SET_SELECTED_SET(state, set) {
      state.sets.selected = set
    },
    SET_PROJECT_FILTERS(state, filter) {
      // sort by name
      filter.items.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

      for (let i = filter.items.length - 1; i >= 0; i--) {
        const item = filter.items[i]
        try {
          JSON.parse(item.search_description) && JSON.parse(item.search_parameters)
        } catch (e) {
          filter.items.splice(i, 1)
        }
      }

      filter.items.forEach(item => {
        item.search_description = JSON.parse(item.search_description)
        item.search_parameters = JSON.parse(item.search_parameters)
      })

      state.filters.items = filter.items
      state.filters.total = filter.total
    },
    CLEAR_FILTERS(state) {
      state.filters.items = []
      state.filters.total = 0
      state.filters.selected = {}
    },
    SET_SELECTED_FILTER(state, filter) {
      state.filters.selected = filter
    },
    SET_WORK_MAP(state, workRecordData) {
      state.workRecord.chkValue = workRecordData.chkValue
      state.workRecord.selected_map = workRecordData.selected_map
      state.workRecord.selected_map_name = workRecordData.selected_map_name
    },
    UPDATE_SAVED_FILTER(state, filter) {
      filter.search_description = JSON.parse(filter.search_description)
      filter.search_parameters = JSON.parse(filter.search_parameters)
      Object.assign(state.filters.selected, filter)

      const index = state.filters.items.findIndex(item => item.id === filter.id)
      Object.assign(state.filters.items[index], filter)
    },
    CLEAR_SOURCES_USERS(state) {
      state.mediaSources = []
      state.users = []
    },
    //Not used in this store, but in projectSettings on field updates
    CLEAR_PROJECT_ID(state) {
      state.details.projectId = 0
    },
    RESET_STATE(state) {
      Object.assign(state, defaultProjects())
    },
    SET_INVALID(state, invalid) {
      state.details.showInvalidNotification = invalid
    }
  },
  actions: {
    async fetchProjects(context) {
      const res = await getMediaProjects()
      context.commit('SET_LIST', res.data.items)
    },
    async fetchProject(context, options) {
      const params = Object.assign(defaultAssetParams(), cloneDeep(options.params))
      if (params.query.trim() === '') {
        delete params.query
      }
      const assetType = context.state ? context.state.assetType : 'projects'

      if (typeof params.filter !== 'string' && !(params.filter instanceof String)) {
        params.filter = JSON.stringify(params.filter)
      }

      if (context.state.details.projectId !== options.projectId) {
        params.with_meta = true

        if (assetType === 'projects') {
          context.commit('CLEAR_SOURCES_USERS')
        }
      }

      context.commit('LOADING_TRUE')

      try {
        let res
        if (assetType === 'saved-searches') {
          res = await querySavedFilterAssets(options.id, { params }, fetchProjectSource)
        } else if (assetType === 'asset-groups') {
          res = await queryAssetGroupAssets(options.id, { params }, fetchProjectSource)
        } else {
          // default to projects
          res = await queryProjectAssets(options.projectId, { params }, fetchProjectSource)
        }

        if (res.data) {
          params.with_meta = false
          context.commit('SET_DETAILS', Object.assign(res.data, { query: params }, { projectId: options.projectId }))
          context.commit('LOADING_FALSE')
          return true
        }
      } catch (err) {
        const canceledReq = !err.message
        if (!canceledReq) {
          context.commit(
            'SET_DETAILS',
            Object.assign(
              {
                assets: [],
                success: false,
                total: 0
              },
              { query: params },
              { projectId: options.projectId }
            )
          )
          context.commit('LOADING_FALSE')
          throw Error('projects.js#fetchProject failed')
        }
      }
    },

    async downloadProject(context, options) {
      const assetType = context.state ? context.state.assetType : 'projects'
      let params = Object.assign(defaultAssetParams(), options.params)
      params.filter = JSON.stringify(params.filter)
      const updatedParams = new URLSearchParams(params).toString()
      return downloadFile(`/${assetType}/${options.id}/assets.xls?${updatedParams}`)
    },
    async createAssets(context, options) {
      const { projectId, quantity } = options
      const res = await saveProjectAssets(projectId, quantity)
      context.commit('CREATED_ASSETS', res.data.assets)
      return res
    },
    async importRecords(context, options) {
      let res
      if (options.type === 'mfcl') {
        res = await saveControlledListTermsFile(options.id, options.file)
      } else if (options.type === 'asset-groups' || context.state.assetType === 'asset-groups') {
        res = await saveAssetGroupAssetsFile(options.id, options.file)
      } else {
        res = await saveProjectAssetsFile(options.id, options.file)
      }
      return res
    },
    async deleteRecords(_context, options) {
      const res = await deleteProjectAssets(options)
      EventBus.$emit('items-deleted', options)
      return res
    },
    fetchBulkExport(_context, options) {
      const { projectId, institutionId, assetsArr } = options
      return getBulkExport(projectId, institutionId, assetsArr)
    },
    async fetchMediaSources(context, options) {
      const { projectId } = options
      const res = await getProjectMediaSources(projectId)
      context.commit('SET_MEDIA_SOURCES', res.data.items)
    },
    linktoDRS(_context, options) {
      const { projectId, assetIds } = options
      return saveAutoLinkExternalMedia(projectId, assetIds)
    },
    updateAssetsWithDRSData(_context, options) {
      const { assetData } = options
      return saveAssetDrsData(assetData)
    },
    setInvalidNotification(context, invalid) {
      context.commit('SET_INVALID', invalid)
    },
    setMasterRecord(context, options) {
      const { projectId, assetData } = options
      context.commit('SET_MASTER_RECORD', { projectId, assetData })

      // save to state
      const assetId = (assetData && assetData.id) || null
      context.dispatch('userState/saveMasterRecord', { projectId, assetId }, { root: true })
    },
    setActivityMonitorIndex(context, index) {
      context.commit('SET_ACTIVITY_MONITOR_INDEX', index)
    },
    async fetchSets(context, options) {
      const { projectId, params: givenParams } = options
      const params = { start: 0, limit: 100, sort: 'name', dir: 'ASC', name: '', visibility: [], ...givenParams }

      const urlParams = new URLSearchParams()
      for (const key in params) {
        if (key === 'visibility') {
          params[key].forEach(value => {
            urlParams.append('visibility', value)
          })
        } else {
          urlParams.append(key, params[key])
        }
      }

      if (!options.COMMIT_SET_TYPE) {
        context.commit('SETS_LOADING_TRUE')
      }

      try {
        const res = await queryProjectAssetGroups(projectId, urlParams, fetchSetSource)

        if (res && res.data) {
          if (context.state.sets.selected.id && !context.state.sets.selected.name) {
            const selectedSet = res.data.items.find(set => set.id === context.state.sets.selected.id)
            if (selectedSet) {
              context.dispatch('setSelectedSet', selectedSet)
            }
          }
          context.commit(options.COMMIT_SET_TYPE || 'SET_PROJECT_SETS', res.data)
        }
      } catch (error) {
        throw error
      } finally {
        context.commit('SETS_LOADING_FALSE')
      }
    },
    setAssetType(context, type) {
      context.commit('SET_ASSET_TYPE', type)
    },
    async addNewSet(context, options) {
      const { projectId, fields, assetIds } = options
      const setRes = await saveProjectAssetGroups(projectId, fields)
      const setId = setRes.data.asset_group.id
      context.dispatch('userState/copyProjectState', { projectId, setId }, { root: true })
      if (assetIds.length > 0) {
        const assetsRes = await saveAssetGroupAddAssets(setId, assetIds)
        return assetsRes.data.message
      }
      return `'${fields.name}' was successfully created`
    },
    async addToExistingSet(_context, options) {
      const { setId, assetIds } = options
      const assetsRes = await saveAssetGroupAddAssets(setId, assetIds)
      return assetsRes.data.message
    },
    async editSet(context, options) {
      const { projectId, assetGroupId, fields } = options
      await updateProjectAssetGroups(projectId, assetGroupId, fields)
      if (context.state.sets.selected.id === assetGroupId) {
        context.dispatch('setSelectedSet', fields)
      }
      return `'${fields.name}' was successfully saved`
    },
    async getProjectUsers(context, options) {
      const { projectId } = options
      const res = await getProjectAccounts(projectId)
      context.commit('SET_USERS', { users: res.data.items })
      return res.data
    },
    async deleteSet(context, options) {
      const { projectId, setId } = options
      const res = await deleteProjectAssetGroup(projectId, setId)
      context.dispatch('setSelectedSet', {})
      return res
    },
    async deleteMultipleSets(context, options) {
      const { projectId, sets } = options
      const res = await deleteMultipleProjectAssetGroup(projectId, sets)
      if (context.state.sets.selected && sets.includes(context.state.sets.selected.id)) {
        context.dispatch('setSelectedSet', {})
      }
      return res
    },
    setSelectedSet(context, set) {
      context.commit('SET_SELECTED_SET', set)
    },
    async removeFromSet(_context, options) {
      const { setId, assetIds } = options
      const res = await removeFromProjectAssetGroup(setId, assetIds)
      return res.data.message
    },
    async fetchFilters(context, options) {
      const { projectId } = options

      try {
        const res = await queryProjectSavedFilters(projectId, fetchFilterSource)

        if (res && res.data) {
          if (context.state.filters.selected.id && !context.state.filters.selected.name) {
            const selectedFilter = res.data.items.find(filter => filter.id === context.state.filters.selected.id)
            if (selectedFilter) {
              context.dispatch('setSelectedFilter', selectedFilter)
            }
          }

          context.commit('SET_PROJECT_FILTERS', res.data)
        }
      } catch (err) {
        console.error(err)
      }
    },
    setSelectedFilter(context, filter) {
      context.commit('SET_SELECTED_FILTER', filter)
    },
    async addNewSavedFilter(context, options) {
      const { projectId, fields, searchParams, searchDesc } = options
      const res = await saveProjectSavedFilter(projectId, fields, searchParams, searchDesc)
      const filterId = res.data.saved_search.id
      context.dispatch('userState/copyProjectState', { projectId, filterId }, { root: true })
      return res
    },
    async deleteFilter(context, options) {
      const { projectId, filterId } = options
      const res = await deleteProjectSavedSearch(projectId, filterId)
      context.dispatch('setSelectedFilter', { search_parameters: {} })
      return res
    },
    async updateSavedFilter(context, options) {
      const { projectId, filterId, fields, searchParams, searchDesc } = options
      const res = await updateProjectSavedFilter(projectId, filterId, fields, searchParams, searchDesc)

      if (res && res.data) {
        context.commit('UPDATE_SAVED_FILTER', res.data.saved_search)
        return res
      }
    },

    async compareAssets(context, options) {
      const tab = context.rootState.tabs.list[options.tabIndex]
      if (!tab) {
        return
      }

      let filter
      let assetType
      let paramId
      let query

      if (tab.batchSelected.type === 'all') {
        filter = tab.query.filter
        assetType = tab.assetType || 'projects'
        paramId = tab.paramId
        query = tab.query.query
      } else {
        filter = JSON.stringify([
          {
            type: 'list',
            value: tab.batchSelected.params,
            field: 'id'
          }
        ])

        assetType = 'projects'
        paramId = tab.projectId
      }

      try {
        const res = await compareProjectAssets(assetType, paramId, filter, query)
        return res.data
      } catch (err) {
        console.error(err)
      }
    },
    async updateAssets(context, options) {
      const { tabIndex, fields } = options
      const tab = context.rootState.tabs.list[tabIndex]
      let filter
      let assetType
      let paramId
      let query
      let selectAll = false

      if (tab.batchSelected.type === 'all') {
        filter = tab.query.filter
        assetType = tab.assetType || 'projects'
        paramId = tab.paramId
        query = tab.query.query
        selectAll = true
      } else {
        filter = JSON.stringify([
          {
            type: 'list',
            value: tab.batchSelected.params,
            field: 'id'
          }
        ])

        assetType = 'projects'
        paramId = tab.projectId
      }

      try {
        const res = await updateProjectAssets(assetType, paramId, filter, fields, query, selectAll)
        return res.data
      } catch (err) {
        console.error(err)
      }
    },
    async fetchEditAsset(context, options) {
      let params = Object.assign(defaultAssetParams(), options.params)
      const tab = context.rootState.tabs.list[options.tabIndex]
      const assetType = tab.assetType || 'projects'

      try {
        let res
        if (assetType === 'saved-searches') {
          res = await querySavedFilterAssets(tab.paramId, { params }, tab.fetchEditAssetSource)
        } else if (assetType === 'asset-groups') {
          res = await queryAssetGroupAssets(tab.paramId, { params }, tab.fetchEditAssetSource)
        } else {
          // default to projects
          res = await queryProjectAssets(options.projectId, { params }, tab.fetchEditAssetSource)
        }
        return res.data
      } catch (err) {
        console.error(err)
      }
    },
    async fetchLinkedTerms(_context, options) {
      const { id, cancelSource, params: customParams } = options
      const params = {
        with_meta: true,
        limit: 25,
        ...customParams
      }
      const res = await queryLinkedTerms(id, params, cancelSource || fetchLinkedTermsSource)
      return res.data
    },
    async executeMapForProject(_context, options) {
      const { projectId, workId, behaviour } = options
      return executeMap(projectId, workId, behaviour)
    }
  },
  getters: {
    sortedProjects: state => [...state.items].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
    sortedByAccess: (state, _getters, rootState) => {
      const accessed = rootState.userState.data['project-access']
      return [...state.items].sort((a, b) => {
        const date1 = new Date(accessed[a.id] || a.id)
        const date2 = new Date(accessed[b.id] || b.id)
        return date2 - date1
      })
    },
    getColumns: (state, _getters, _rootState, rootGetters) => gridState => {
      /**
       * when a column is moved, the system knows where the column is placed
       * gridState data is your preferred grid configuration
       */
      const gridStateData = rootGetters['userState/getGridStateForProject'](gridState)
      const columnState = gridStateData.columns || []

      const allColumns = state.details.metaData.columns.map(col => col.dataIndex)
      const columnsFromState = []

      const columns = compact(
        columnState.map((config, colIndex) => {
          let column
          if (config.id && config.id.length) {
            column = state.details.metaData.columns.find(c => c.id === config.id || c.dataIndex === config.id)
          } else if (config.id > 1) {
            column = state.details.metaData.columns[config.id - 1]
          }

          if (!column) {
            return null
          }

          const skipPinning = column.id === 'thumbnail' && colIndex !== 0

          columnsFromState.push(column.dataIndex)

          return defaults(
            { width: config.width, hide: config.hidden, id: column.id || column.dataIndex, skipPinning },
            column
          )
        })
      )

      let missingColumns = difference(allColumns, columnsFromState)
      missingColumns = missingColumns.map(dataIndex => {
        const column = state.details.metaData.columns.find(c => c.dataIndex === dataIndex)
        return { ...column, id: column.id || column.dataIndex }
      })

      const result = concat(columns, missingColumns)

      const currentOrder = result.map(c => c.id)
      const originalOrder = state.details.metaData.columns.map(c => c.id || c.dataIndex)

      const shouldMoveSystemFields = currentOrder.every((c, index) => c === originalOrder[index])

      if (!shouldMoveSystemFields) {
        return result
      }

      // Need a better way to identify work field
      let workField = result.find(
        col =>
          col.xtype === 'lookupcolumn' && !col.dataIndex.endsWith('mfcl_lookup') && col.filter.type === 'nullstring'
      )

      if (workField) {
        workField = workField.dataIndex
      }

      const preferredOrder = concat(
        ['thumbnail', 'id', 'filename', 'created_by', 'created_on'],

        // Added fields
        originalOrder.filter(c => c.startsWith('fd_') && c !== workField),

        // Other system fields
        ['updated_by', 'updated_on', 'media_count', 'propagated_on'],

        // Work related fields
        [workField, 'sequence_number', 'primary_image'],

        // Publishing status fields
        originalOrder.filter(c => c.startsWith('publishing_'))
      )

      return result.sort((c1, c2) => preferredOrder.indexOf(c1.id) - preferredOrder.indexOf(c2.id))
    },
    getColumnDefinitions: (_state, getters, _rootState, rootGetters) => gridState => {
      const columns = getters.getColumns(gridState)
      const suppressFilters = rootGetters['userState/viewingBetaFilters']

      return transformColumns(
        [
          { id: 'select', dataIndex: 'select', header: '', xtype: 'selectcolumn', width: 28 },
          { id: 'is_valid', dataIndex: 'is_valid', header: 'Valid', hide: true, xtype: 'nullablebooleancolumn' },
          ...columns
        ],
        'projects',
        suppressFilters
      )
    },
    isAdmin: state => {
      return state.details.metaData.admin
    },
    getProject: state => projectId => {
      return state.items.find(item => item.id === projectId) || {}
    },
    projectName: (_state, getters) => projectId => {
      return getters.getProject(projectId).name
    },
    projectDescription: (_state, getters) => projectId => {
      return getters.getProject(projectId).description
    },
    institutionId: (_state, getters) => projectId => {
      return getters.getProject(projectId).institution_id
    },
    institutionUuid: (_state, getters, rootState) => projectId => {
      const institutionId = getters.getProject(projectId).institution_id
      const legacyIdToUuidMap = rootState.user['legacy_id_to_uuid_map']
      return legacyIdToUuidMap[institutionId]
    },
    ocrEnabled: (_state, getters) => projectId => {
      return getters.getProject(projectId).ocr_enabled
    },
    invalidData: (_state, getters) => projectId => {
      return getters.getProject(projectId).allows_invalid_data
    },
    compoundEnabled: (_state, getters) => projectId => {
      return getters.getProject(projectId).supports_media_objects
    },
    assetsWithMasterInfo: state => {
      if (state.details && state.details.assets && state.details.assets.length) {
        const projectId = state.details.assets[0].project_id
        let masterRecordId = -1
        if (state.masterRecords && state.masterRecords[projectId]) {
          masterRecordId = state.masterRecords[projectId].id
        }

        return state.details.assets.map(asset => ({
          ...asset,
          isMasterRecord: asset.id === masterRecordId
        }))
      }
      return []
    }
  }
}

export default projects
