import {
  getCatalogingFormFields,
  getUnassignedFormFields,
  addCatalogingFormFields,
  updateCatalogingFormField,
  deleteCatalogingFormField
} from '@/pages/project-settings/services/projectFormFields.js'
import cloneDeep from 'lodash/cloneDeep'

const defaultFormFields = () => ({
  catalogFormFields: []
})

const formFields = {
  namespaced: true,
  state: defaultFormFields(),
  mutations: {
    SET_CATALOG_FORMS_FIELDS(state, catalogFormFields) {
      state.catalogFormFields = catalogFormFields
      state.catalogFormFields.sort((a, b) => a.position - b.position)
    },
    UPDATE_CATALOG_FORMS_FIELD(state, field) {
      const fieldIndex = state.catalogFormFields.findIndex(f => f.id === field.id)
      state.catalogFormFields.splice(fieldIndex, 1, field)
    },
    RESET_STATE(state) {
      Object.assign(state, defaultFormFields())
    }
  },
  actions: {
    async fetchCatalogingFormFields(context, options) {
      const { formId, params, skipCommit = false } = options
      const res = await getCatalogingFormFields(formId, { params })
      if (!skipCommit) {
        // reindex form fields
        context.commit(
          'SET_CATALOG_FORMS_FIELDS',
          res.data.items.map((field, index) => {
            field.position = index + 1
            return field
          })
        )
      }
      return res
    },
    getUnassignedFormFields(context, options) {
      const { formId, projectId, params } = options
      return getUnassignedFormFields(formId, projectId, { params })
    },
    addCatalogingFormFields(context, options) {
      const { formId, selectedFields } = options
      const returnedPromises = selectedFields.map(field => {
        let formData = {}
        formData['id'] = field.id
        formData['position'] = field.position
        return addCatalogingFormFields(formId, formData).catch(error => error.response)
      })
      return Promise.all(returnedPromises)
    },
    async updateCatalogingFormField(context, options) {
      let { formId, field } = options
      const res = await updateCatalogingFormField(formId, field)
      context.commit('UPDATE_CATALOG_FORMS_FIELD', res.data.form_fields)
      return res
    },
    async deleteFormField(context, options) {
      let { formId, fieldId } = options
      return await deleteCatalogingFormField(formId, fieldId)
    },
    async reorderCatalogingFormFields(context, options) {
      const { formId, row, save, toIndex: startingToIdx } = options

      const newFields = cloneDeep(context.state.catalogFormFields)
      const toIndex = Math.max(0, Math.min(startingToIdx, newFields.length - 1))
      const fromIndex = newFields.findIndex(field => field.id === row.id)

      let fields = []
      const movingDown = fromIndex < toIndex
      const movingUp = fromIndex > toIndex

      if (movingDown) {
        fields = newFields.slice(fromIndex + 1, toIndex + 1)
        fields.forEach((field, index) => (field.position = fromIndex + index + 1))
      } else if (movingUp) {
        fields = newFields.slice(toIndex, fromIndex)
        fields.forEach((field, index) => (field.position = toIndex + index + 2))
      } else {
        return
      }

      newFields[fromIndex].position = toIndex + 1
      fields.splice(0, 0, newFields[fromIndex])

      if (save) {
        try {
          await Promise.all(fields.map(field => updateCatalogingFormField(formId, field)))
        } catch (e) {
          context.state.catalogFormFields[fromIndex].position = fromIndex + 1
          context.commit('SET_CATALOG_FORMS_FIELDS', context.state.catalogFormFields)
          throw e
        }
      }

      context.commit('SET_CATALOG_FORMS_FIELDS', newFields)
    },
    async setFormFields(context, options) {
      const { formId } = options
      try {
        await Promise.all(context.state.catalogFormFields.map(field => updateCatalogingFormField(formId, field)))
      } catch (e) {
        throw e
      }
    },
    displayFieldsAtPosition(context, options) {
      const { fields } = options
      context.commit('SET_CATALOG_FORMS_FIELDS', fields)
    }
  }
}

export default formFields
