export function getProjectFilterStateKey(projectId) {
  return `project-${projectId}-filters`
}

export function getProjectFavoriteFiltersStateKey(projectId) {
  return `project-${projectId}-favorite-filters`
}

export function getUploadDetailsKey(upload) {
  return `uploads-${upload.date}-details`
}
