import {
  createProjectDefinitions,
  getProjectSources,
  getFieldData,
  getLinkedFieldSources,
  updateProjectDefinitions,
  bulkUpdateProjectDefinitions
} from '@/pages/project-settings/services/projectFields.js'
import { getCatalogingForms } from '@/pages/project-settings/services/projectForms.js'
import {
  getCatalogingFormFields,
  updateCatalogingFormField
} from '@/pages/project-settings/services/projectFormFields.js'

import groupBy from 'lodash/groupBy'

const defaultProjectFields = () => ({
  sources: []
})

const projectFields = {
  namespaced: true,
  state: defaultProjectFields(),
  mutations: {
    SET_SOURCES(state, sources) {
      state.sources = sources
    },
    RESET_STATE(state) {
      Object.assign(state, defaultProjectFields())
    }
  },
  actions: {
    async createField(context, options) {
      const { projectId, formData, list } = options

      const res = await createProjectDefinitions(projectId, formData)

      if (res.data.success) {
        const newDefinition = res.data.definitions
        const mutation = list ? 'lists/ADD_DEFINITION' : 'projectSettings/ADD_DEFINITION'
        context.commit(mutation, newDefinition, { root: true })

        return res.data
      } else {
        // return the error message (success & reason)
        return res.data
      }
    },
    async getProjectSources(context, { projectId }) {
      const response = await getProjectSources(projectId)
      const { items } = response.data
      items.sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      })
      context.commit('SET_SOURCES', items)
    },
    async getLinkedFieldSources(context, { fieldId }) {
      const response = await getLinkedFieldSources(fieldId)
      return response.data.items
    },
    async saveFieldPositions(context, { projectId, existing, updated, isList }) {
      const newPositions = groupBy(existing, 'id')
      const updatedPositions = updated
        .filter(def => {
          return def.position !== newPositions[def.id][0].position
        })
        .map(({ id, position }) => ({ id, position }))
      if (!updatedPositions.length) {
        return false
      }

      if (isList) {
        const desiredPositions = groupBy(updatedPositions, 'id')

        const catalogingForm = await getCatalogingForms(projectId)
        const catalogingFormId = catalogingForm.data.items[0].id

        const catalogingFormData = await getCatalogingFormFields(catalogingFormId)
        const catalogingFormItems = catalogingFormData.data.items

        const updatedCatalogingFormItems = catalogingFormItems
          .filter(item => {
            return (
              desiredPositions[item.field_definition_id] &&
              item.position !== desiredPositions[item.field_definition_id][0].position
            )
          })
          .map(item => {
            return {
              ...item,
              position: desiredPositions[item.field_definition_id][0].position
            }
          })
        await Promise.all(updatedCatalogingFormItems.map(item => updateCatalogingFormField(catalogingFormId, item)))
      }

      return await bulkUpdateProjectDefinitions(projectId, updatedPositions)
    },
    async getFieldData(context, options) {
      const { projectId, fieldId } = options
      const response = await getFieldData(projectId, fieldId)
      return response.data.empty
    },
    async updateField(context, options) {
      const { projectId, fieldId, formData } = options

      const res = await updateProjectDefinitions(projectId, fieldId, formData)

      if (res.data.success) {
        const field = {
          ...formData,
          id: fieldId
        }

        return {
          ...res.data,
          definitions: field
        }
      } else {
        // return the error message (success & reason)
        return res.data
      }
    }
  },
  getters: {
    suggestedProjectFieldSourceGroups: (state, getters) => {
      const groups = getters.projectFieldSourceGroups

      return groups.map(group => {
        let { options } = group

        if (group.id === 'local_lists') {
          options = options.slice(0, 5)
        } else if (group.id === 'community_lists') {
          options = options.slice(0, 3)
        } else {
          options = options.slice(0, 4)
        }

        return {
          ...group,
          options
        }
      })
    },
    projectFieldSourceGroups: state => {
      const groups = [
        { id: 'forum_integrated_sources', label: 'Forum integrated sources', options: [] },
        { id: 'local_lists', label: 'Local lists', options: [] },
        { id: 'community_lists', label: 'Community lists', options: [] },
        { id: 'external_sources', label: 'External sources', options: [] }
      ]

      const groupMap = {
        forum_integrated_sources: groups[0],
        local_lists: groups[1],
        community_lists: groups[2],
        external_sources: groups[3]
      }

      state.sources.forEach(source => {
        let destination = null

        if (source.lookup_type_id === 150) {
          if (source.shared) {
            destination = groupMap['community_lists']
          } else {
            destination = groupMap['local_lists']
          }
        } else if (source.lookup_type_id === 500) {
          destination = groupMap['external_sources']
        } else {
          destination = groupMap['forum_integrated_sources']
        }

        destination.options.push(source)
      })

      return groups
    }
  }
}

export default projectFields
