import { queryAssetGroupAssets } from '@/services/assetGroups'
import {
  deleteAssetMediaObject,
  getAsset,
  getAssetMediaObjects,
  getAssetPublicationStatus,
  getAssetRepresentationDetails,
  getAssetRepresentationStatus,
  getAssetShortURL,
  getAssetDoi
} from '@/services/assets'
import { getFormFields } from '@/services/forms.js'
import { getMediaObjectRepresentationDetails, getMediaObjectShortURL } from '@/services/mediaObjects'
import {
  getProjectForms,
  queryProjectAssets,
  updateProjectAssetClearMedia,
  updateProjectAsset
} from '@/services/projects'
import { querySavedFilterAssets } from '@/services/savedFilters'
import cloneDeep from 'lodash/cloneDeep'
import formatFields from '../common/formatFields'
import axios from 'axios'
import { isJstorTarget } from '@/common/targetTypes'
import poll from '@/common/poll'

const controller = new AbortController()
const asset = {
  namespaced: true,
  mutations: {
    CHANGE_COMPOUND_OBJECT_SEQUENCE(_, payload) {
      const { tabs, oldIndex, newIndex, tabIndex, object } = payload

      tabs[tabIndex].compoundObject.splice(oldIndex, 1)
      tabs[tabIndex].compoundObject.splice(newIndex, 0, object)

      tabs[tabIndex].compoundObject.forEach((object, index) => {
        if (object.sequence_number !== index + 1) {
          object.sequence_number = index + 1
          object._dirty = true
        }
      })
    }
  },
  actions: {
    abortAssetFetch() {
      controller.abort()
    },
    async fetchAssetDetailsWithoutStatus(context, options) {
      const { assetId, representationId, tabIndex } = options
      if (!assetId || !representationId) {
        return
      }

      // For images uploaded long time ago (not using v2 at all), the status endpoint will return nothing
      // In this situation, we want to go ahead and still call representation details
      const detailsRes = await getAssetRepresentationDetails(assetId, representationId)
      if (detailsRes.data.data?.storData?.type || !detailsRes.data.data) {
        const data = { path: ['current', 'details'], value: detailsRes.data }
        await context.dispatch('tabs/addTabStateDetailToPath', { tabIndex, data }, { root: true })
      }
    },
    async fetchAssetDetails(context, options) {
      const { assetId, representationId, tabIndex } = options
      if (!assetId || !representationId) {
        return
      }

      const storId = representationId.split(':')[1]
      const res = await poll(
        async () => {
          try {
            return await getAssetRepresentationStatus(storId, controller.signal)
          } catch (err) {
            if (err.message === 'canceled') {
              return
            } else if (err.status !== 404) {
              throw err
            }
          }
        },
        res => res && res.data.success === true,
        {
          interval: 1000,
          timeout: 10000
        },
        true
      )

      // For images uploaded long time ago (not using v2 at all), the status endpoint will return nothing
      // In this situation, we want to go ahead and still call representation details
      if (!res || (res.data && res.data.success)) {
        const detailsRes = await getAssetRepresentationDetails(assetId, representationId)
        if (detailsRes.data.data?.storData?.type || !detailsRes.data.data) {
          const data = { path: ['current', 'details'], value: detailsRes.data }
          await context.dispatch('tabs/addTabStateDetailToPath', { tabIndex, data }, { root: true })
        }
      }
    },
    async clearAssetMedia(context, options) {
      const { tabIndex } = options
      const { projectId, assetId } = options
      await updateProjectAssetClearMedia(projectId, assetId)
      const data = { path: ['current', 'details'], value: { details: 'success' } }
      await context.dispatch('tabs/addTabStateDetailToPath', { tabIndex, data }, { root: true })
    },
    async fetchMediaObjects(context, options) {
      const { assetId } = options
      const signal = options.signal ?? null
      return await getAssetMediaObjects(assetId, signal)
    },
    async fetchMediaObjectDetails(context, options) {
      const { objectId, tabIndex } = options
      const res = await getMediaObjectRepresentationDetails(objectId)
      const data = { path: ['current', 'details'], value: res.data.media_object }
      context.dispatch('tabs/addTabStateDetailToPath', { tabIndex, data }, { root: true })
    },
    async fetchMediaObjectShortLink(context, options) {
      const { tabIndex, id } = options

      const res = await getMediaObjectShortURL(id)
      const data = { shortLink: res.data.token }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    async fetchAsset(context, options) {
      const assetId = options.assetId

      try {
        return await getAsset(assetId)
      } catch (err) {
        console.error(err)
      }
    },
    async deleteMediaObject(context, options) {
      const { assetId, objectId } = options
      return await deleteAssetMediaObject(assetId, objectId)
    },
    async fetchCatalogingScreens(context, options) {
      const { tabIndex, projectId } = options
      const res = await getProjectForms(projectId)
      const data = { screens: res.data.items }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
      return res.data
    },
    async fetchFormFields(context, options) {
      const { tabIndex, screenId, screenIndex = 0 } = options

      const res = await getFormFields(screenId)
      const resFields = res.data.components

      const fields = formatFields(resFields)

      const data = { path: ['screens', screenIndex, 'fields'], value: fields }
      context.dispatch('tabs/addTabStateDetailToPath', { tabIndex, data }, { root: true })

      return res.data
    },
    async fetchPublishingInfo(context, options) {
      const { tabIndex, assetId } = options

      const res = await getAssetPublicationStatus(assetId)
      const data = { publishInfo: res.data }

      for (const status of res.data.statuses) {
        if (isJstorTarget(status.target) && status.publishing_status.status === 'Published') {
          const doiRes = await getAssetDoi(assetId)
          const { doi } = doiRes.data
          status.publishing_status.doi = doi
        }
      }

      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    async fetchAssetShortLink(context, options) {
      const { tabIndex, assetId, representationId } = options

      const res = await getAssetShortURL(assetId, representationId)
      const data = { shortLink: res.data.token }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    switchScreen(context, options) {
      const { tabIndex, screenId } = options

      const data = { selectedScreen: screenId }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    setCurrentAsset(context, options) {
      const { tabIndex, asset } = options

      const data = { current: cloneDeep(asset) }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    setCompoundObject(context, options) {
      const { tabIndex, compoundObject } = options

      const data = { compoundObject }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    setCoIndex(context, options) {
      const { tabIndex, coIndex } = options

      const data = { coIndex }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    clearCompoundObject(context, options) {
      const { tabIndex } = options

      const data = { coIndex: 0, compoundObject: [] }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    changeCompoundObjectSequence(context, options) {
      context.commit('CHANGE_COMPOUND_OBJECT_SEQUENCE', { ...options, tabs: context.rootState.tabs.list })
    },
    selectBatchAsset(context, options) {
      const { tabIndex, assets } = options

      const data = {
        batchSelected: {
          type: 'ids',
          params: [...assets]
        }
      }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    async fetchBatchAssetItems(context, options) {
      const { tabIndex, projectId } = options

      const tab = context.rootState.tabs.list[tabIndex]
      const params = tab.query

      context.dispatch(
        'tabs/updateTabState',
        { tabIndex, data: { batchItems: [], batchLoading: true } },
        { root: true }
      )

      const assetType = tab.assetType || 'projects'
      try {
        let res
        if (assetType === 'saved-searches') {
          res = await querySavedFilterAssets(tab.paramId, { params }, tab.fetchBatchItemsSource)
        } else if (assetType === 'asset-groups') {
          res = await queryAssetGroupAssets(tab.paramId, { params }, tab.fetchBatchItemsSource)
        } else {
          // default to projects
          res = await queryProjectAssets(projectId, { params }, tab.fetchBatchItemsSource)
        }

        if (res.data) {
          const data = {
            batchLoading: false,
            batchItems: res.data.assets,
            batchTotal: res.data.total
          }
          context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error(err)
        }
      }
    },
    updateBatchQuery(context, options) {
      const { tabIndex, query } = options
      const data = { query }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    updateBatchType(context, options) {
      const { tabIndex } = options
      const data = {
        batchSelected: {
          type: 'all',
          params: []
        }
      }
      context.dispatch('tabs/updateTabState', { tabIndex, data }, { root: true })
    },
    async saveEdit(context, options) {
      const { projectId, assetId } = options

      const res = await updateProjectAsset(projectId, assetId, options.asset)

      return res
    }
  },
  getters: {}
}

export default asset
