import { addProjectAsset, updateProjectAsset } from '@/services/projects'
import { EventBus } from '@/common/eventBus.js'

const term = {
  namespaced: true,
  mutations: {},
  actions: {
    async addTerm(context, options) {
      const { projectId, asset } = options
      return await addProjectAsset(projectId, asset)
    },
    async saveEdit(context, options) {
      const { projectId, assetId, tabIndex, asset } = options
      try {
        const res = await updateProjectAsset(projectId, assetId, asset)

        if (res && res.data && res.data.success) {
          const asset = res.data.assets[0]
          EventBus.$emit('terms-updated', { projectId, assetId, tabIndex, asset })
        }

        return res
      } catch (err) {
        console.error(err)
      }
    }
  },
  getters: {}
}

export default term
