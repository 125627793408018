<template>
  <Trap :disabled="disableTrap" return-focus>
    <div
      ref="modal"
      :class="{ nested: isNested }"
      class="modal fade show d-block"
      tabindex="-1"
      role="dialog"
      @keydown="handleDown"
    >
      <div ref="overlay" class="modal-backdrop fade show" style="z-index: auto" title="" @click="tapOnOverlay" />
      <div
        :class="{ 'modal-lg': size === 'lg', 'modal-sm': size === 'sm' }"
        class="modal-dialog modal-animate h-100 d-flex flex-column justify-content-center my-0"
        role="document"
        title=""
      >
        <div ref="modal-content" class="modal-content">
          <div class="modal-header">
            <template v-if="$slots['modal-header']">
              <slot name="modal-header" />
            </template>
            <template v-else>
              <forum-ui-pharos-heading level="2" preset="5">{{ title }}</forum-ui-pharos-heading>
              <forum-ui-pharos-button
                :disabled="isLoading"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                variant="subtle"
                icon="close"
                a11y-label="close"
                label="close"
                @click="close"
              >
              </forum-ui-pharos-button>
            </template>
          </div>
          <div class="modal-body"><slot /></div>
          <div v-if="!noFooter" class="modal-footer">
            <template v-if="$slots['modal-footer']">
              <slot name="modal-footer" />
            </template>
            <template v-else>
              <forum-ui-pharos-button ref="okBtn" data-automation-component="ok-btn" @click="close">
                Ok
              </forum-ui-pharos-button>
            </template>
          </div>
        </div>
      </div>
      <template v-if="$slots['nested-modals']">
        <slot name="nested-modals" />
      </template>
    </div>
  </Trap>
</template>

<script>
import Trap from 'vue-focus-lock'

export default {
  name: 'ForumModal',
  components: {
    Trap
  },
  props: {
    size: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isNested: {
      type: Boolean,
      default: false
    },
    disableTrap: {
      type: Boolean,
      default: false
    },
    noFooter: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$refs.modal.focus()
    if (this.$refs.okBtn) {
      this.$refs.okBtn.focus()
    }
  },
  methods: {
    tapOnOverlay(event) {
      if (event.target === this.$refs.overlay) {
        this.close()
      }
    },
    close() {
      this.$emit('close')
    },
    handleDown(evt) {
      const escKey = 27
      if (evt.keyCode === escKey) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.red-x {
  color: #990000;
}

.modal {
  &-header {
    border-color: transparent;
    padding: $pharos-spacing-2-x $pharos-spacing-2-x 0 $pharos-spacing-2-x;
  }

  &-body {
    padding: 0 $pharos-spacing-2-x $pharos-spacing-3-x $pharos-spacing-2-x;
  }

  &-footer {
    border-color: transparent;
    padding: 0 $pharos-spacing-2-x $pharos-spacing-2-x $pharos-spacing-2-x;
  }
}
.modal-content {
  max-height: 100vh !important;
}

@media (max-width: 768px) {
  .modal-header {
    padding: 10px 20px;
  }

  .modal-footer {
    padding: 10px 20px;
  }
}

.modal.nested {
  transform: translateY(20px) translateX(20px);

  ::v-deep .modal-backdrop {
    display: none;
  }
}
</style>
