import { isValidDate } from './dateValidate'

export function getTimeZone(date) {
  const userLang = navigator.languages || navigator.language
  const options = {
    timeZoneName: 'short',
    hour12: false
  }
  if (isValidDate(date)) {
    const [, timeZone] = new Date(date).toLocaleTimeString(userLang, options).split(/\s+/)
    return timeZone
  }
  return ''
}
