import axios from 'axios'

/**
 * @description create a new list permission
 * @param {Number} projectId id of the project to add permission to
 * @returns {Promise} axios request promise
 */
export function createPermission({ projectId, role, users, filter, editFilter, forms }) {
  const form = new FormData()
  form.append('role_id', role)
  form.append('profile_id', users.join('|'))
  form.append('base_filter', filter)
  form.append('edit_filter', editFilter)
  if (forms && forms.length > 0) {
    forms.forEach(formId => {
      form.append('form_ids', formId)
    })
  }

  return axios.post(`/admin/projects/${projectId}/permissions`, form)
}

/**
 * @description Add shared lists to user
 * @param {Number} profile_id ID of user to add shared list to
 * @returns {Promise} axios request promise
 */
export function addShareListPermissions({ profileId }) {
  return axios.post(`/admin/permissions/get-shared-list-permissions`, { profile_id: profileId })
}

/**
 * @description delete user permissions for a list
 * @param {Number} projectId id of the project to remove permission from
 * @param {Number} permissionId id of the permission delete
 * @returns {Promise} axios request promise
 */
export function deletePermissions(projectId, permissionid) {
  return axios.delete(`/admin/projects/${projectId}/permissions/${permissionid}`)
}

/**
 * @description edit a given permission
 * @param {Number} projectId id of the project to add permission to
 * @param {Number} permissionId id of the permission to update
 * @returns {Promise} axios request promise
 */
export function updatePermission({ projectId, permissionId, ...formFields }) {
  const { role, users, filter, editFilter } = formFields
  const form = new FormData()
  form.append('role_id', role)
  form.append('profile_id', users.join('|'))
  form.append('base_filter', filter)
  form.append('edit_filter', editFilter)

  return axios.put(`/admin/projects/${projectId}/permissions/${permissionId}`, form)
}
