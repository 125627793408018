import { federatedGraphqlClient } from '../vue-apollo'
import gql from 'graphql-tag'

const FORUM_FLAGS_QUERY = gql`
  query FlagsFlagList($flagsFlagList: [String]) {
    flags(flagList: $flagsFlagList) {
      enabled
    }
  }
`

const defaultFlags = () => ({
  enabledFlags: []
})

const state = defaultFlags()

const actions = {
  fetchFeatureFlags({ commit }) {
    return federatedGraphqlClient
      .query({
        query: FORUM_FLAGS_QUERY,
        variables: {
          flagsFlagList: ['enable_av_permission', 'forum-transcript-srt']
        }
      })
      .then(response => {
        commit('SET_ENABLED_FLAGS', response.data.flags.enabled)
      })
      .catch(() => {
        console.error('Unable to fetch feature flags.')
      })
  }
}

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, defaultFlags())
  },
  SET_ENABLED_FLAGS(state, enabledFlags) {
    state.enabledFlags = enabledFlags
  }
}

const getters = {
  isFlagEnabled: state => flagName => {
    return state.enabledFlags.includes(flagName)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
