import { getCatalogingForms, deleteCatalogingForm, addCatalogingForm, updateCatalogingForm } from '../services/forms.js'
import cloneDeep from 'lodash/cloneDeep'

const defaultForms = () => ({
  catalogForms: []
})

const forms = {
  namespaced: true,
  state: defaultForms(),
  mutations: {
    SET_CATALOG_FORMS(state, catalogForms) {
      state.catalogForms = catalogForms
      state.catalogForms.sort((a, b) => a.position - b.position)
    },
    RESET_STATE(state) {
      Object.assign(state, defaultForms())
    }
  },
  actions: {
    async fetchCatalogingForms(context, options) {
      const { projectId, params } = options
      const res = await getCatalogingForms(projectId, { params })
      // reindex forms
      context.commit(
        'SET_CATALOG_FORMS',
        res.data.items.map((form, index) => {
          form.position = index + 1
          return form
        })
      )
    },
    addCatalogingForm(context, options) {
      const { projectId, formName } = options
      return addCatalogingForm(projectId, formName)
    },
    deleteCatalogingForm(context, options) {
      const { projectId, formId } = options
      return deleteCatalogingForm(projectId, formId)
    },
    async reorderCatalogingForms(context, options) {
      const { projectId, row, save, toIndex: startingToIdx } = options

      const newForms = cloneDeep(context.state.catalogForms)
      const toIndex = Math.max(0, Math.min(startingToIdx, newForms.length - 1))
      const fromIndex = newForms.findIndex(form => form.id === row.id)

      let forms = []
      const movingDown = fromIndex < toIndex
      const movingUp = fromIndex > toIndex

      if (movingDown) {
        forms = newForms.slice(fromIndex + 1, toIndex + 1)
        forms.forEach((form, index) => (form.position = fromIndex + index + 1))
      } else if (movingUp) {
        forms = newForms.slice(toIndex, fromIndex)
        forms.forEach((form, index) => (form.position = toIndex + index + 2))
      } else {
        return
      }

      newForms[fromIndex].position = toIndex + 1
      forms.splice(0, 0, newForms[fromIndex])

      if (save) {
        try {
          await Promise.all(forms.map(form => updateCatalogingForm(projectId, form)))
        } catch (e) {
          context.state.catalogForms[fromIndex].position = fromIndex + 1
          context.commit('SET_CATALOG_FORMS', context.state.catalogForms)
          throw e
        }
      }
      context.commit('SET_CATALOG_FORMS', newForms)
    }
  }
}

export default forms
