export function showToastInfo(message, returnElements = []) {
  popToast(message, 'info', returnElements)
}

export function showToastError(message, returnElements = []) {
  popToast(message, 'error', returnElements)
}

export function showToastSuccess(message, returnElements = []) {
  popToast(message, 'success', returnElements)
}

function popToast(message, status, returnElements) {
  const event = new CustomEvent('pharos-toast-open', {
    detail: {
      status: status,
      content: message,
      returnElements: returnElements
    }
  })

  document.dispatchEvent(event)
}
