import axios from 'axios'

/**
 * @description gets list of active user profiles
 * @returns an axios request promise
 */
export function getUsers() {
  return axios.get('/aaa/admin/users/manageUsers?type=active')
}

/**
 * @description create users from specified emails
 * @returns an axios request promise
 */
export function createUsers(emails) {
  return axios.post('/aaa/admin/users/create?source=forum', emails)
}

/**
 * @description enable forum for an existing user
 * @returns an axios request promise
 */
export function enableForum(profileId, isAdmin) {
  return axios.post(`/aaa/admin/users/updateUserDetails?profileId=${profileId}&source=forum`, {
    ssEnabled: true,
    ssAdmin: isAdmin,
    profileId
  })
}

/**
 * @description disable forum for an existing user
 * @returns an axios request promise
 */
export function disableForum(profileId) {
  return axios.post(`/aaa/admin/users/updateUserDetails?profileId=${profileId}&source=forum`, {
    ssEnabled: false,
    ssAdmin: false,
    profileId
  })
}
