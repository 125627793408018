import axios from 'axios'
import axiosRetry from 'axios-retry'
import { setupDistributedTracing } from '@/common/setupAxios'

// Works with custom axios instances
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay })
setupDistributedTracing('forum-ui', axios)

/**
 * @description get a users saved state
 * @returns {Promise} axios request promise
 */
export function getState(token, key) {
  return axios.get(`/user/state/${key}`, {
    headers: {
      Authorization: token
    }
  })
}

/**
 * @description save a users state
 * @param {object} data user state data
 * @returns {Promise} axios request promise
 */
export function saveState(token, key, state) {
  return axios.post(
    `/user/state/${key}`,
    { state },
    {
      headers: {
        Authorization: token
      }
    }
  )
}
