import axios from 'axios'

/**
 * @description fetch a list of cataloging form fields
 * @returns {Promise} axios request promise
 * @param formId
 * @param {object} options axios get request options
 *   options = { params: {
      with_meta: Boolean // send metadata information or not
    }}
 */
export function getCatalogingFormFields(formId, options) {
  return axios.get(`/admin/forms/${formId}/fields`, options)
}

/**
 * @description fetch a list of unassigned cataloging form fields
 * @returns {Promise} axios request promise
 * @param formId
 * @param projectId
 * @param {object} options axios get request options
 *   options = { params: {
      with_meta: Boolean // send metadata information or not
    }}
 */
export function getUnassignedFormFields(formId, projectId, options) {
  return axios.get(`/admin/projects/${projectId}/forms/${formId};unassigned-fields`, options)
}

/**
 * @description add a cataloging form field
 * @param formId
 * @param {object} formField axios get request options
 * @returns {Promise} axios request promise
 * @params formId: form ID to which to add the field, formField: form field to add
 *
 */
export function addCatalogingFormFields(formId, {id, position}) {
  let formData = new FormData()
  formData.set('field_definition_id', id)
  formData.set('position', position)
  return axios.post(`/admin/forms/${formId}/fields`, formData)
}

/**
 * @description update a cataloging form field
 * @returns {Promise} axios request promise
 * @param {object} options axios get request options
 */
export function updateCatalogingFormField(formId, field) {
  const { id } = field
  const params = new URLSearchParams()
  params.append('items', JSON.stringify(field))
  return axios.put(`/admin/forms/${formId}/fields/${id}`, params)
}

/**
 * @description delete a form field
 * @returns {Promise} axios request promise
 * @param {Number} formId id of form the field belongs to
 * @param {Number} fieldId id of field to delete
 */
export function deleteCatalogingFormField(formId, fieldId) {
  return axios.delete(`/admin/forms/${formId}/fields/${fieldId}`)
}
