<template>
  <forum-ui-pharos-alert v-if="shouldShow" :status="alertStyle" v-html="message"></forum-ui-pharos-alert>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    message: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: undefined
    },
    alertStyle: {
      type: String,
      default: 'error'
    }
  },
  computed: {
    shouldShow() {
      return (this.message.length && typeof this.show === 'undefined') || this.show
    }
  }
}
</script>
