export const INSTITUTIONS = {
  ARTSTOR: 1000,
  SAMPLE_U: 24615,
  HARVARD: 10003,
  VANDERBILT: 10217,
  JSTOR_INTERNAL_TEST: 402261445,
  CHATHAM: 10757,
  XYZ_UNIV_USER_TEST: 402680055
}

export const WORK_RECORDS_ENABLED_INSTITUTIONS = [INSTITUTIONS.HARVARD, INSTITUTIONS.SAMPLE_U]
