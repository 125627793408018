import { searchAAT, getAatDetails } from '../services/vocabularies/aat'
import { authenticate, searchNames, advancedSearchNames, searchFields } from '../services/vocabularies/name'
import { searchTGN, getTgnDetails } from '../services/vocabularies/tgn'

const vocabularies = {
  namespaced: true,
  mutations: {},
  actions: {
    authenticate(context, params) {
      return authenticate(params)
    },
    searchNames(context, params) {
      return searchNames(params)
    },
    searchTGN(context, params) {
      return searchTGN(params)
    },
    getTgnDetails(context, params) {
      return getTgnDetails(params)
    },
    searchAAT(context, params) {
      return searchAAT(params)
    },
    getAatDetails(context, params) {
      return getAatDetails(params)
    },
    advancedSearchNames(context, params) {
      return advancedSearchNames(params)
    },
    searchFields(context, params) {
      return searchFields(params)
    }
  },
  getters: {}
}

export default vocabularies
