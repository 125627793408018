import { getProjectSummary } from '@/pages/project-settings/services/projectSettings'
import { getFieldDefinitions, deleteFieldDefinition } from '@/pages/project-settings/services/projectFields'
import { getPublishingTargets, deletePublishingTarget } from '@/services/admin/projectPublishingTargets'
import { getPermissions } from '@/services/admin/projectPermissions'
import { saveProjectWorkmappings, getWorkMap } from '@/services/projectWorkMappings'
import { createProject, getAdminMediaProjects } from '@/services/admin/projects'
import { getInstitutions } from '@/services/admin/institutions'
import { getTemplateDetails, getTemplates } from '@/services/admin/urls'
import { getPublishingTargetMappings } from '@/services/admin/publishingTargets'

// 1: Institutional collection in Artstor
// 2: Public collection in Artstor
// 7: Personal collection in Artstor
const DISABLED_TARGET_TYPE_IDS = [1, 2, 7]

export const defaultProjectSettings = () => ({
  items: [],
  summary: {},
  definitions: [],
  permissions: [],
  publishingTargets: [],
  workRecord: {
    chkValue: false,
    selected_map: 0,
    selected_map_name: ''
  },
  institutions: [],
  templates: []
})

const projectSettings = {
  namespaced: true,
  state: defaultProjectSettings(),
  mutations: {
    SET_LIST(state, projects) {
      projects.forEach(project => {
        if (!project.name) {
          project.name = 'No name available'
        }
      })
      state.items = projects
    },
    SET_DEFINITIONS(state, definitions) {
      state.definitions = definitions
    },
    UPDATE_DEFINITION_MAPPING(state, mapInfo) {
      state.definitions = state.definitions.map(definition => ({
        ...definition,
        has_work_mapping: mapInfo[definition.id]
      }))
    },
    ADD_DEFINITION(state, definition) {
      state.definitions.push(definition)
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
    SET_PUBLISHING_TARGETS(state, publishingTargets) {
      state.publishingTargets = publishingTargets
    },
    REMOVE_PUBLISHING_TARGET(state, collectionId) {
      state.publishingTargets = state.publishingTargets.filter(target => target.id !== collectionId)
    },
    SET_SUMMARY(state, summary) {
      Object.assign(state, summary)
    },
    SET_WORK_MAP(state, workRecordData) {
      state.workRecord.chkValue = workRecordData.chkValue
      state.workRecord.selected_map = workRecordData.selected_map
      state.workRecord.selected_map_name = workRecordData.selected_map_name
    },
    SET_INSTITUTIONS(state, items) {
      state.institutions = items
    },
    SET_TEMPLATES(state, items) {
      state.templates = items
    },
    RESET_STATE(state) {
      Object.assign(state, defaultProjectSettings())
    },
    SET_MAPPINGS(state, options) {
      const { targetId, mapping } = options
      const index = state.publishingTargets.findIndex(item => item.id === targetId)
      state.publishingTargets[index].mapping = mapping
    }
  },
  actions: {
    async fetchAdminProjects(context) {
      const res = await getAdminMediaProjects()
      context.commit('SET_LIST', res.data.projects)
      return res
    },
    createProject(_context, params) {
      return createProject(params)
    },
    async fetchInstitutions(context) {
      const response = await getInstitutions()
      const { institutions } = response.data
      context.commit('SET_INSTITUTIONS', institutions)
      return response
    },
    async fetchTemplates(context) {
      const response = await getTemplates()
      const { templates } = response.data
      context.commit('SET_TEMPLATES', templates)
      return response
    },
    async fetchTemplateDetails(_context, templateId) {
      return getTemplateDetails(templateId)
    },
    async fetchProjectSummary(context, options) {
      const { projectId } = options
      const res = await getProjectSummary(projectId)
      context.commit('SET_SUMMARY', res.data)
    },
    async fetchDefinitions(context, options) {
      const { projectId } = options
      const res = await getFieldDefinitions(projectId)
      context.commit('SET_DEFINITIONS', res.data.items)
      context.commit('projects/CLEAR_PROJECT_ID', null, { root: true })
      context.commit('controlledLists/CLEAR_PROJECT_ID', null, { root: true })
    },
    async fetchProjectPublishingTargets(context, options) {
      const { projectId } = options
      const res = await getPublishingTargets(projectId)
      let targets = res.data.publishing_targets
      targets = targets.filter(target => !DISABLED_TARGET_TYPE_IDS.includes(target.target_id))
      context.commit('SET_PUBLISHING_TARGETS', targets)
    },
    async fetchPermissions(context, options) {
      const { projectId } = options
      const res = await getPermissions(projectId)
      context.commit('SET_PERMISSIONS', res.data.project_permissions)
      return res.data.project_permissions
    },
    async fetchProjectWorkMapping(context, options) {
      const { projectId } = options
      const res = await saveProjectWorkmappings(projectId, { chkValue: '' })
      context.commit('SET_WORK_MAP', res.data)
    },
    async fetchProjectWorkMapDetails(context, mapId) {
      const response = await getWorkMap(mapId)
      const parsedResponse = JSON.parse(response.data.response.map)
      const fields = parsedResponse.fields
      if (fields) {
        const workMap = fields.reduce((acc, item) => {
          acc[item.displayFieldId] = item.workFields.length > 0
          return acc
        }, {})
        context.commit('UPDATE_DEFINITION_MAPPING', workMap)
      }
    },
    async deleteProjectPublishingTarget(context, options) {
      const { projectId, collectionId } = options
      const res = await deletePublishingTarget(projectId, collectionId)
      context.commit('REMOVE_PUBLISHING_TARGET', collectionId)
      return res
    },
    async deleteDefinition(_context, options) {
      const { projectId, fieldId } = options
      await deleteFieldDefinition(projectId, fieldId)
    },
    async getPublishingTargetMappings(context, options) {
      const { targetId } = options
      const res = await getPublishingTargetMappings(targetId)
      context.commit('SET_MAPPINGS', { targetId, mapping: res.data })
    }
  },
  getters: {
    getMapping: state => targetId => {
      const mapping = state.publishingTargets.find(target => target.id === targetId)?.mapping || []
      return mapping.reduce((arr, item) => {
        if (item.label !== 'Unmapped') {
          item.children.forEach(field => {
            arr.push({ projectField: field.name, targetField: item.label })
          })
        }
        return arr
      }, [])
    },
    getProject: state => projectId => {
      return state.items.find(item => item.id === projectId) || {}
    },
    projectName: (_state, getters) => projectId => {
      return getters.getProject(projectId).name
    },
    projectDescription: (_state, getters) => projectId => {
      return getters.getProject(projectId).description
    },
    instituteOptions: state =>
      state.institutions.map(item => ({
        value: item.id,
        text: item.name,
        shortName: item.shortName
      })),
    templateOptions: state => [
      ...state.templates
        .map(item => ({
          value: item.id,
          text: item.name,
          description: item.description,
          shared: item.shared,
          restrict: item.name.toLowerCase().includes('jstor')
        }))
        .sort((a, b) => {
          if (a.restrict) {
            return -1
          }

          if (b.restrict) {
            return 1
          }

          return a.value - b.value
        })
    ]
  }
}

export default projectSettings
