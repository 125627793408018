import { getLanguages, list, upload, update, remove, setDefault } from '../services/media/captions'

const defaultCaptions = () => ({
  loading: false,
  error: '',
  items: []
})

const captions = {
  namespaced: true,
  state: defaultCaptions(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, defaultCaptions())
    }
  },
  actions: {
    getLanguages() {
      return getLanguages()
    },
    async fetchCaptions(context, entryId) {
      return await list(entryId)
    },
    async uploadCaption(context, options) {
      const { entryId, caption } = options
      return await upload(entryId, caption)
    },
    async updateCaption(context, options) {
      const { entryId, caption } = options
      return await update(entryId, caption)
    },
    async removeCaption(context, options) {
      const { entryId, caption } = options
      return await remove(entryId, caption.id)
    },
    async setDefaultCaption(context, options) {
      const { entryId, caption } = options
      return await setDefault(entryId, caption.id)
    }
  },
  getters: {}
}

export default captions
