import axios from 'axios'

/**
 * @description start the process of resetting a user's password
 * @param {string} email user's email address
 * @param {string} userUuid the UUID of the current requestor
 * @returns {Promise} axios request promise
 */
export function requestPasswordReset(email) {
  return axios.post('/request-reset-password', { email })
}

/**
 * @description If the given signature is associated with the given email address and has not expired, this will return True. Otherwise, False.
 * @param {string} email user's email address
 * @param {string} signature provided in the email
 * @returns {Promise} axios request promise
 */
export function validateResetRequest(email, signature) {
  const params = { email, signature }
  return axios.get('/password/reset/request', { params })
}

/**
 * @description reset the password for the user with the submitted e-mail address.
 * @param {string} email user's email address
 * @param {string} signature provided in the email
 * @param {string} password new password
 * @returns {Promise} axios request promise
 */
export function resetPassword(email, signature, password) {
  const body = new URLSearchParams()
  body.append('email', email)
  body.append('signature', signature)
  body.append('password', password)
  body.append('requestOrigin', 'forum')
  return axios.post('/password/reset', body)
}
