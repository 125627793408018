import axios from 'axios'

/**
 * @description fetch a list of publishing target mappings
 * @returns {Promise} axios request promise
 * @param targetId
 */
export function getPublishingTargetMappings(targetId) {
  return axios.get(`/admin/publishing-targets/${targetId}/publishing-target-mappings`).then(response => {
    response.data = response.data.filter(({ label }) => {
      return label !== 'content_type'
    })
    return response
  })
}

/**
 * @description update publishing target mappings
 * @returns {Promise} axios request promise
 * @param targetId
 */
export function updatePublishingTargetMappings(params) {
  const { targetId, mappings } = params
  const form = new FormData()
  form.append('mapping', JSON.stringify(mappings))

  return axios.put(`/admin/publishing-targets/${targetId}/publishing-target-mappings`, form)
}

/**
 * @description get target collection usage
 * @returns {Promise} axios request promise
 * @param targetId
 * @param collectionId
 */
export function getTargetCollectionUsage(params) {
  const { targetId, collectionId } = params
  return axios.get(`/admin/targets/${targetId}/collections/${collectionId}/usage`)
}

/**
 * @description update publishing target mappings for JSTOR target to existing artstor target mapping
 * @returns {Promise} axios request promise
 * @param targetId
 */
export function saveJstorTargetMapping(params) {
  const { targetId } = params

  return axios.put(`/admin/publishing-targets/${targetId}/aiw-jstor-auto-mappings`)
}
