import axios from 'axios'

/**
 * @description fetch terms for provided list
 * @param {number} listId id of the list
 * @param {object} options axios get request options
 *  options = { params: {
      with_meta: Boolean // send metadata information or not
      institution_id // institution ID
      limit // number of results to return
      query // search query
    }}
 * @returns {Promise} axios request promise
 */
export function getTerms(listId, options) {
  const { params } = options
  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/mfcl/${listId}/search/terms?${queryParams}`)
}
