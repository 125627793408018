import axios from 'axios'

/**
 * @description fetch a list of vocab projects
 * @returns {Promise} axios request promise
 */
export function getVocabProjects() {
  return axios.get('/admin/vocab-projects')
}

/**
 * @description fetch vocab list usage
 * @returns {Promise} axios request promise
 */
export function getVocabUsage(listId) {
  return axios.get(`/admin/vocab-list-usage/${listId}`)
}

/**
 * @description fetch vocab list usage details
 * @returns {Promise} axios request promise
 */
export function getVocabUsageDetails(listId) {
  return axios.get(`/admin/vocab-list-usage-details/${listId}`)
}
