import axios from 'axios'

export function queryKTSListThumbnail(params) {
  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/kts/listthumbnail?${queryParams}`)
}

export function queryKTSDefaultThumbnail(params) {
  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/kts/defaultthumbnail?${queryParams}`)
}

export function queryKTSRemoveThumbnail(params) {
  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/kts/removethumbnail?${queryParams}`)
}

export function saveKTSUpdateThumbnail(id, file) {
  const formData = new FormData()
  formData.append('file', file)

  return axios.post(`/kts/updatethumbnail_file?id=${id}&default=true`, formData)
}
