/* eslint-disable no-console */
import axios from 'axios'

/**
 * @description get a portal id if it exists for the institution
 * @returns {Promise} axios request promise
 */
export function getPortal() {
  return axios.get('/ccollection/v1/portals?default=true')
}

/**
 * @description get JSTOR collections
 * @returns {Promise} axios request promise
 */
export function getJstorCollections() {
  return axios.get('/ccollection/v1/collections')
}

/**
 * @description get JSTOR collection by ID
 * @returns {Promise} axios request promise
 */
export function getJstorCollectionInfo(collectionId) {
  return axios.get(`/ccollection/v1/collections/${collectionId}`)
}

/**
 * @description update JSTOR collection by ID
 * @returns {Promise} axios request promise
 */
export function savePortal(options) {
  const { id, name, description, slug, canonical_url, default: isDefault, ...preferences } = options
  const form = {
    name,
    description,
    slug,
    canonical_url,
    default: isDefault,
    preferences
  }

  return axios.put(`/ccollection/v1/portals/${id}`, form)
}

/**
 * @description create a portal if one doesn't exist for the institution
 * @returns {Promise} axios request promise
 */
export function createPortal(options) {
  const { name, description, slug, canonical_url, preferences } = options
  const form = {
    default: true,
    name,
    description,
    slug,
    canonical_url,
    preferences: { ...preferences }
  }

  return axios.post('/ccollection/v1/portals', form)
}

/**
 * @description create a new JSTOR collection
 * @returns {Promise} axios request promise
 * @param {object} options axios get request options
 */
export function createJstorCollection(options) {
  const {
    name,
    description,
    slug,
    canonical_url,
    type,
    portal_id,
    download_size,
    image_caption,
    background_color,
    banner_image,
    banner_image_iiif
  } = options

  const preferences = { background_color, banner_image, banner_image_iiif }

  const form = {
    name,
    description,
    slug,
    canonical_url,
    type,
    portal_id,
    download_size,
    image_caption,
    preferences: {
      ...preferences,
      logos: [],
      logos_iiif: []
    }
  }

  return axios.post('/ccollection/v1/collections', form)
}

/**
 * @description delete JSTOR collection by ID
 * @returns {Promise} axios request promise
 */
export function deleteJstorCollection(options) {
  const { collectionId } = options
  return axios.delete(`/ccollection/v1/collections/${collectionId}`)
}

/**
 * @description update JSTOR collection by ID
 * @returns {Promise} axios request promise
 */
export function saveJstorCollection(options) {
  const {
    id,
    name,
    description,
    slug,
    canonical_url,
    type,
    portal_id,
    download_size,
    image_caption,
    background_color,
    banner_image,
    banner_image_iiif
  } = options

  const preferences = { background_color, banner_image, banner_image_iiif }

  const form = {
    name,
    description,
    slug,
    canonical_url,
    type,
    portal_id,
    download_size,
    image_caption,
    preferences: {
      ...preferences,
      logos: [],
      logos_iiif: []
    }
  }
  return axios.put(`/ccollection/v1/collections/${id}`, form)
}
