<template>
  <svg class="icon">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="`static/symbol-defs.svg?v=${cacheBust}#${icon}`" />
  </svg>
</template>

<script>
export default {
  name: 'ForumIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    cacheBust() {
      return process.env.CACHE_BUST
    }
  }
}
</script>

<style lang="scss">
.icon {
  fill: currentColor;
  width: 1em;
  max-height: 1em;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;

  &.success {
    fill: rgb(20, 221, 20);
  }

  &.error {
    fill: rgb(226, 19, 19);
  }
}
</style>
