import { getCollections, createElementSets, createItemTypes } from '@/services/admin/targets'
import { createOmekaApiTarget } from '@/services/admin/projectPublishingTargets'

function defaultOmekaState() {
  return {
    fields: {
      url: '',
      apiKey: '',
      collectionId: null,
      elementSets: [],
      itemTypes: []
    },
    collections: [],
    fetchedCollections: false,
    elementSets: [],
    fetchedElementSets: false,
    itemTypes: [],
    fetchedItemTypes: false
  }
}

const omekaTarget = {
  namespaced: true,
  state: defaultOmekaState,
  mutations: {
    SET_FIELDS(state, fields) {
      Object.assign(state, defaultOmekaState())
      Object.assign(state.fields, fields)
    },
    SET_COLLECTIONS(state, collections) {
      state.collections = collections
      state.fetchedCollections = true
    },
    SET_ELEMENT_SETS(state, sets) {
      state.elementSets = sets
      state.fetchedElementSets = true
    },
    SET_ITEM_TYPES(state, types) {
      state.itemTypes = types
      state.fetchedItemTypes = true
    },
    SAVE_SELECTION(state, params) {
      Object.assign(state.fields, params)
    },
    RESET_STATE(state) {
      Object.assign(state, defaultOmekaState())
    }
  },
  actions: {
    async resetState(context) {
      context.commit('RESET_STATE')
    },
    setDetails(context, fields) {
      if (fields.url !== context.state.fields.url || fields.apiKey !== context.state.fields.apiKey) {
        context.commit('SET_FIELDS', fields)
      }
    },
    async getCollections(context, target) {
      if (context.state.fetchedCollections) {
        return
      }

      const { url, apiKey } = context.state.fields
      const institutionId = context.rootState.user.institution_id

      const res = await getCollections({
        institutionId: institutionId,
        targetId: target.id,
        userId: null,
        url,
        apiKey
      })
      context.commit('SET_COLLECTIONS', res.data.sub_targets)
      return res.data.sub_targets
    },
    async createElementSets(context) {
      if (context.state.fetchedElementSets) {
        return
      }

      const { url, apiKey } = context.state.fields
      const res = await createElementSets(url, apiKey)
      context.commit('SET_ELEMENT_SETS', res.data.element_sets)
      return res.data.element_sets
    },
    async createItemTypes(context) {
      if (context.state.fetchedItemTypes) {
        return
      }

      const { url, apiKey } = context.state.fields
      const res = await createItemTypes(url, apiKey)
      context.commit('SET_ITEM_TYPES', res.data.item_types)
      return res.data.item_types
    },
    async saveSelection(context, params) {
      context.commit('SAVE_SELECTION', params)
    },
    async createOmekaTarget(context, { project, target }) {
      const { url, apiKey: token, collectionId } = context.state.fields
      const collection = context.state.collections.find(collection => collection.id === collectionId)
      let collectionName = collection && collection.title

      const findAndFilter = (list, selection) =>
        list.filter(({ id }) => selection.includes(id)).map(({ id, name, description }) => ({ id, name, description }))

      const elementSets = findAndFilter(context.state.elementSets, context.state.fields.elementSets)
      const itemTypes = findAndFilter(context.state.itemTypes, context.state.fields.itemTypes)

      if (itemTypes.length) {
        collectionName = `${collectionName} - ${itemTypes[0].name}`
      }

      return createOmekaApiTarget({
        projectId: project.id,
        name: collectionName,
        target_id: target.id,
        target_name: target.name,
        routing_identifier: collectionId,
        targetConfiguration: {
          url,
          token,
          element_sets: elementSets,
          item_types: itemTypes
        }
      })
    }
  },
  getters: {}
}

export default omekaTarget
