<template></template>
<script>
/* eslint vue/valid-template-root: 0 */
import _ from 'lodash'
import { EventBus } from '@/common/eventBus.js'

export default {
  props: {
    filter: {
      type: Function,
      default: function() {}
    }
  },
  data() {
    return {
      events: Object.keys(this.$listeners),
      methods: _.mapValues(this.$listeners, (value, key) => this.handleEvent.bind(this, key))
    }
  },
  mounted() {
    this.events.forEach(event => {
      this.methods[event].SOMETHING = event
      EventBus.$on(event, this.methods[event])
    })
  },
  destroyed() {
    this.events.forEach(event => {
      EventBus.$off(event, this.methods[event])
    })
  },
  methods: {
    handleEvent(name, params) {
      if (this.filter && this.filter(name, params) === false) {
        return
      }

      this.$emit(name, params)
    }
  }
}
</script>
