import {
  getEmailMap,
  getPublicationData,
  publishAssets,
  suppressAssets,
  getTargets,
  addPublishingNotification
} from '../services/publishing.js'

const defaultPublishing = () => ({
  emailMap: {},
  publicationBatches: {
    items: [],
    metaData: {}
  },
  publicationStatus: {
    items: [],
    metaData: {}
  },
  propagationData: {
    jobItems: [],
    total: 0
  },
  publishedRecords: {
    results: [],
    targets: []
  },
  suppressedRecords: {
    results: [],
    targets: []
  }
})

const publishing = {
  namespaced: true,
  state: defaultPublishing(),
  mutations: {
    FETCHED_EMAIL_MAP(state, emails) {
      state.emailMap = emails
    },
    FETCHED_PUBLICATION_BATCHES(state, publicationBatches) {
      state.publicationBatches = publicationBatches
    },
    FETCHED_PUBLICATION_STATUS(state, status) {
      state.publicationStatus = status
    },
    FETCHED_PROPAGATION(state, propagationData) {
      state.propagationData = propagationData
    },
    PUBLISHED_RECORDS(state, publishedRecordsData) {
      state.publishedRecords = publishedRecordsData
    },
    SUPPRESSED_RECORDS(state, suppressedRecordsData) {
      state.suppressedRecords = suppressedRecordsData
    },
    RESET_STATE(state) {
      Object.assign(state, defaultPublishing())
    }
  },
  actions: {
    async fetchEmailMap(context) {
      const res = await getEmailMap()
      context.commit('FETCHED_EMAIL_MAP', res.data)
    },
    async fetchPropagationData(context, options) {
      const res = await getPublicationData(options)
      context.commit('FETCHED_PROPAGATION', res.data)
    },
    async publishRecords(context, options) {
      try {
        const res = await publishAssets(options)

        if (typeof res.data === 'undefined') {
          throw new Error(res.message)
        }

        context.commit('PUBLISHED_RECORDS', res.data)
      } catch (err) {
        console.error(err)
      }
    },
    async suppressRecords(context, options) {
      const res = await suppressAssets(options)
      context.commit('SUPPRESSED_RECORDS', res.data)
    },
    async fetchTargets(context, options) {
      const { projectId } = options
      return await getTargets(projectId)
    },
    async addPublishingNotification(context, options) {
      return addPublishingNotification(options)
    }
  },
  getters: {}
}

export default publishing
