import get from 'lodash/get'
import flatten from 'lodash/flatten'
import intersectionBy from 'lodash/intersectionBy'
import groupBy from 'lodash/groupBy'
import dayjs from 'dayjs'
import { getTimeZone } from '../../common/timeFormat'
import FilterConfig from './filters/FilterConfig'
import ThumbnailRenderer from './renderers/ThumbnailRenderer'

const checkboxSelection = {
  selectcolumn: true
}

const pinnedColumn = {
  selectcolumn: 'left',
  thumbnailcolumn: 'left'
}

const lockedColumn = {
  selectcolumn: true
}

const suppressedResize = {
  selectcolumn: true
}

const cellRendererFrameworks = {
  thumbnailcolumn: ThumbnailRenderer
}

function getParamValue(params, customIndex) {
  const dataIndex = customIndex || params.colDef.dataIndex
  return params.data.invalid_data?.[dataIndex] || get(params.data, dataIndex)
}

const valueGetters = colType => {
  const defaultGetter = col => getParamValue(col)

  const getters = {
    lookupcolumn: params => {
      const value = getParamValue(params)
      return value && (value.display_value ? value.display_value : value.length ? value : '')
    },
    publicationstatuscolumn: params => {
      const value = getParamValue(params)
      return value || 'Not published'
    },
    profilecolumn: params => {
      const value = getParamValue(params)
      if (value) {
        const obj = params.colDef.options.find(p => p.id === value)
        return (obj && obj.text) || 'Unknown User'
      }
    },
    publicationdatecolumn: params => {
      let value = getParamValue(params)

      if (!value) {
        const properKey = params.colDef.dataIndex.split('-').join('.')
        value = get(params.data, properKey)
      }

      if (value) {
        value = `${dayjs(value).format('YYYY-MM-DD HH:mm')} ${getTimeZone(value)}`
      }

      return value
    },
    publicationusercolumn: params => {
      const value = getParamValue(params)
      if (value) {
        const obj = params.colDef.options.find(p => p.id === value)
        return (obj && obj.text) || 'Unknown User'
      }
    },
    datecolumn: params => {
      const value = getParamValue(params)

      if (!value) {
        return value
      }
      if (params.colDef.field.startsWith('fd_')) {
        return dayjs(value).format('YYYY-MM-DD')
      }

      return `${dayjs(value).format('YYYY-MM-DD HH:mm')} ${getTimeZone(value)}`
    },
    nullablebooleancolumn: params => {
      const value = getParamValue(params)
      if (typeof value !== 'string' && value !== undefined && value !== null) {
        return value === false ? 'False' : 'True'
      }
      return value
    },
    levelcolumn: params => {
      const value = getParamValue(params)
      return value === 1 ? 'Community' : 'Local'
    },
    institutioncolumn: params => {
      const value = getParamValue(params, 'institution_id')
      if (value) {
        return params.colDef.institutions[value] || 'Unknown Institution'
      }
    },
    valueindexcolumn: params => {
      const value = getParamValue(params)
      if (value) {
        return params.colDef.lookupTable[value]
      }
    }
  }

  return getters[colType] || defaultGetter
}

const cellClass = {
  thumbnailcolumn: 'ag-cell-thumbnail',
  selectcolumn: 'ag-cell-select'
}

const suppressFilterFrameworks = (xtype, col) => {
  let isSuppressFilter = true

  if (col.xtype === 'publicationstatuscolumn') {
    isSuppressFilter = false
  } else if (xtype !== undefined) {
    switch (xtype.type) {
      case 'nullstring':
        isSuppressFilter = false
        break
      case 'nullnumeric':
        isSuppressFilter = false
        break
      case 'nullalphanum':
      case 'profile':
        isSuppressFilter = false
        break
      case 'nulllink':
        isSuppressFilter = false
        break
      case 'nullbool':
        isSuppressFilter = false
        break
      case 'date':
        isSuppressFilter = false
        break
      case 'nulldate':
        isSuppressFilter = false
        break
      case 'valueindex':
        isSuppressFilter = false
        break
      default:
        break
    }
  } else if (col.dataIndex === 'id') {
    isSuppressFilter = false
  } else if (col.xtype === 'thumbnailcolumn') {
    isSuppressFilter = false
  } else if (col.xtype === 'nullablebooleancolumn') {
    isSuppressFilter = false
  } else if (col.xtype === 'datecolumn') {
    isSuppressFilter = false
  }

  return isSuppressFilter
}

const defaultSort = (type, index, dataIndex) => {
  if (type === 'projects') {
    return dataIndex === 'id' ? 'desc' : null
  } else if (type === 'controlledLists') {
    return index === 1 ? 'asc' : null
  }
}

const filterOptions = filter => {
  if (filter.type === 'profile') {
    return intersectionBy(filter.options, 'id')
  }
  return filter.options
}

export const tooltipFormatter = col => {
  const excludeTooltip = ['selectcolumn', 'thumbnailcolumn']
  if (excludeTooltip.includes(col.xtype)) {
    return null
  }

  return valueGetters(col.xtype)
}

const cellClassRules = {
  'invalid-data': params => {
    return !!params.data.invalid_data?.[params.colDef.dataIndex]
  }
}

export const transformColumns = (columns, type, suppressFilterSort = false, institutions = {}) =>
  columns.map((col, index) => ({
    ...col,
    institutions,
    headerCheckboxSelection: checkboxSelection[col.xtype],
    checkboxSelection: checkboxSelection[col.xtype],
    pinned: col.skipPinning ? false : pinnedColumn[col.xtype],
    lockPosition: lockedColumn[col.xtype],
    resizable: !suppressedResize[col.xtype],
    headerName: col.header,
    field: col.dataIndex,
    tooltipValueGetter: tooltipFormatter(col),
    colId: col.dataIndex,
    width: col.width,
    cellClass: cellClass[col.xtype] || 'cell-wrap',
    cellRendererFramework: cellRendererFrameworks[col.xtype],
    valueGetter: valueGetters(col.xtype),
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
    filter: suppressFilterSort || suppressFilterFrameworks(col.filter, col) ? false : 'gridFilter',
    filterParams: FilterConfig(col),
    sortable: col.sortable || false,
    options: col.filter && col.filter.options ? filterOptions(col.filter) : null,
    lockPinned: true,
    sort: defaultSort(type, index, col.dataIndex),
    mapping: col.filter && col.filter.mapping ? col.filter.mapping : null,
    headerTooltip: col.header,
    cellClassRules
  }))

export const transformFilter = filterData => {
  return flatten(Object.values(filterData))
}

export const loadFilter = filterArray => {
  return groupBy(filterArray, 'field')
}

export const getTargetId = dataIndex => {
  return dataIndex.match(/publishing_status[.|-](\d+)/)[1]
}

export const transformSort = sortData => {
  const { oldSortCol, dir, newSortCol } = sortData
  let params
  if (newSortCol === 'id') {
    params = { colId: 'id', sort: dir === 'DESC' ? 'asc' : 'desc' }
  } else if (dir === 'DESC' && oldSortCol === newSortCol) {
    params = { colId: 'id', sort: 'desc' }
  } else {
    params = { colId: newSortCol, sort: oldSortCol === newSortCol ? 'desc' : 'asc' }
  }
  return [params]
}
