export function daysInMonth(month, year) {
  switch (month) {
    case 1:
      return (year % 4 === 0 && year % 100) || year % 400 === 0 ? 29 : 28
    case 8:
    case 3:
    case 5:
    case 10:
      return 30
    default:
      return 31
  }
}

export function isValidDate(date) {
  const cleanDate = String(date).match(/^(\d{4})-(\d{2})-(\d{2})/)
  if (cleanDate) {
    let [, year, month, day] = cleanDate
    return validateDate(year, month, day)
  }
  return false
}

export function isValidEditDate(date) {
  const cleanDate = String(date).match(/^(\d{2})-(\d{2})-(\d{4})/)
  if (cleanDate) {
    let [, month, day, year] = cleanDate
    return validateDate(year, month, day)
  }
  return false
}

export function validateDate(year, month, day) {
  month = parseInt(month, 10) - 1
  return month >= 0 && month < 12 && day > 0 && day <= daysInMonth(month, year)
}
