import axios from 'axios'
import { request } from '@/common/async.js'

/**
 * @description fetch a list of media projects
 * @returns {Promise} axios request promise
 */
export function getAdminMediaProjects() {
  return axios.get('/admin/media-projects')
}

/**
 * @description create a new media project
 * @returns {Promise} axios request promise
 */
export function createProject({ name, owner, template, reference, listScope, description, type = 'project' }) {
  const form = new FormData()
  form.append('name', name)
  form.append('institution_id', owner)
  if (template !== -1) {
    form.append('template_id', template)
  }
  if (reference) {
    form.append('reference_id', reference)
  }

  if (type === 'list') {
    form.append('type_id', 100)
    form.append('listScope', listScope)
  } else if (type === 'container') {
    form.append('type_id', 100)
    form.append('list_type', 110)
    form.append('listScope', 0)
  } else {
    form.append('description', description)
    form.append('supports_media_objects', true)
    form.append('type_id', 200)
  }

  return request({ url: '/admin/projects', method: 'POST', data: form })
}

/**
 * @description edit a new media project
 * @returns {Promise} axios request promise
 */
export function updateProject({ id, name, owner, supports_media_objects, description, type = 'project' }) {
  const form = new FormData()
  form.append('id', id)
  form.append('name', name)
  form.append('institution_id', owner)
  form.append('source', 'ssimata')

  if (type === 'project') {
    form.append('description', description)
    form.append('supports_media_objects', supports_media_objects)
  }

  return request({ url: `/admin/projects/${id}`, method: 'PUT', data: form })
}

/**
 * @description delete a media project
 * @returns {Promise} axios request promise
 */
export function deleteProject(projectId) {
  return request({ url: `/admin/projects/${projectId}`, method: 'DELETE' })
}

/**
 * @description fetch summary of the project
 * @param {number} projectId id of the project
 * @returns {Promise} axios request promise
 */
export function getProjectSummary(projectId) {
  return axios.get(`/admin/projects/${projectId}/summary`)
}
