import('./initComponents')

import Vue from 'vue'
import App from './App'
import { createRouter } from './router'
import store from './store'
import 'svgxuse'
import { capitalize, decode } from './common/filters'
import bootstrapIncludes from './common/bootstrapInclude'
import ForumIcon from '@/components/common/ForumIcon'
import EventBus from '@/components/common/EventBus'
import { i18n } from '@/common/i18n'
import * as Sentry from '@sentry/browser'
import { setupAxios, setupDistributedTracing } from '@/common/setupAxios'
import GlobalEvents from 'vue-global-events'
import VueAnnouncer from 'vue-announcer'
import VueI18n from 'vue-i18n'
import VueGtm from 'vue-gtm'
import { ObserveVisibility } from 'vue-observe-visibility'
import { createProvider } from './vue-apollo'
import VueCookies from 'vue-cookies'
import VueSanitize from 'vue-sanitize'

Vue.directive('observe-visibility', ObserveVisibility)

const router = createRouter()
Vue.use(VueI18n)

// Plugin to strip all HTML from a given string
Vue.use(VueSanitize, { allowedTags: [], allowedAttributes: {} })

const isDev = window.location.host === 'localhost'
const isProd = ['forum.jstor.org', 'new.forum.jstor.org', 'catalog.sharedshelf.artstor.org'].includes(
  window.location.host
)

Vue.use(VueAnnouncer, {}, router)
Vue.use(VueCookies)
Vue.config.productionTip = false

// global filters
Vue.filter('capitalize', capitalize)
Vue.filter('decode', decode)

// register bootstrap components
bootstrapIncludes(Vue)
Vue.component('ForumIcon', ForumIcon)

// EventBus moved to global scope
Vue.component('EventBus', EventBus)

Vue.component('GlobalEvents', GlobalEvents)
if (!isDev) {
  const EVENTS_TO_IGNORE = ['Failed to fetch']
  // Sentry.io
  Sentry.init({
    dsn: 'https://6a24630c695046da8d766e4052bf04f1@sentry.io/1248434',
    environment: isProd ? 'production' : 'test',
    release: process.env.APP_VERSION,
    beforeSend(event, hint) {
      if (hint.originalException && EVENTS_TO_IGNORE.includes(hint.originalException.message)) {
        return null
      }

      return event
    }
  })
}

setupDistributedTracing('forum-ui')

/* eslint-disable no-new */
const vm = new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App),
  apolloProvider: createProvider()
})

setupAxios(vm)

const analyticsId = isProd ? 'G-RR7L9VB122' : 'G-Z6JHKNBZ7N'
const tagManagerId = isProd ? 'GTM-P7HKHPT' : 'GTM-5VVP84C'
Vue.use(VueGtm, {
  id: tagManagerId,
  enabled: true,
  vueRouter: router
})

window.dataLayer = window.dataLayer || []
function gtag() {
  window.dataLayer.push(arguments)
}
gtag('js', new Date())
gtag('config', analyticsId)

window.addEventListener(
  'dragover',
  function(e) {
    e = e || event
    e.preventDefault()
  },
  false
)

window.addEventListener(
  'drop',
  function(e) {
    e = e || event
    e.preventDefault()
  },
  false
)
