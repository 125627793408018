<template>
  <div class="user-permissions">
    <div v-if="!isList" class="page-header">
      <div class="nav__label">
        <h1 ref="list-name" tabindex="0">
          <span class="controlled-list-name">{{ selectedProjectName }}</span>
        </h1>
      </div>
      <forum-ui-pharos-button
        variant="primary"
        data-automation-component="add-permission"
        data-point="add-permission"
        @click="handleAddPermission()"
        @keydown.tab.exact="!hasRowsSelected ? enterGrid($event, gridOptions, 1) : {}"
        >{{ $t('PROJECT_USERS_TAB.PANEL_ADD_BUTTON') }}</forum-ui-pharos-button
      >
    </div>
    <b-card :header="tableHeader" bg-variant="light" class="permissions-card" no-body>
      <event-bus @footer-enter-grid="enterGrid($event, gridOptions, 1)" />
      <b-card-header>
        <div class="header-text">{{ $t('LIST_TAB.LIST_USERS') }}</div>
      </b-card-header>
      <b-card-body :class="{ 'grid-wrapper': true, 'error-msg-showing': errorMessage.length }">
        <error-message :message="errorMessage" />
        <div class="action-bar">
          <forum-ui-pharos-button
            v-if="isList"
            class="panel-btn"
            variant="primary"
            data-automation-component="add-permission"
            data-point="add-permission"
            @click="handleAddPermission()"
            @keydown.tab.exact="!hasRowsSelected ? enterGrid($event, gridOptions, 1) : {}"
            >{{ $t('PROJECT_USERS_TAB.PANEL_ADD_BUTTON') }}</forum-ui-pharos-button
          >
          <forum-ui-pharos-button
            v-if="isArtstorAdmin"
            :disabled="!hasRowsSelected"
            :title="editBtnTitle"
            :class="['panel-btn', { disabled: numberRowsSelected > 1 }]"
            variant="secondary"
            data-automation-component="edit-permission"
            data-point="edit-permission"
            @click="handleEditPermission()"
            >{{ $t('PROJECT_USERS_TAB.PANEL_EDIT_BUTTON') }}</forum-ui-pharos-button
          >
          <forum-ui-pharos-button
            :disabled="!hasRowsSelected"
            variant="secondary"
            class="panel-btn"
            icon-left="delete"
            a11y-label="delete"
            data-automation-component="delete-permission"
            data-point="delete-permission"
            @click="showDeleteUserPermissions = true"
            @keydown.tab.exact="enterGrid($event, gridOptions, 1)"
            >{{ $t('PROJECT_USERS_TAB.PANEL_DELETE_BUTTON') }}</forum-ui-pharos-button
          >
        </div>
        <ag-grid-vue
          ref="permissions-grid"
          :grid-options="gridOptions"
          :row-data="rowData"
          :column-defs="columnDefs"
          class="ag-theme-ss grid"
          data-automation-component="permissions-grid"
          data-point="permissions-grid"
        />
      </b-card-body>
      <loading-indicator v-if="isLoading" />

      <!-- Delete User Permissions -->
      <delete-user-permissions
        v-if="showDeleteUserPermissions"
        :permission-ids="selectedRowIds"
        :project-name="selectedProjectName"
        :user-names="userNames"
        :namespace="namespace"
        @close="showDeleteUserPermissions = false"
        @delete-finished="loadPermissions"
      />
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { AgGridVue } from 'ag-grid-vue'
import ErrorMessage from '@/components/common/ErrorMessage'
import LoadingIndicator from '@/components/common/LoadingIndicator'
import DeleteUserPermissions from './DeleteUserPermissions'
import tc from '@/common/tc'
import enterGrid from '@/common/enterGrid.js'

export default {
  name: 'UserPermissions',
  components: {
    AgGridVue,
    LoadingIndicator,
    ErrorMessage,
    DeleteUserPermissions
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    permissionId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      showDeleteUserPermissions: false,
      isLoading: false,
      errorMessage: '',
      hasRowsSelected: false,
      selectedRowIds: [],
      userNames: [],
      gridOptions: {
        headerHeight: 41,
        rowHeight: 40,
        suppressContextMenu: true,
        suppressPropertyNamesCheck: true,
        suppressScrollOnNewData: true,
        rowSelection: 'multiple',
        rowDeselection: true,
        defaultColDef: {
          resizable: true,
          sortable: true
        },
        onGridReady: params => {
          params.api.sizeColumnsToFit()
        },
        onRowDoubleClicked: () => {
          this.handleEditPermission()
        },
        onSelectionChanged: params => {
          this.selectedRowIds = params.api.getSelectedRows().map(elem => elem.profile_id)
          this.userNames = params.api.getSelectedRows().map(elem => elem.name)
          this.hasRowsSelected = !!this.selectedRowIds.length
        },
        getRowNodeId: params => {
          return params.id
        },
        tabToNextCell: () => {
          this.gridOptions.api.clearFocusedCell()
        }
      },
      roles: [
        { label: 'Admin', value: 100 },
        { label: 'Cataloger', value: 200 }
      ],
      sharedColumns: [
        {
          headerName: '',
          headerCheckboxSelection: true,
          checkboxSelection: true,
          cellClass: 'ag-cell-select',
          maxWidth: 30
        },
        {
          headerName: 'Name',
          field: 'name',
          sort: 'asc'
        },
        {
          headerName: 'Email Address',
          field: 'email'
        },
        {
          headerName: 'Role',
          field: 'role_id',
          cellRenderer: param => {
            return this.roles.find(role => role.value === param.data.role_id).label
          }
        },
        {
          headerName: 'Base Filter',
          field: 'filter'
        },
        {
          headerName: 'Edit Filter',
          field: 'edit_filter_name'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      projectPermissions: state => state.projects.permissions,
      listPermissions: state => state.lists.permissions
    }),
    ...mapGetters('lists', ['listScope']),
    ...mapGetters('user', ['isArtstorAdmin']),
    ...mapGetters('lists', { getListName: 'projectName' }),
    ...mapGetters('projectSettings', { getProjectName: 'projectName' }),
    selectedProjectName() {
      return this.namespace === 'projects'
        ? this.getProjectName(this.selectedProjectId)
        : this.getListName(this.selectedProjectId)
    },
    selectedProjectId() {
      return Number(this.$route.params.id)
    },
    selectedPermission() {
      if (this.numberRowsSelected > 1) {
        return
      }
      return this.rowData.find(permission => permission.profile_id === this.selectedRowIds[0])
    },
    isList() {
      return this.$route.name.includes('lists')
    },
    columnDefs() {
      let columns = [...this.sharedColumns]
      if (!this.isList) {
        columns.splice(5, 1)
        columns.splice(
          4,
          0,
          {
            headerName: 'Cataloging Forms',
            field: 'forms'
          },
          {
            headerName: 'Publishing Targets',
            field: 'publishing_targets'
          }
        )
      } else if (this.isList && !this.isArtstorAdmin) {
        // remove role, edit filter, base filter columns
        columns.splice(3, 3)
      }
      return columns
    },
    rowData() {
      return this.isList ? this.listPermissions : this.projectPermissions
    },
    namespace() {
      return this.isList ? 'lists' : 'projects'
    },
    isCommunity() {
      return this.listScope(this.selectedProjectId) === 1
    },
    hasAccess() {
      const isCommunityList = this.isList && this.isCommunity
      return isCommunityList ? this.isArtstorAdmin : true
    },
    numberRowsSelected() {
      return this.selectedRowIds.length
    },
    editBtnTitle() {
      return this.numberRowsSelected > 1
        ? this.$t('PROJECT_USERS_TAB.ONE_PERMISSION_AT_A_TIME')
        : this.$t('PROJECT_USERS_TAB.PANEL_EDIT_BUTTON')
    },
    tableHeader() {
      return this.isList ? this.$t('LIST_TAB.LIST_USERS') : this.$t('PROJECT_TAB.PROJECT_USERS_TAB')
    }
  },
  mounted() {
    if (this.permissionId) {
      this.scrollToPermission(this.permissionId)
      return
    }
    if (this.refresh) {
      this.loadPermissions()
    }
  },
  methods: {
    ...mapActions('projects', ['fetchPermissions']),
    ...mapActions('lists', ['fetchPermissions']),
    enterGrid,
    async loadPermissions() {
      this.isLoading = true
      await tc(
        async () => {
          await this.$store.dispatch(`${this.namespace}/fetchPermissions`, {
            projectId: this.selectedProjectId
          })
          this.selectedRowIds = []
          this.userNames = []
          this.hasRowsSelected = false
        },
        errorMsg => {
          this.errorMessage = errorMsg
        }
      )
      this.isLoading = false
    },
    fitColumns() {
      this.gridOptions.api.sizeColumnsToFit()
    },
    handleAddPermission() {
      this.$router
        .push({
          name: this.isList ? 'add-list-permission' : 'add-project-permission'
        })
        .catch(routeError => {
          if (routeError.name !== 'NavigationDuplicated') {
            console.error(routeError)
          }
        })
    },
    handleEditPermission() {
      if (this.numberRowsSelected !== 1) {
        return
      }
      if (this.hasAccess) {
        this.$router
          .push({
            name: this.isList ? 'edit-list-permission' : 'edit-project-permission',
            params: {
              permissionId: this.selectedPermission.id
            }
          })
          .catch(routeError => {
            if (routeError.name !== 'NavigationDuplicated') {
              console.error(routeError)
            }
          })
      }
    },
    async scrollToPermission(permissionId) {
      await this.loadPermissions()
      const api = this.$refs['permissions-grid'].gridOptions.api
      const row = api.getRowNode(permissionId)
      const index = row && row.rowIndex
      api.ensureIndexVisible(index, 'top')
      api.setFocusedCell(index, 'name', null)
    }
  }
}
</script>

<style scoped lang="scss">
.btn-link {
  text-decoration: underline;
  &:hover {
    text-decoration: none !important;
  }
}
.user-permissions {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.permissions-card {
  display: flex;
  flex: 1;
  border: none !important;
  ::v-deep .card-header {
    padding: 0.5rem 0.75rem;
    align-items: center;
  }
  .header-text {
    font-size: 0.75rem;
    margin-top: 0.4rem;
  }
}
.grid-wrapper {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  &.error-msg-showing {
    display: grid;
    grid-template-rows: auto auto 1fr;
  }
  .alert {
    margin: 0.5rem;
  }
}

.grid {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: auto;
  border-top: none;

  ::v-deep .ag-root-wrapper {
    flex: 1;
    height: unset;
  }
}

.action-bar {
  padding: 0.25rem 0;
  display: flex;
  justify-content: flex-start;
}

.panel-btn {
  margin-top: var(--pharos-spacing-one-half-x);
  margin-left: var(--pharos-spacing-one-half-x);
  margin-bottom: var(--pharos-spacing-one-half-x);
}

.btn-link:disabled,
.btn-link.disabled {
  pointer-events: inherit;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  padding: 20px;
}

.nav__label {
  @include truncate();
  h1 {
    color: $bodyFontColor;
    padding: 0 10px 0 0;
    font-size: 1rem;
    display: inline;
    vertical-align: middle;

    .controlled-list-name {
      color: $pharos-alert-color-text-base;
      font-size: 1.5rem;
    }

    &:focus {
      outline: none;
      border-bottom: 1px dotted $primary;
    }
  }
}

.actions-wrapper {
  display: flex;
  font-size: var(--pharos-spacing-1-x);
  justify-content: flex-end;
  padding-right: var(--pharos-spacing-3-x);

  > div,
  button {
    margin-left: var(--pharos-spacing-1-x);
  }
}

.extended-button {
  justify-content: space-between;
}

.delete-icon {
  margin-right: 0.25rem;
  margin-top: -0.125rem;
}
</style>
