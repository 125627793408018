import { transformColumns } from '@/components/grid/asset-grid-utils'
import { deleteControlledListTerm, getControlledLists, queryPropagationDetails } from '@/services/controlledLists'
import { queryListTerms } from '@/services/projects'
import cloneDeep from 'lodash/cloneDeep'
import concat from 'lodash/concat'
import { downloadFile } from '../common/downloadFile'

let fetchProjectSource = {}
let fetchListSource = {}

const defaultAssetParams = () => ({
  with_meta: false,
  start: 0,
  limit: 100,
  sort: '_term_field',
  dir: 'ASC',
  filter: [],
  query: ''
})

const defaultControlledLists = () => ({
  loading: false,
  items: [],
  details: {
    assets: [],
    metaData: {
      columns: []
    },
    total: 0,
    query: {},
    projectId: 0
  },
  selected: null,
  users: []
})

const controlledLists = {
  namespaced: true,
  state: defaultControlledLists(),
  mutations: {
    SET_LIST(state, lists) {
      state.items = lists
    },
    SET_SELECTED(state, projectId) {
      state.selected = projectId
    },
    SET_DETAILS(state, project) {
      state.details.assets = project.assets
      state.details.assets.forEach(asset => {
        asset.details = {}
      })

      if (project.metaData) {
        // sometimes with_meta=false is set, in which case we done overwrite the old metaData
        state.details.metaData = project.metaData
      }
      state.details.total = project.total
      state.details.query = project.query
      state.details.projectId = project.projectId
    },
    CLEAR_DETAILS(state) {
      state.details.assets.length = 0
      state.details.metaData = { columns: [] }
      state.details.total = 0
      state.details.projects = []
      state.details.query = {}
    },
    LOADING_TRUE(state) {
      state.loading = true
    },
    LOADING_FALSE(state) {
      state.loading = false
    },
    SET_USERS(state, payload) {
      state.users = payload.users
    },
    CLEAR_PROJECT_ID(state) {
      state.details.projectId = 0
    },
    RESET_STATE(state) {
      Object.assign(state, defaultControlledLists())
    }
  },
  actions: {
    async fetchLists(context) {
      const res = await getControlledLists(fetchListSource)
      context.commit('SET_LIST', res.data.items)
      return res.data
    },
    async fetchControlledListTerms(context, options) {
      context.commit('SET_SELECTED', options.projectId)
      let params = Object.assign(defaultAssetParams(), cloneDeep(options.params))

      if (params.query.trim() === '') {
        delete params.query
      }

      if (typeof params.filter !== 'string' && !(params.filter instanceof String)) {
        params.filter = JSON.stringify(params.filter)
      }

      if (context.state.details.projectId !== options.projectId) {
        params.with_meta = true
      }

      context.commit('LOADING_TRUE')

      try {
        let res = await queryListTerms(options.projectId, { params }, fetchProjectSource)

        if (res.data) {
          if (params.with_meta) {
            const columns = res.data.metaData.columns

            const fieldColumns = columns.filter(column => column.dataIndex.startsWith('fd_'))
            const otherColumns = columns.filter(column => !column.dataIndex.startsWith('fd_'))

            res.data.metaData.columns = [...fieldColumns, ...otherColumns]
            context.commit('SET_USERS', { users: res.data.metaData.profiles })
          }

          context.commit('SET_DETAILS', Object.assign(res.data, { query: params }, { projectId: options.projectId }))
          context.commit('LOADING_FALSE')

          return true
        }
      } catch (err) {
        const canceledReq = !err.message
        if (!canceledReq) {
          context.commit('LOADING_FALSE')
          throw new Error(err.message)
        }
      }
    },
    async fetchEditTerm(context, options) {
      let params = Object.assign(defaultAssetParams(), options.params)
      const tab = context.rootState.tabs.list[options.tabIndex]
      const res = await queryListTerms(options.projectId, { params }, tab.fetchEditTermSource)
      return res.data
    },
    async deleteTerm(_context, options) {
      const { listId, assetId } = options
      try {
        await deleteControlledListTerm(listId, assetId)
        return 'Term deleted successfully'
      } catch (err) {
        if (err.status === 500) {
          const res = await queryPropagationDetails(assetId)
          throw new Error(
            `This term cannot be deleted because it is being used for the following number of links: ${res.data.dr_count} Items, ${res.data.work_count} Works, ${res.data.name_count} Names, ${res.data.term_count} Terms`
          )
        }
        throw new Error(err.message)
      }
    },
    async fetchPropagationDetails(_context, assetId) {
      const res = await queryPropagationDetails(assetId)
      return res.data
    },
    async downloadProject(_context, options) {
      let params = Object.assign(defaultAssetParams(), options.params)
      params.filter = JSON.stringify(params.filter)
      return downloadFile(`/mfcl/${options.id}/terms.xls`, {
        params
      })
    }
  },
  getters: {
    sortedLists: state => [...state.items].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
    getColumns: state => () => {
      const columns = []

      return concat(
        columns,
        state.details.metaData.columns.slice(columns.length).map((col, index) => ({
          ...col,
          id: col.id || index + columns.length + 1
        }))
      )
    },
    getColumnDefinitions: (_state, getters) => gridState => {
      const columns = getters.getColumns(gridState)

      return transformColumns(
        [
          {
            id: 'select',
            dataIndex: 'select',
            header: '',
            xtype: 'selectcolumn',
            width: 28
          },
          ...columns
        ],
        'controlledLists',
        false,
        _state.details.metaData.institutions
      )
    },
    foundList: state => listId => {
      return state.items.find(item => item.id === listId) || {}
    },
    listName: (_state, getters) => listId => {
      return getters.foundList(listId).name
    },
    getTermField: (_state, getters) => listId => {
      return getters.foundList(listId).term_field
    }
  }
}

export default controlledLists
