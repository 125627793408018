<template>
  <div :key="`img-${retryCount}`" v-lazyload class="img-container">
    <content-loader
      v-if="loading"
      :width="100"
      :height="100"
      :style="{ 'max-height': `${size}px`, 'max-width': `${size}px` }"
      class="content-loader"
    />
    <transition name="fade">
      <!-- eslint-disable-next-line vue-a11y/alt-text -->
      <img
        v-show="!loading"
        ref="img"
        :data-url="sourceWithBust"
        :alt="alt"
        :style="{ 'max-height': `${size}px`, 'max-width': `${size}px` }"
        @load="loading = false"
        @error="onImageError"
      />
    </transition>
  </div>
</template>

<script>
import LoadingIndicator from '@/components/common/LoadingIndicator'
import { ContentLoader } from 'vue-content-loader'
import LazyLoad from '@/directives/LazyLoad.js'

const RETRY_INTERVALS = [5, 15, 30]

export default {
  name: 'ImageWithLoader',
  components: {
    LoadingIndicator,
    ContentLoader
  },
  directives: {
    lazyload: LazyLoad
  },
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 64
    }
  },
  data() {
    return {
      retryCount: 0,
      loading: true
    }
  },
  computed: {
    sourceWithBust() {
      if (!this.src || this.retryCount === 0) {
        return this.src
      }

      const retryFlag = `retry=${this.retryCount}`
      if (this.src.includes('&')) {
        return `${this.src}&${retryFlag}`
      } else {
        return `${this.src}?${retryFlag}`
      }
    }
  },
  watch: {
    src() {
      this.loading = true
    }
  },
  methods: {
    onImageError() {
      if (this.retryCount < RETRY_INTERVALS.length) {
        setTimeout(() => {
          this.retryCount += 1
        }, RETRY_INTERVALS[this.retryCount] * 1000)
      } else {
        this.loading = false
        this.$refs.img.src = '/static/brokenlink_64.png'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.img-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.content-loader {
  pointer-events: none;
}
</style>
