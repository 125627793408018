<template>
  <span :class="{ 'loader-without-overlay': !showOverlay, 'loader-overlay': showOverlay }">
    <div :class="{ wrapper: true, 'with-text': text.length }">
      <forum-ui-pharos-loading-spinner a11y-label="spinner" data-automation-component="loading" />
      <span v-if="text.length" class="optional-text" role="status">{{ text }}</span>
    </div>
  </span>
</template>

<script>
export default {
  name: 'LoadingIndicator',
  props: {
    showOverlay: {
      required: false,
      default: true,
      type: Boolean
    },
    text: {
      required: false,
      default: '',
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
.loader-overlay {
  overflow: hidden;
  display: block;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;

  .icon {
    display: block;
    width: 3em;
    max-height: inherit;
    margin: 0 auto;
    animation: rotation 1s infinite linear;
  }
}

.wrapper {
  position: relative;
  display: block;
  width: 100%;
  &.with-text {
    margin-top: -2rem;
  }
}

.optional-text {
  width: 100%;
  position: absolute;
  bottom: 1.5rem;
  text-align: center;
  font-size: 0.9rem;
  animation: showHidden 0s 1s forwards;
  visibility: hidden;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes showHidden {
  to {
    visibility: visible;
  }
}
</style>
