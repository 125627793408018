export default function enterGrid(event, gridOptions, cellIndex) {
  if (gridOptions.rowData.length === 0) {
    return
  }
  event.preventDefault()

  gridOptions.api.ensureIndexVisible(0)

  const firstCol = gridOptions.columnApi.getAllDisplayedColumns()[cellIndex]
  gridOptions.api.ensureColumnVisible(firstCol)

  gridOptions.api.setFocusedCell(0, firstCol)
}
