import axios from 'axios'

export function authenticate({ userId, institutionId }) {
  return axios.get(`/service/api/user/json/authenticate?adlUserId=${userId}&instituteId=${institutionId}&expiry=24`)
}

export function searchNames({ authToken, term, sort, pageNumber, pageSize = 25 }) {
  const form = new URLSearchParams()
  form.append('refined', true)
  form.append('authToken', authToken)
  if (typeof term !== 'undefined') {
    form.append('term', term)
  }
  form.append('sort', sort)
  form.append('pageNumber', pageNumber)
  form.append('pageSize', pageSize)
  return axios.post('/names/name/json/searchConcept', form)
}

export function searchFields({ authToken, instituteId, lookupType, term, pageSize = 500 }) {
  let form = {
    authToken: authToken,
    instituteId: instituteId,
    lookupType: lookupType,
    pageSize: pageSize,
    term: term
  }
  const queryParams = new URLSearchParams(form).toString()

  return axios.get(`/service/api/work/json/getLookupByTypeAlpha?${queryParams}`)
}

export function advancedSearchNames(obj) {
  const form = new FormData()
  Object.entries(obj).forEach(([key, value]) => {
    form.append(key, value)
  })
  return axios.post(`/names/name/json/advanceSearch?authToken=${encodeURIComponent(obj.authToken)}`, form)
}
