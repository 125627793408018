import { getPublishingTargetMappings } from '@/services/admin/publishingTargets'

const defaultPublishingTargets = () => ({
  mappings: []
})

const publishingTargets = {
  namespaced: true,
  state: defaultPublishingTargets(),
  mutations: {
    SET_MAPPINGS(state, mappings) {
      state.mappings = mappings
    },
    RESET_STATE(state) {
      Object.assign(state, defaultPublishingTargets())
    }
  },
  actions: {
    async fetchPublishingTargetMappings(context, options) {
      const { targetId } = options
      const res = await getPublishingTargetMappings(targetId)
      context.commit('SET_MAPPINGS', res.data)
      return res
    }
  }
}

export default publishingTargets
