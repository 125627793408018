import Vue from 'vue'

var ethnioWritten = false

function writeEthnioScript(el, scriptID) {
  if (!ethnioWritten && scriptID) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.language = 'javascript'
    script.src = `//ethn.io/${scriptID}.js`
    script.async = 'true'
    script.charset = 'utf-8'

    el.appendChild(script)
    ethnioWritten = true
  }
}

export default Vue.directive('ethnio', {
  bind: function(el, binding) {
    writeEthnioScript(el, binding.value)
  },
  update: function(el, binding) {
    writeEthnioScript(el, binding.value)
  }
})
