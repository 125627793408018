import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { insertInvalidDataToAsset } from '@/common/asset'

const defaultTabs = () => ({
  list: []
})

const tabs = {
  namespaced: true,
  state: defaultTabs(),
  mutations: {
    ADD_EDIT_TAB(state, options) {
      const { asset, details, ...params } = options
      const index = details.assets.findIndex(a => a.id === asset.id)
      const assetInGrid = index !== -1
      // if asset is not showing in grid due to filter/search applied, clone asset itself
      let current = assetInGrid ? cloneDeep(details.assets[index]) : cloneDeep(asset)
      current = insertInvalidDataToAsset(current)

      state.list = [
        {
          current,
          query: { ...details.query },
          total: details.total,
          metaData: { ...details.metaData },
          compoundObject: [],
          coIndex: 0,
          batch: false,
          fetchEditAssetSource: {},
          type: 'asset',
          assetInGrid,
          ...params
        }
      ]
    },
    ADD_BATCH_EDIT_TAB(state, options) {
      const { projectId, query, details, ...params } = options
      state.list = [
        {
          current: {
            project_id: projectId
          },
          assets: [],
          query: Object.assign(query || details.query, { limit: 100 }),
          total: details.total,
          metaData: { ...details.metaData },
          compoundObject: [],
          coIndex: 0,
          batch: true,
          projectId,
          batchLoading: true,
          batchItems: [],
          batchTotal: 0,
          batchSelected: {
            type: 'all',
            params: []
          },
          fetchBatchItemsSource: {},
          type: 'asset',
          ...params
        }
      ]
    },
    ADD_TERM_CREATE_TAB(state, options) {
      const { listId, institutionId, ...params } = options

      state.list = [
        {
          current: {
            project_id: listId,
            institute_admin: institutionId
          },
          query: {},
          total: 0,
          metaData: {},
          type: 'term',
          fetchEditTermSource: {},
          ...params
        }
      ]
    },
    ADD_TERM_EDIT_TAB(state, options) {
      const { asset, details, ...params } = options
      const index = details.assets.findIndex(a => a.id === asset.id)
      const assetInGrid = index !== -1
      // if asset is not showing in grid due to filter/search applied, clone asset itself
      let current = assetInGrid ? cloneDeep(details.assets[index]) : cloneDeep(asset)
      current = insertInvalidDataToAsset(current)

      state.list = [
        {
          current,
          query: { ...details.query },
          total: details.total,
          metaData: { ...details.metaData },
          type: 'term',
          fetchEditTermSource: {},
          ...params
        }
      ]
    },
    UPDATE_TAB_STATE(state, { data }) {
      Object.keys(data).forEach(key => {
        Vue.set(state.list[0], key, data[key])
      })
    },
    ADD_TAB_STATE_DETAILS(state, { data }) {
      const { path, value } = data

      const key = path[path.length - 1]
      let itemToUpdate = state.list[0]
      path.forEach((p, index) => {
        if (index === path.length - 1) {
          return
        }

        itemToUpdate = itemToUpdate[p]
      })

      Vue.set(itemToUpdate, key, value)
    },
    REMOVE_BATCH_SELECTED_ASSETS(state, options) {
      const { tabIndex, assetIds } = options
      assetIds.forEach(assetId => {
        const index = state.list[tabIndex].batchSelected.params.findIndex(id => id === assetId)
        if (index !== -1) {
          state.list[tabIndex].batchSelected.params.splice(index, 1)
        }
      })
    },
    RESET_STATE(state) {
      Object.assign(state, defaultTabs())
    }
  },
  actions: {
    addEditTab(context, options) {
      context.commit('ADD_EDIT_TAB', {
        ...options,
        details: context.rootState.projects.details,
        assetType: context.rootState.projects.assetType
      })
    },
    addBatchEditTab(context, options) {
      context.commit('ADD_BATCH_EDIT_TAB', {
        ...options,
        details: context.rootState.projects.details,
        assetType: context.rootState.projects.assetType
      })
    },
    addTermCreateTab(context, options) {
      context.commit('ADD_TERM_CREATE_TAB', options)
    },
    addTermEditTab(context, options) {
      context.commit('ADD_TERM_EDIT_TAB', { ...options, details: context.rootState.controlledLists.details })
    },
    updateTabState(context, options) {
      context.commit('UPDATE_TAB_STATE', options)
    },
    addTabStateDetailToPath(context, options) {
      context.commit('ADD_TAB_STATE_DETAILS', options)
    },
    removeBatchSelectedAssets(context, options) {
      context.commit('REMOVE_BATCH_SELECTED_ASSETS', options)
    },
    closeTab(context) {
      context.commit('RESET_STATE')
    }
  },
  getters: {}
}

export default tabs
