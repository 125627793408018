import { supportedBrowsers, technicalSupportLink } from '../utils/supportedBrowsers'
import platform from 'platform'
import StatusPage from 'statuspage-client'

const defaultBanner = () => ({
  showBanner: false,
  adminEthnioSurveyID: '',
  catalogerEthnioSurveyID: '',
  statusActive: false,
  statusText: '',
  statusPageClient: undefined,
  statusComponentId: '',
  disableLogin: false,
  bannerLoaded: false,
  termsVersion: '2019-06-10',
  artstorTermsVersion: '2020-01-30',
  jstorTermsVersion: '2020-01-30',
  browser: {
    unsupported: false,
    outdated: false,
    name: '',
    version: 0,
    link: '#'
  }
})

const banner = {
  state: defaultBanner(),
  mutations: {
    FETCHED_BANNER(state, options) {
      Object.assign(state, {
        ...options
      })
    },
    INIT_STATUS_CLIENT(state, statusPageClient) {
      Object.assign(state, { statusPageClient })
    },
    UPDATE_STATUS(state, options) {
      const { status, body: statusText } = options
      Object.assign(state, { statusActive: status !== 'resolved', statusText })
    },
    SHOW_BANNER(state, show) {
      state.showBanner = show
    },
    SET_BROWSER(state, browserInfo) {
      Object.assign(state.browser, browserInfo)
    },
    SET_STATUS_COMPONENT_ID(state, statusComponentId) {
      Object.assign(state, { statusComponentId })
    },
    SET_BANNER_LOADED(state) {
      state.bannerLoaded = true
    },
    RESET_STATE(state) {
      Object.assign(state, defaultBanner())
    }
  },
  actions: {
    async fetchBanner(context) {
      try {
        // LOCA-2684: Removed legacy Artstor-flags call, keeping everything else to reduce scope
        context.commit('SET_BANNER_LOADED')
        context.dispatch('renderBanner')
        context.dispatch('readBrowserData', window.navigator.userAgent)
      } catch (err) {
        console.log(err)
      }
    },
    renderBanner(context) {
      const savedBannerText = sessionStorage.getItem('bannerText')

      const { statusActive, statusText } = context.state
      if (statusActive === false) {
        // reset the banner state if the banner gets turned off
        sessionStorage.removeItem('userClosedBanner')
        sessionStorage.removeItem('bannerText')
        return context.commit('SHOW_BANNER', false)
      }

      let bannerText = ''
      if (statusActive === true) {
        bannerText = statusText
      }
      const textHasChanged = bannerText.length > 0 && bannerText === savedBannerText

      const hasUserClosedBanner = sessionStorage.getItem('userClosedBanner') === 'true'
      let showBanner = true
      if (textHasChanged) {
        sessionStorage.setItem('bannerText', bannerText)
        sessionStorage.setItem('userClosedBanner', 'false')
      } else if (hasUserClosedBanner || bannerText.length === 0) {
        showBanner = false
      }

      context.commit('SHOW_BANNER', showBanner)
    },
    initStatusClient(context, options) {
      const { componentId } = options
      const client = StatusPage(componentId, options)
      context.commit('INIT_STATUS_CLIENT', client)
      client.subscribe((err, data) => {
        if (err) {
          return console.error(err)
        }
        context.commit('UPDATE_STATUS', data)
        context.dispatch('renderBanner')
      })
    },
    setStatusComponentId(context, componentId) {
      context.commit('SET_STATUS_COMPONENT_ID', componentId)
    },
    closeBanner(context) {
      try {
        sessionStorage.setItem('userClosedBanner', 'true')
      } catch (e) {
        console.log('Could not set sessionStorage, will not remember banner state.')
      }
      context.commit('SHOW_BANNER', false)
    },
    readBrowserData(context, userAgent) {
      const browser = platform.parse(userAgent)
      const { name, version } = browser

      const browserInfo = {
        name,
        version
      }

      if (supportedBrowsers[name.toLowerCase()]) {
        const { minVersion, link } = supportedBrowsers[name.toLowerCase()]
        if (parseFloat(version) < minVersion) {
          Object.assign(browserInfo, {
            outdated: true,
            link
          })
        }
      } else {
        Object.assign(browserInfo, {
          unsupported: true,
          link: technicalSupportLink
        })
      }

      context.commit('SET_BROWSER', browserInfo)
    }
  },
  getters: {
    hasBanner: state => state.showBanner || state.browser.outdated || state.browser.unsupported
  },
  namespaced: true
}

export default banner
