import axios from 'axios'

/**
 * @description fetch templates
 * @returns {Promise} axios request promise
 */
export function getTemplates() {
  return axios.get('/admin/templates')
}

/**
 * @description fetch template details
 * @returns {Promise} axios request promise
 */
export function getTemplateDetails(templateId) {
  return axios.get(`/admin/templates/${templateId}`)
}
