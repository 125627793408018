import axios from 'axios'

/**
 * @description save a projects workmapping settings
 * @returns {Promise} axios request promise
 * @param {Number} projectId id of the project the setting belongs to
 * @param {Object} params form data to send
 */
export function saveProjectWorkmappings(projectId, params) {
  const form = new FormData()
  form.append('data', JSON.stringify(params))

  return axios.post(`/admin/projects/${projectId}/workmappings`, form)
}

/**
 * @description get a map details
 * @returns {Promise} axios request promise
 * @param {Number} mapId id of the map
 */
export function getWorkMap(mapId) {
  return axios.get(`/workmap/${mapId}`)
}
