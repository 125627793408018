<template>
  <forum-modal :id="id" :title="title" :is-loading="loading" @close="$emit('close')">
    <loading-indicator v-if="loading" />
    <template v-if="!error.length && !successMsg.length">
      <slot />
      <forum-ui-pharos-alert v-if="checkboxConfirmMsg.length" :status="variant" show>
        <div>{{ checkboxConfirmMsg }} Click here to confirm.</div>
        <b-form-checkbox v-model="confirmed" class="confirm-checkbox" />
      </forum-ui-pharos-alert>
      <template slot="modal-footer">
        <span v-if="footerWarning" class="footer-warning"> {{ footerWarning }} </span>
        <forum-ui-pharos-button
          :disabled="loading"
          variant="secondary"
          data-automation-component="no-btn"
          @click="$emit('close')"
        >
          {{ noBtn }}
        </forum-ui-pharos-button>
        <forum-ui-pharos-button
          :disabled="disabledActionBtn"
          variant="primary"
          data-automation-component="yes-btn"
          @click="$emit('yes')"
        >
          {{ yesBtn }}
        </forum-ui-pharos-button>
      </template>
    </template>
    <template v-if="successMsg.length">
      <forum-ui-pharos-alert status="success" show>{{ successMsg }}</forum-ui-pharos-alert>
      <template slot="modal-footer" />
    </template>
    <template v-else>
      <forum-ui-pharos-alert v-if="errorHtml" status="error" show v-html="error" />
      <error-message v-else :message="error" />
      <template slot="modal-footer" />
    </template>
  </forum-modal>
</template>

<script>
import ForumModal from './ForumModal'
import LoadingIndicator from '@/components/common/LoadingIndicator'
import ErrorMessage from '@/components/common/ErrorMessage'
export default {
  name: 'ConfirmationModal',
  components: {
    ForumModal,
    LoadingIndicator,
    ErrorMessage
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Confirm'
    },
    loading: {
      type: Boolean,
      required: false,
      default: undefined
    },
    yesBtn: {
      type: String,
      required: false,
      default: 'Yes'
    },
    noBtn: {
      type: String,
      required: false,
      default: 'No'
    },
    error: {
      type: String,
      required: false,
      default: ''
    },
    successMsg: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: 'confirmation-modal'
    },
    checkboxConfirmMsg: {
      type: String,
      required: false,
      default: ''
    },
    errorHtml: {
      type: Boolean,
      required: false,
      default: false
    },
    footerWarning: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      confirmed: false,
      variant: 'warning'
    }
  },
  computed: {
    disabledActionBtn() {
      return this.checkboxConfirmMsg.length ? this.loading || !this.confirmed : this.loading
    }
  }
}
</script>

<style scoped lang="scss">
.footer-warning {
  margin-right: auto;
  color: #a82626;
}

.modal-body {
  min-height: 300px;
}

.custom-control {
  align-items: unset;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
