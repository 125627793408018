import axios from 'axios'

/**
 * @description return targets for the institution
 * @returns {Promise} axios request promise
 */
export function getTargetTypes() {
  return axios.get('/admin/targets')
}

function _getCollectionUrl(institutionId, targetId, userId, url, apiKey) {
  let path = `/admin/institutions/${institutionId}`
  if (userId) {
    path += `/users/${userId}`
  }
  path += `/targets/${targetId}/collections`

  if (url && apiKey) {
    path += `?url=${encodeURIComponent(url)}&api_key=${encodeURIComponent(apiKey)}`
  }
  return path
}

/**
 * @description create a new collection
 * @returns {Promise} axios request promise
 * @param institutionId
 * @param targetId
 * @param userId
 * @param {object} options axios get request options
 */
export function createCollection(options) {
  const { institutionId, targetId, userId, collection } = options
  const { name, description, image } = collection
  const form = new FormData()
  form.append('name', name)
  form.append('description', description || '')
  form.append('target_id', targetId)
  if (image) {
    form.append('image', image)
  }
  if (userId) {
    form.append('profile_id', userId)
  }

  return axios.post(_getCollectionUrl(institutionId, targetId), form)
}

/**
 * @description create item types for a given target
 * @returns {Promise} axios request promise
 * @param {object} options axios get request options
 */
export function createItemTypes(url, apiKey) {
  const form = new FormData()
  form.append('url', url)
  form.append('api_key', apiKey)

  return axios.post('/admin/targets/omeka/item_types', form)
}

/**
 * @description create element sets for a given target
 * @returns {Promise} axios request promise
 * @param {object} options axios get request options
 */
export function createElementSets(url, apiKey) {
  const form = new FormData()
  form.append('url', url)
  form.append('api_key', apiKey)

  return axios.post('/admin/targets/omeka/element_sets', form)
}

/**
 * @description get institutional collections
 * @returns {Promise} axios request promise
 * @param institutionId
 * @param targetId
 * @param userId
 * @param {object} options axios get request options
 */
export function getCollections(options) {
  const { institutionId, targetId, userId, url, apiKey } = options
  return axios.get(_getCollectionUrl(institutionId, targetId, userId, url, apiKey))
}

/**
 * @description get collection info for an institution's target
 * @returns {Promise} axios request promise
 * @param {Number} institutionId id of the institution
 * @param {Number} targetId id of the target
 */
export function getInstitutionTargetCollectionInfo(options) {
  const { institutionId, targetId } = options
  return axios.get(`/admin/institution/${institutionId}/targets/${targetId}/collection-info`)
}
