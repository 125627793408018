export function capitalize(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function decode(str, hideApostrophe = false) {
  if (!str) return ''
  var textArea = document.createElement('textarea')
  textArea.innerHTML = str

  if (hideApostrophe) {
    return textArea.value.replace(/'/g, '')
  }

  return textArea.value
}
