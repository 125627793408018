import { getPortal, getJstorCollections, createPortal } from '../services/ccollection'

const defaultPortal = () => ({
  portalId: -1,
  collections: [],
  canonical_url: '',
  loading_collections: true,
  size: -1
})

const portals = {
  namespaced: true,
  state: defaultPortal(),
  mutations: {
    SET_ID(state, id) {
      state.portalId = id
    },
    SET_COLLECTIONS(state, collections) {
      state.collections = collections
      state.loading_collections = false
    },
    SET_CANONICAL_URL(state, url) {
      state.canonical_url = url
    },
    SET_SIZE(state, size) {
      state.size = size
    },
    RESET_STATE(state) {
      Object.assign(state, defaultPortal())
    }
  },
  actions: {
    async fetchPortal(context) {
      const res = await getPortal()
      context.commit('SET_SIZE', res.data.size)
      context.commit('SET_ID', res.data.size > 0 ? res.data.portals[0].id : 0)
      context.commit('SET_CANONICAL_URL', res.data.size > 0 ? res.data.portals[0].canonical_url : '')
      return res
    },
    async fetchLandingPageImage() {
      const res = await getPortal()
      return res.data.size > 0 ? res.data.portals[0].preferences.banner_image : ''
    },
    async createPortal(context, options) {
      const res = await createPortal(options)
      context.commit('SET_ID', res.data.portal_id)
      return res
    },
    async fetchJstorCollections(context) {
      const res = await getJstorCollections()
      const collections = res.data.collections
      context.commit('SET_COLLECTIONS', collections)
      return collections
    }
  },
  getters: {
    portalId: state => state.portalId,
    canonical_url: state => state.canonical_url,
    hasPortal: state => !!state.portalId,
    portalSize: state => state.size
  }
}

export default portals
