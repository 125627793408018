export default async function poll(
  fetchBlock,
  checkCondition,
  { interval = 1000, timeout = 10000 } = {},
  continueAfterTimeout
) {
  let isComplete = false
  let data
  const endAt = Date.now() + timeout
  while (!isComplete && Date.now() < endAt) {
    try {
      data = await fetchBlock()
      isComplete = checkCondition(data)
    } catch (error) {
      if (error.message === 'canceled') {
        return
      }
    }
    if (!isComplete) {
      await new Promise(resolve => setTimeout(resolve, interval))
    }
  }
  if (!data || (!isComplete && !continueAfterTimeout)) {
    throw new Error(
      'Sorry, your action failed! Please reload your page and try again. If it persists please contact ForumSupport@jstor.org'
    )
  }

  return data
}
