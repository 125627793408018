import { request } from '@/common/async.js'

export function downloadFile(url, options) {
  return request(
    { url: url, method: 'GET', params: (options && options.params) || {}, withCredentials: false },
    true
  ).then(response => {
    const filename = response.headers['download-filename']
    const result = document.createElement('a')
    result.style = 'display: none'
    const url = window.URL.createObjectURL(response.data)
    result.href = url
    result.download = filename
    document.body.appendChild(result)
    result.click()

    setTimeout(function() {
      document.body.removeChild(result)
      window.URL.revokeObjectURL(url)
    }, 100)
  })
}

export function downloadUri(uri) {
  var link = document.createElement('a')
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
