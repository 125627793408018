import axios from 'axios'
import { request } from '@/common/async.js'

/**
 * @description fetch a list of field definitions for a project
 * @returns {Promise} axios request promise
 */
export function getFieldDefinitions(projectId) {
  return axios.get(`/admin/projects/${projectId}/definitions`)
}

/**
 * @description delete a field definition
 * @param projectId
 * @param fieldId
 * @returns {Promise} axios request promise
 */
export function deleteFieldDefinition(projectId, fieldId) {
  return request({ url: `/admin/projects/${projectId}/definitions/${fieldId}`, method: 'DELETE' })
}

/**
 * @description create a definition (field) for a given project
 * @param {number} projectId id of the project the field will belong to
 * @param {object} data user input data to post to the service
 * @returns {Promise} axios request promise
 */
export function createProjectDefinitions(projectId, data) {
  const params = new URLSearchParams()
  for (let key in data) {
    if (data[key] !== null && data[key] !== undefined) {
      params.append(key, data[key])
    }
  }

  return request({
    url: `/admin/projects/${projectId}/definitions`,
    method: 'POST',
    data: params,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  })
}

/**
 * @description get available linked sources for a given project
 * @param {number} projectId id of the project the field will belong to
 * @returns {Promise} axios request promise
 */
export function getProjectSources(projectId) {
  return axios.get(`/extensions/linked-field/external-lookups/project/${projectId}`)
}

/**
 * @description get available linked sources for a general institution
 * @param {number} institution id taken from from the request from session
 * @returns {Promise} axios request promise with available sources
 */
export function getProjectSourcesFromInstitutionId() {
  return axios.get(`/extensions/linked-field/external-lookups`)
}

/**
 * @description fetch info about whether or not field is empty
 * @param projectId
 * @param fieldId
 * @returns {Promise} axios request promise
 */
export function getFieldData(projectId, fieldId) {
  return axios.get(`/admin/projects/${projectId}/definitions/${fieldId}/is-empty`)
}

/**
 * @description get sources linked to a field
 * @param {number} fieldId id of the field definition
 * @returns {Promise} axios request promise
 */
export function getLinkedFieldSources(fieldId) {
  return axios.get(`/extensions/lookup-field/${fieldId}/external-sources`)
}

/**
 * @description post sources linked to a field
 * @param {number} fieldId id of the field definition
 * @param {number} fieldId id of the field definition
 * @param {number[]} sources array of selected sources
 * @returns {Promise} axios request promise
 */
export function saveLinkedFieldSources({ projectId, fieldId, sources }) {
  const form = new FormData()
  form.append('project_id', projectId)
  form.append('external_sources', JSON.stringify(sources))

  return axios.post(`/extensions/lookup-field/${fieldId}/external-sources`, form)
}

/**
 * @description update a definition (field) for a given project
 * @param {number} projectId id of the project the field will belong to
 * @param {object} data user input data to post to the service
 * @returns {Promise} axios request promise
 */
export function updateProjectDefinitions(projectId, fieldId, data) {
  const params = new URLSearchParams()
  for (let key in data) {
    if (data[key] !== null && data[key] !== undefined) {
      params.append(key, data[key])
    }
  }

  return request({
    url: `/admin/projects/${projectId}/definitions/${fieldId}`,
    method: 'PUT',
    data: params,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  })
}

/**
 * @description updates the position for multiple definition (field) for a given project
 * @param {number} projectId id of the project the field will belong to
 * @param {array} data list of { id, position } objects for the updated definitions
 * @returns {Promise} axios request promise
 */
export function bulkUpdateProjectDefinitions(projectId, data) {
  return axios.request({
    url: `/admin/_bulk/projects/${projectId}/definitions`,
    method: 'POST',
    data
  })
}
