import { getDashboardProjects, getProjectThumbnail } from '@/services/scs-lambdas'
import chunk from 'lodash/chunk'

const defaultDashboard = () => ({
  loading: true,
  items: [],
  meta: {
    total: 0,
    total_published_artstor: 0,
    total_published_jstor: 0,
    total_published_omeka: 0
  },
  thumbnails: {}
})

const dashboard = {
  namespaced: true,
  state: defaultDashboard(),
  mutations: {
    SET_DATA(state, data) {
      state.items = data.items
      state.meta = data.meta
      state.loading = false
    },
    SET_THUMBNAIL(state, { projectId, url }) {
      state.thumbnails[projectId] = url
    },
    RESET_STATE(state) {
      Object.assign(state, defaultDashboard())
    }
  },
  actions: {
    async fetchDashboardProjects(context, projects) {
      const token = context.rootState.user.token
      const projectIds = projects.map(project => project.id)
      const results = await Promise.all(chunk(projectIds, 100).map(ids => getDashboardProjects(token, ids)))
      const meta = {
        total: 0,
        total_published_artstor: 0,
        total_published_jstor: 0,
        total_published_omeka: 0
      }

      const items = []

      for (const res of results) {
        const part = res.data
        meta.total += part.meta.total
        meta.total_published_artstor += part.meta.total_published_artstor
        meta.total_published_jstor += part.meta.total_published_jstor
        meta.total_published_omeka += part.meta.total_published_omeka
        items.push(...part.items)
      }

      context.commit('SET_DATA', { items, meta })
      return results
    },
    async fetchProjectThumbnail(context, projectId) {
      if (context.state.thumbnails[projectId] !== undefined) {
        return context.state.thumbnails[projectId]
      }

      let url = null
      try {
        const token = context.rootState.user.token
        const res = await getProjectThumbnail(token, projectId)
        url = res.data.url
      } catch (e) {
        /**/
      }

      context.commit('SET_THUMBNAIL', { projectId, url })
      return url
    }
  },
  getters: {
    projects: state => {
      const projectMap = {}
      state.items.forEach(project => {
        projectMap[project.id] = project
      })
      return projectMap
    },
    summary: state => state.meta
  }
}

export default dashboard
