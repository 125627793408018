import axios from 'axios'

/**
 * @description get a given media object's representation details
 * @param {string} objectId
 * @returns {Promise} axios request promise
 */
export function getMediaObjectRepresentationDetails(objectId) {
  return axios.get(`/media-objects/${objectId}/representation/details`)
}

/**
 * @description get a given media object's google image search link
 * @param {string} id
 * @returns {Promise} axios request promise
 */
export function getMediaObjectShortURL(id) {
  return axios.get(`/media-objects/${id}/shorten`)
}
