import axios from 'axios'

/**
 * @description get a logged in users account information
 * @returns an axios request promise
 */
export function getAccount() {
  return axios.get('/account')
}

/**
 * @description log a user out of the application
 * @returns an axios request promise
 */
export function deleteAccount() {
  return axios.delete('/account')
}
