import findIndex from 'lodash/findIndex'

const gridColumnState = {
  actions: {
    selectColumns(context, options) {
      let { gridState, selected } = options

      let store = options.store || 'projects'
      const columns = context.rootGetters[`${store}/getColumns`](gridState)

      const newColumns = columns.map(col => ({
        width: col['width'],
        hidden: selected.indexOf(col['id']) < 0,
        id: col['id']
      }))

      context.dispatch('updateProjectColumns', { gridState, columns: newColumns })
    },
    toggleAllColumns(context, options) {
      let { gridState, checked } = options

      let store = options.store || 'projects'
      const columns = context.rootGetters[`${store}/getColumns`](gridState)

      const newColumns = columns.map(col => ({
        width: col['width'],
        hidden: !checked,
        id: col['id']
      }))

      context.dispatch('updateProjectColumns', { gridState, columns: newColumns })
    },
    resizedColumn(context, options) {
      let { gridState, id, width } = options

      let store = options.store || 'projects'
      const columns = context.rootGetters[`${store}/getColumns`](gridState)

      const newColumns = columns.map(col => ({
        width: col.id === id ? width : col.width,
        hidden: col.hide,
        id: col.id
      }))

      context.dispatch('updateProjectColumns', { gridState, columns: newColumns })
    },
    movedColumn(context, options) {
      let { gridState, id, toIndex } = options

      let store = options.store || 'projects'
      const columns = context.rootGetters[`${store}/getColumns`](gridState)

      const fromIndex = findIndex(columns, col => col['id'] === id)

      const newColumns = columns.map(col => ({
        width: col['width'],
        hidden: col['hide'],
        id: col['id']
      }))

      newColumns.splice(toIndex, 0, newColumns.splice(fromIndex, 1)[0])

      context.dispatch('updateProjectColumns', { gridState, columns: newColumns })
    }
  }
}

export default gridColumnState
