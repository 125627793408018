import Vue from 'vue'
import Vuex from 'vuex'

import dashboard from './dashboard'
import projects from './projects'
import tabs from './tabs'
import user from './user'
import uploads from './uploads'
import userState from './userState'
import publishing from './publishing'
import asset from './asset'
import term from './term'
import thumbnails from './thumbnails'
import captions from './captions'
import controlledLists from './controlledLists'
import banner from './banner'
import profile from './profile'
import vocabularies from './vocabularies'
import institutions from './institutions'
import portals from './portals'
import lists from './lists'
import forms from './forms'
import timing from './timing'
import userSettings from '@/pages/user-settings/store'
import formFields from './formFields'
import projectSettings from '@/pages/project-settings/store/projectSettings'
import publishingTargets from '@/pages/publishing-targets/store'
import projectFields from '@/pages/project-settings/store/projectFields'
import omekaTarget from './omekaTarget'
import featureFlags from './featureFlags'

// for state management
Vue.use(Vuex)

const defaultState = () => ({
  showGlobalLoader: false
})

const store = new Vuex.Store({
  strict: true,
  modules: {
    dashboard,
    banner,
    user,
    tabs,
    projects,
    uploads,
    userState,
    publishing,
    asset,
    term,
    thumbnails,
    captions,
    controlledLists,
    vocabularies,
    institutions,
    profile,
    timing,
    portals,
    userSettings,
    projectSettings,
    lists,
    projectFields,
    forms,
    formFields,
    omekaTarget,
    publishingTargets,
    featureFlags
  },
  state: defaultState(),
  mutations: {
    TOGGLED_GLOBAL_LOADER(state, isVisible) {
      state.showGlobalLoader = isVisible
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState())
    }
  },
  actions: {
    toggleGlobalLoader(context, isVisible) {
      context.commit('TOGGLED_GLOBAL_LOADER', isVisible)
    },
    resetState(context) {
      context.commit('RESET_STATE')
      context.commit('banner/RESET_STATE')
      context.commit('captions/RESET_STATE')
      context.commit('controlledLists/RESET_STATE')
      context.commit('dashboard/RESET_STATE')
      context.commit('formFields/RESET_STATE')
      context.commit('formFields/RESET_STATE')
      context.commit('forms/RESET_STATE')
      context.commit('institutions/RESET_STATE')
      context.commit('lists/RESET_STATE')
      context.commit('omekaTarget/RESET_STATE')
      context.commit('portals/RESET_STATE')
      context.commit('projectFields/RESET_STATE')
      context.commit('projects/RESET_STATE')
      context.commit('projectSettings/RESET_STATE')
      context.commit('publishing/RESET_STATE')
      context.commit('tabs/RESET_STATE')
      context.commit('thumbnails/RESET_STATE')
      context.commit('timing/RESET_STATE')
      context.commit('uploads/RESET_STATE')
      context.commit('user/RESET_STATE')
      context.commit('userState/RESET_STATE')
      context.commit('featureFlags/RESET_STATE')
    }
  }
})

export default store
