import axios from 'axios'
import axiosRetry from 'axios-retry'

/**
 * @description captains log
 * @param {event}
 * @returns {Promise} axios request promise
 */
export function captainsLog(token, event) {
  axiosRetry(axios, { retries: 3, retryDelay: count => count * 500, retryCondition: () => true })

  return axios.post(`/captains-log`, event, {
    headers: {
      Authorization: token
    }
  })
}
