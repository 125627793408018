export function pushToDatalayer(eventCategory, eventAction, eventLabel, eventValue) {
  window.dataLayer.push({
    event: 'GAEvent',
    eventCategory: eventCategory || null,
    eventAction: eventAction || null,
    eventLabel: eventLabel || null,
    eventValue: eventValue || null
  })
}

export function pushToDirectDatalayer(key, value) {
  window.dataLayer.push({
    [key]: value
  })
}
