import axios from 'axios'

/**
 * @description send feedback about a specified feature
 * @param {string} feature feature to post feedback for
 * @param {number} rating numerical rating between 1 and 5
 * @param {string} message optional message
 * @returns an axios request promise
 */
export function saveFeedback(token, feature, rating, message, role) {
  return axios.post(
    '/user/feedbacks',
    { feature, rating, message, role },
    {
      headers: {
        Authorization: token
      }
    }
  )
}

/**
 * @description fetch all submitted feedbacks for a feature
 * @param {string} feature feature to get feedback for
 * @returns {Promise} axios request promise
 */
export function getFeedbacks(token, feature) {
  return axios(`/user/feedbacks?Feature=${feature}`, {
    headers: {
      Authorization: token
    }
  })
}
