import { loginToArtstor, updatePassword } from '../services/aaa.js'
import { requestPasswordReset, validateResetRequest, resetPassword } from '../services/iac.js'

const profile = {
  namespaced: true,
  actions: {
    loginToArtstor(context, options) {
      const email = context.rootState.user.email
      const { password } = options
      return loginToArtstor(email, password)
    },
    updatePassword(context, options) {
      const { currentPassword, newPassword } = options
      return updatePassword(currentPassword, newPassword)
    },
    requestPasswordReset(context, email) {
      return requestPasswordReset(email)
    },
    validateResetRequest(context, { email, signature }) {
      return validateResetRequest(email, signature)
    },
    resetPassword(context, { email, signature, password }) {
      return resetPassword(email, signature, password)
    }
  }
}

export default profile
