import axios from 'axios'
import { request } from '@/common/async.js'
import { decode } from '@/common/filters'
import { getForumCookie } from '@/common/cookie'

/**
 * @description get institution emails
 * @returns {Promise} axios request promise
 */
export function getEmailMap() {
  return axios.get(`/institution/accounts/email-id-map`)
}

/**
 * @description get publication data
 * @param {object} options axios get request options
 * @returns {Promise} axios request promise
 */
export function getPublicationData(options) {
  const { params, institutionId } = options
  const queryParams = new URLSearchParams(params).toString()

  return axios.get(`/sync-monitor/by-institute/${institutionId}?${queryParams}`, {
    withCredentials: false
  })
}

/**
 * @description publish assets
 * @param {object} options axios post request options
 * @returns {Promise} axios request promise
 */
export function publishAssets(options) {
  const { assetIds, publishingTargetIds, projectId, selectAll, paramId, assetType, query } = options
  const formData = new FormData()

  if (selectAll) {
    formData.append('all', true)
    formData.append('filter', query.filter)
    if (query.query) {
      formData.append('query', query.query)
    }
  } else {
    assetIds.forEach(id => {
      formData.append('asset_ids', id)
    })
  }

  publishingTargetIds.forEach(id => {
    formData.append('publishing_target_ids', id)
  })

  const id = selectAll ? paramId : projectId
  const type = selectAll ? assetType : 'projects'

  return request({
    url: `/_bulk/${type}/${id}/publish/assets`,
    method: 'POST',
    data: formData
  })
}

/**
 * @description suppress assets
 * @param {object} options axios post request options
 * @returns {Promise} axios request promise
 */
export function suppressAssets(options) {
  const { assetIds, suppressingTargetIds, projectId, selectAll, paramId, assetType, query } = options

  const formData = new FormData()

  if (selectAll) {
    formData.append('all', true)
    formData.append('filter', query.filter)
    if (query.query) {
      formData.append('query', query.query)
    }
  } else {
    assetIds.forEach(id => {
      formData.append('asset_ids', id)
    })
  }
  suppressingTargetIds.forEach(id => {
    formData.append('publishing_target_ids', id)
  })

  const id = selectAll ? paramId : projectId
  const type = selectAll ? assetType : 'projects'

  return request({ url: `/_bulk/${type}/${id}/suppress/assets`, method: 'POST', data: formData })
}

/**
 * @description get publication targets
 * @param {object} projectId projectId
 * @returns {Promise} axios request promise
 */
export function getTargets(projectId) {
  return axios.get(`/projects/${projectId}/publishing-targets`).then(response => {
    if (response.data.items.length) {
      let str = decode(response.data.items[0].name)
      response.data.items[0].name = str
    }
    return response
  })
}

/**
 * @description set publishing notification for user
 * @param {object} queueJson { batchId, type, to }
 * @returns {Promise} axios request promise
 */
export function addPublishingNotification(options) {
  const headers = {
    Authorization: getForumCookie()
  }

  return axios.post('/publish-notification/add', options, { headers })
}
