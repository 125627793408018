import axios from 'axios'

/**
 * @description bulk export assets
 * @param {string} projectId project id
 * @param {string} institutionId institution id
 * @param {array} assetsArr array of assets
 */
export function getBulkExport(projectId, institutionId, assetsArr) {
  const params = {
    project_id: projectId,
    institution_id: institutionId,
    data: JSON.stringify(assetsArr)
  }

  return axios.get(`/_bulk_export?${new URLSearchParams(params).toString()}`)
}
