const UserSettings = () => import(/* webpackChunkName: "user-settings" */ './components/UserSettings')
const AddUsersPage = () => import(/* webpackChunkName: "user-settings" */ './components/AddUsersPage')

const NEW_USER_PAGE = 'New User Page'

export default [
  {
    path: '/new-user',
    name: 'create-new-user',
    component: AddUsersPage,
    meta: {
      announcer: NEW_USER_PAGE,
      title: NEW_USER_PAGE,
      titleGA: 'New User'
    }
  },
  {
    path: 'users',
    name: 'users',
    meta: {
      announcer: 'User Settings Page',
      title: 'User Settings Page',
      titleGA: 'User Settings'
    },
    component: UserSettings,
    props: true
  }
]
