export default async function tc(action, onError) {
  try {
    const response = await action()
    onError('')
    return response
  } catch (err) {
    // if bad request, server message should overrule standard 500 msg
    const errorCodesWithMessages = [400, 403]
    if (err.response && errorCodesWithMessages.includes(err.response.status)) {
      return onError(err.response.data.message || err.message)
    } else {
      return onError(err.message)
    }
  }
}
