import Vue from 'vue'
import Router from 'vue-router'

const MainLayout = () => import(/* webpackChunkName: "main" */ '@/components/layout/MainLayout')

// Dashboard
const DashboardPage = () => import(/* webpackChunkName: "main" */ '@/components/Dashboard')
// Admin Projects
const AdminProjectsPage = () => import(/* webpackChunkName: "main" */ '@/pages/admin-projects/AdminProjects')

// Projects
const ProjectListPage = () => import(/* webpackChunkName: "projects" */ '@/components/projects/ProjectListPage')
const ProjectDetailsPage = () => import(/* webpackChunkName: "projects" */ '@/components/projects/ProjectDetailsPage')
const NewImportPage = () => import(/* webpackChunkName: "import" */ '@/components/import-workflow/NewImportPage.vue')
const UploadPage = () => import(/* webpackChunkName: "projects" */ '@/components/projects/UploadPage')

// Controlled Lists
const ControlledList = () => import(/* webpackChunkName: "lists" */ '@/components/controlled-lists/ControlledList')
const ControlledListDetails = () =>
  import(/* webpackChunkName: "lists" */ '@/components/controlled-lists/ControlledListDetails')

// Edit Pages
const EditPage = () => import(/* webpackChunkName: "edit" */ '@/components/layout/EditPage')

const EditPortalPage = () => import(/* webpackChunkName: "admin-portal" */ '@/components/admin/EditPortalPage')
const NewProjectPage = () => import(/* webpackChunkName: "edit" */ '@/pages/new-project/NewProjectPage.vue')
const ProjectSettingsPage = () =>
  import(/* webpackChunkName: "project-settings" */ '@/pages/project-settings/components/ProjectSettingsPage')
const ProjectOverview = () =>
  import(/* webpackChunkName: "project-settings" */ '@/pages/project-settings/components/ProjectOverview')
const ProjectPublishingTargets = () =>
  import(
    /* webpackChunkName: "publishing-targets" */ '@/pages/project-settings/components/targets/ProjectPublishingTargets'
  )
const PublishingTargetMapping = () =>
  import(
    /* webpackChunkName: "publishing-targets" */ '@/pages/project-settings/components/targets/target-mappings/PublishingTargetMapping'
  )
const EditCollectionLandingPage = () =>
  import(
    /* webpackChunkName: "publishing-targets" */ '@/pages/publishing-targets/components/collection-landing/EditCollectionLandingPage'
  )
const FieldDefinitions = () =>
  import(/* webpackChunkName: "publishing-targets" */ '@/pages/project-settings/components/fields/FieldDefinitions')

const NewTargetPage = () => import(/* webpackChunkName: "publishing-targets" */ '@/pages/new-target/NewTargetPage')

const CatalogingForms = () =>
  import(
    /* webpackChunkName: "cataloging-forms" */ '@/pages/project-settings/components/cataloging-forms/CatalogingForms'
  )
const CatalogingFormFields = () =>
  import(
    /* webpackChunkName: "cataloging-forms" */ '@/pages/project-settings/components/cataloging-forms/CatalogingFormFields'
  )
const ProjectUsers = () =>
  import(/* webpackChunkName: "project-users" */ '@/pages/project-settings/components/users/ProjectUsers')

const AddPermissionPage = () =>
  import(/* webpackChunkName: "project-users" */ '@/pages/project-settings/components/users/AddPermissionPage.vue')

const PreservationPage = () =>
  import(/* webpackChunkName: "project-users" */ '@/pages/project-settings/components/preservation/Preservation.vue')

const StatisticsPage = () =>
  import(/* webpackChunkName: "statistics" */ '@/pages/project-settings/components/Statistics.vue')

const AdminRedirectPage = () => import(/* webpackChunkName: "redirect" */ '@/components/AdminRedirectPage.vue')
const LoginPage = () => import(/* webpackChunkName: "login" */ '@/components/login/LoginPage')
const LoginForm = () => import(/* webpackChunkName: "login" */ '@/components/login/LoginForm')
const ShibbolethForm = () => import(/* webpackChunkName: "login" */ '@/components/login/ShibbolethForm')
const ForgotPassword = () => import(/* webpackChunkName: "login" */ '@/components/login/ForgotPassword')
const ResetPassword = () => import(/* webpackChunkName: "login" */ '@/components/login/ResetPassword')

import qs from 'qs'
import userSettingsRoutes from '@/pages/user-settings/router'
import UserPermissions from '@/pages/common/permissions/UserPermissions'

const DASHBOARD_PAGE = 'Dashboard'
const ADMIN_PROJECTS_PAGE = 'All Projects'
const PROJECTS_PAGE = 'Projects Page'
const LISTS_PAGE = 'Lists Page'
const NEW_TERM_PAGE = 'New Term Page'
const EDIT_TERM_PAGE = 'Edit Term Page'
const EDIT_ITEM_PAGE = 'Edit Item Page'
const BATCH_EDIT_ITEM_PAGE = 'Batch Edit Items Page'
const SPREADSHEET_UPLOADER_PAGE = 'Spreadsheet Uploader'
const EDIT_LANDING_PAGE = 'Edit Landing Page'
const CREATE_LANDING_PAGE = 'Create Portal Page'
const PROJECT_SETTINGS_PAGE = 'Project Settings Page'
const NEW_PROJECT_PAGE = 'New Project Page'
const COLLECTION_LANDING = 'Collection Landing Page'
const TARGETS_PAGE = 'Targets Page'
const NEW_TARGET_PAGE = 'New Target Page'
const FIELDS_PAGE = 'Fields Page'
const CATALOGING_FORMS_PAGE = 'Cataloging Forms Page'
const PROJECT_USERS_PAGE = 'Users Page'
const PROJECT_PRESERVATION_PAGE = 'Users Page'
const ADD_PROJECT_USER_PAGE = 'Add Project User'
const EDIT_PROJECT_USER_PAGE = 'Edit Project User'
const ADD_USER_PERMISSIONS_PAGE = 'Add User Permissions Page'
const EDIT_USER_PERMISSION_PAGE = 'Edit User Permission Page'
const USER_PERMISSIONS_PAGE = 'User Permissions Page'
const ADMIN_REDIRECT_PAGE = 'Admin Redirect Page'
const ADD_USER_PERMISSION_META = {
  announcer: ADD_USER_PERMISSIONS_PAGE,
  title: ADD_USER_PERMISSIONS_PAGE,
  titleGA: 'Add User Permissions'
}
const EDIT_USER_PERMISSION_META = {
  announcer: EDIT_USER_PERMISSION_PAGE,
  title: EDIT_USER_PERMISSION_PAGE,
  titleGA: 'Edit User Permissions'
}
export const LOGIN_PAGE = 'Login Page'
export const LOGIN_FORM = 'Login Form'
export const SHIBBOLETH_FORM = 'Shibboleth Form'
export const FORGOT_PASSWORD_FORM = 'Forgot Password Form'
export const RESET_PASSWORD_FORM = 'Reset Password Form'

export function createRouter() {
  Vue.use(Router)

  const router = new Router({
    routes: [
      {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: {
          announcer: LOGIN_PAGE,
          title: LOGIN_PAGE,
          titleGA: LOGIN_PAGE
        },
        children: [
          {
            path: 'by-email',
            name: 'login.by-email',
            component: LoginForm,
            meta: {
              announcer: LOGIN_FORM,
              title: LOGIN_FORM,
              titleGA: LOGIN_FORM
            }
          },
          {
            path: 'shibboleth',
            name: 'login.by-shibboleth',
            component: ShibbolethForm,
            meta: {
              announcer: LOGIN_FORM,
              title: LOGIN_FORM,
              titleGA: LOGIN_FORM
            }
          },
          {
            path: 'forgot',
            name: 'login.forgot-password',
            component: ForgotPassword,
            meta: {
              announcer: FORGOT_PASSWORD_FORM,
              title: FORGOT_PASSWORD_FORM,
              titleGA: FORGOT_PASSWORD_FORM
            }
          },
          {
            path: 'reset/:email/:signature',
            name: 'login.reset-password',
            component: ResetPassword,
            meta: {
              announcer: LOGIN_FORM,
              title: LOGIN_FORM,
              titleGA: LOGIN_FORM
            }
          }
        ]
      },
      {
        path: '/',
        name: 'mainLayout',
        component: MainLayout,
        redirect: '/dashboard',
        children: [
          {
            path: '/dashboard',
            name: 'dashboard',
            component: DashboardPage,
            meta: {
              announcer: DASHBOARD_PAGE,
              title: DASHBOARD_PAGE
            }
          },
          {
            path: '/admin-redirect',
            name: 'admin-redirect',
            component: AdminRedirectPage,
            meta: {
              title: ADMIN_REDIRECT_PAGE
            }
          },
          {
            path: '/admin-projects',
            name: 'admin-projects',
            component: AdminProjectsPage,
            meta: {
              announcer: ADMIN_PROJECTS_PAGE,
              title: ADMIN_PROJECTS_PAGE
            }
          },
          {
            path: '/import-metadata',
            name: 'import-metadata',
            component: NewImportPage,
            meta: {
              announcer: SPREADSHEET_UPLOADER_PAGE,
              title: SPREADSHEET_UPLOADER_PAGE,
              hideBars: true
            }
          },
          {
            path: '/new-project',
            name: 'create-new-project',
            component: NewProjectPage,
            meta: {
              announcer: NEW_PROJECT_PAGE,
              title: NEW_PROJECT_PAGE,
              titleGA: 'New Project'
            }
          },
          {
            path: 'projects/:id/:assetType?/:paramId?/edit/:assetid',
            name: 'projects.single-edit',
            component: EditPage,
            meta: {
              announcer: EDIT_ITEM_PAGE,
              title: EDIT_ITEM_PAGE,
              layout: 'fluid'
            }
          },
          {
            path: 'projects/:id/:assetType?/:paramId?/edit',
            name: 'projects.batch-edit',
            component: EditPage,
            meta: {
              announcer: BATCH_EDIT_ITEM_PAGE,
              title: BATCH_EDIT_ITEM_PAGE,
              layout: 'fluid'
            }
          },
          {
            path: 'projects/:id/users/new',
            name: 'project-settings.users.add-project-permission',
            component: AddPermissionPage,
            props: true,
            meta: {
              announcer: ADD_PROJECT_USER_PAGE,
              title: ADD_PROJECT_USER_PAGE,
              titleGA: 'Add User Permissions'
            }
          },
          {
            path: 'projects/:id/users/:permissionId',
            name: 'project-settings.users.edit-project-permission',
            component: AddPermissionPage,
            props: true,
            meta: {
              announcer: EDIT_PROJECT_USER_PAGE,
              title: EDIT_PROJECT_USER_PAGE,
              titleGA: 'Edit User Permissions'
            }
          },
          {
            path: 'projects/:id/settings',
            component: ProjectSettingsPage,
            children: [
              {
                path: '',
                name: 'project-settings',
                component: ProjectOverview,
                meta: {
                  announcer: PROJECT_SETTINGS_PAGE,
                  title: PROJECT_SETTINGS_PAGE,
                  titleGA: 'Project Settings - Overview'
                }
              },
              {
                path: 'fields',
                name: 'project-settings.fields',
                component: FieldDefinitions,
                props: true,
                meta: {
                  announcer: FIELDS_PAGE,
                  title: FIELDS_PAGE,
                  titleGA: 'Project Details - Field Definitions'
                }
              },
              {
                path: 'targets',
                name: 'project-settings.targets',
                component: ProjectPublishingTargets,
                meta: {
                  announcer: TARGETS_PAGE,
                  title: TARGETS_PAGE,
                  titleGA: 'Project Details - Publishing Targets'
                },
                children: [
                  {
                    path: ':targetId',
                    name: 'project-settings.targets.mapping',
                    meta: {
                      announcer: TARGETS_PAGE,
                      title: TARGETS_PAGE,
                      titleGA: 'Project Details - Publishing Target Mapping'
                    },
                    component: PublishingTargetMapping
                  }
                ]
              },
              {
                path: 'cataloging-forms',
                name: 'project-settings.cataloging-forms',
                meta: {
                  announcer: CATALOGING_FORMS_PAGE,
                  title: CATALOGING_FORMS_PAGE,
                  titleGA: 'Project Details - Cataloging Forms'
                },
                component: CatalogingForms,
                children: [
                  {
                    path: ':formId',
                    name: 'project-settings.cataloging-form-fields',
                    meta: {
                      announcer: CATALOGING_FORMS_PAGE,
                      title: CATALOGING_FORMS_PAGE,
                      titleGA: 'Project Details - Cataloging Form Fields'
                    },
                    component: CatalogingFormFields
                  }
                ]
              },
              {
                path: 'users',
                name: 'project-settings.users',
                meta: {
                  announcer: PROJECT_USERS_PAGE,
                  title: PROJECT_USERS_PAGE,
                  titleGA: 'Project Details - Users'
                },
                component: ProjectUsers,
                props: true
              },
              {
                path: 'preservation',
                name: 'project-settings.preservation',
                meta: {
                  announcer: PROJECT_PRESERVATION_PAGE,
                  title: PROJECT_PRESERVATION_PAGE,
                  titleGA: 'Project Details - Preservation'
                },
                component: PreservationPage,
                props: true
              }
            ]
          },
          {
            path: '/projects/:projectId/new-target',
            name: 'create-new-target',
            component: NewTargetPage,
            meta: {
              announcer: NEW_TARGET_PAGE,
              title: NEW_TARGET_PAGE,
              titleGA: 'New Target'
            }
          },
          {
            path: '/projects/:projectId/settings/target/:targetId',
            name: 'collection-page',
            component: EditCollectionLandingPage,
            meta: {
              announcer: COLLECTION_LANDING,
              title: COLLECTION_LANDING,
              titleGA: 'New User'
            }
          },
          {
            path: 'statistics',
            name: 'statistics',
            meta: {
              announcer: 'Statistics Page',
              title: 'Statistics Page',
              titleGA: 'Statistics'
            },
            component: StatisticsPage
          },
          {
            path: 'projects',
            name: 'projects',
            component: ProjectListPage,
            meta: {
              announcer: PROJECTS_PAGE,
              title: PROJECTS_PAGE
            },
            children: [
              {
                path: ':id',
                name: 'projects.details',
                component: ProjectDetailsPage,
                meta: {
                  announcer: PROJECTS_PAGE,
                  title: PROJECTS_PAGE
                },
                props: true,
                children: [
                  {
                    path: 'upload',
                    name: 'upload-items',
                    component: UploadPage,
                    meta: {
                      announcer: 'Upload Items Page',
                      title: 'Upload Items Page'
                    },
                    props: true
                  }
                ]
              },
              {
                path: ':id/sets/:setId',
                name: 'projects.sets',
                component: ProjectDetailsPage,
                meta: {
                  announcer: 'Sets Page',
                  title: 'Sets Page'
                },
                props: true,
                children: [
                  {
                    path: 'upload',
                    name: 'upload-items-set',
                    component: UploadPage,
                    meta: {
                      announcer: 'Upload Items Page',
                      title: 'Upload Items Page'
                    },
                    props: true
                  }
                ]
              },
              {
                path: ':id/filters/:filterId',
                name: 'projects.filters',
                component: ProjectDetailsPage,
                meta: {
                  announcer: 'Saved Filters Page',
                  title: 'Saved Filters Page'
                },
                props: true,
                children: [
                  {
                    path: 'upload',
                    name: 'upload-items-filter',
                    component: UploadPage,
                    meta: {
                      announcer: 'Upload Items Page',
                      title: 'Upload Items Page'
                    },
                    props: true
                  }
                ]
              }
            ]
          },
          {
            path: '/portals/new',
            name: 'new-portal-page',
            component: EditPortalPage,
            meta: {
              announcer: CREATE_LANDING_PAGE,
              title: CREATE_LANDING_PAGE,
              titleGA: CREATE_LANDING_PAGE
            }
          },
          {
            path: '/portals/:portalId/edit',
            name: 'edit-portal-page',
            component: EditPortalPage,
            meta: {
              announcer: EDIT_LANDING_PAGE,
              title: EDIT_LANDING_PAGE,
              titleGA: EDIT_LANDING_PAGE
            }
          },
          {
            path: 'controlled-lists/:id/edit',
            name: 'controlled-lists.edit',
            component: EditPage,
            meta: {
              announcer: EDIT_TERM_PAGE,
              title: EDIT_TERM_PAGE,
              layout: 'fluid'
            }
          },
          {
            path: 'controlled-lists/:id/edit/:assetid',
            name: 'controlled-lists.single-edit',
            component: EditPage,
            meta: {
              announcer: EDIT_TERM_PAGE,
              title: EDIT_TERM_PAGE,
              layout: 'fluid'
            }
          },
          {
            path: 'controlled-lists/:id/new',
            name: 'controlled-lists.new',
            component: EditPage,
            meta: {
              announcer: NEW_TERM_PAGE,
              title: NEW_TERM_PAGE,
              layout: 'fluid'
            }
          },
          {
            path: 'controlled-lists',
            name: 'controlled-lists',
            component: ControlledList,
            meta: {
              announcer: LISTS_PAGE,
              title: LISTS_PAGE
            },
            // redirect: 'controlled-lists/:id/terms',
            children: [
              {
                name: 'controlled-lists.details',
                path: ':id',
                redirect: ':id/terms'
              },
              {
                path: ':id/terms',
                name: 'controlled-lists.terms',
                component: ControlledListDetails,
                meta: {
                  announcer: LISTS_PAGE,
                  title: LISTS_PAGE
                }
              },
              {
                path: ':id/fields',
                name: 'controlled-lists.fields',
                meta: {
                  announcer: FIELDS_PAGE,
                  title: FIELDS_PAGE,
                  titleGA: 'List Detail - Fields'
                },
                component: FieldDefinitions
              },
              {
                path: ':id/users',
                name: 'controlled-lists.users',
                meta: {
                  announcer: USER_PERMISSIONS_PAGE,
                  title: USER_PERMISSIONS_PAGE,
                  titleGA: 'List Detail - User Permissions'
                },
                component: UserPermissions,
                props: true
              }
            ]
          },
          {
            path: '/controlled-lists/:id/permissions/new',
            name: 'add-list-permission',
            component: AddPermissionPage,
            props: true,
            meta: ADD_USER_PERMISSION_META
          },
          {
            path: '/controlled-lists/:id/permission/:permissionId',
            name: 'edit-list-permission',
            component: AddPermissionPage,
            props: true,
            meta: EDIT_USER_PERMISSION_META
          },
          ...userSettingsRoutes
        ]
      },
      { path: '*', redirect: '/' }
    ],
    parseQuery(query) {
      return qs.parse(query)
    },
    stringifyQuery(query) {
      let result = qs.stringify(query, { encode: false })

      return result ? '?' + result : ''
    }
  })

  router.beforeEach((to, _from, next) => {
    if (to.query['debug']) {
      if (to.query['cobrowse-pin']) {
        window.Cobrowse?.modal.startSession(to.query['cobrowse-pin'])
      } else if (to.query['cobrowse-pin'] !== undefined) {
        window.Cobrowse?.modal.openModal()
      }
    }

    document.title = `JSTOR Forum | ${to.meta.title || ''}`
    if (to.name.startsWith('login')) {
      const zdWidget = document.createElement('script')
      zdWidget.id = 'ze-snippet'
      zdWidget.src = `//static.zdassets.com/ekr/snippet.js?key=8d4ba5cc-2ac7-4b41-bae1-81c34179ee4c`
      zdWidget.async = 'true'
      zdWidget.charset = 'utf-8'
      //needed for onetrust
      zdWidget.type = 'text/plain'
      zdWidget.className = 'optanon-category-C0003'
      zdWidget.onload = function() {
        if (window.zE && window.location.hash && !window.location.hash.startsWith('#/login')) {
          window.zE('webWidget', 'hide')
        }
      }
      document.head.appendChild(zdWidget)
    }

    if (window.zE) {
      window.zE('webWidget', to.name.startsWith('login') ? 'show' : 'hide')
    }
    next()
  })

  return router
}
