import {
  queryKTSDefaultThumbnail,
  queryKTSListThumbnail,
  saveKTSUpdateThumbnail,
  queryKTSRemoveThumbnail
} from '../services/kts'

const defaultThumbnails = () => ({
  thumbnailList: []
})

const thumbnails = {
  namespaced: true,
  state: defaultThumbnails(),
  mutations: {
    FETCHED_THUMBNAILS(state, thumbnailData) {
      state.thumbnailList = thumbnailData
    },
    RESET_STATE(state) {
      Object.assign(state, defaultThumbnails())
    }
  },
  actions: {
    async fetchThumbnails(context, options) {
      const { id } = options
      const res = await queryKTSListThumbnail({ id })
      context.commit('FETCHED_THUMBNAILS', res.data)
    },
    async setDefaultThumbnail(context, options) {
      const { thumbnailId } = options
      await queryKTSDefaultThumbnail({
        kaltura_id: 1,
        thumbnail_id: thumbnailId
      })
    },
    async removeThumbnail(context, options) {
      const { thumbnailId } = options
      await queryKTSRemoveThumbnail({
        kaltura_id: 1,
        thumbnail_id: thumbnailId
      })
    },
    async uploadThumbnail(context, options) {
      const { id, file } = options
      await saveKTSUpdateThumbnail(id, file)
    }
  },
  getters: {}
}

export default thumbnails
