import { getInstitutions } from '../services/institutions'

export const defaultInstitutions = () => ({
  results: 0,
  data: [
    // Example institution data:
    // "shortName": "Sample U",
    // "name": "Sample University",
    // "id": 24615
  ],
  success: false,
  metaData: {
    totalProperty: '',
    fields: [
      // Example field data:
      // "id",
      // "name"
    ],
    idProperty: '',
    root: '',
    successProperty: ''
  }
})

const institutions = {
  namespaced: true,
  state: defaultInstitutions(),
  mutations: {
    SET_INSTITUTIONS(state, institutionsData) {
      Object.assign(state, { ...institutionsData, data: institutionsData.institutions })
    },
    RESET_STATE(state) {
      Object.assign(state, defaultInstitutions())
    }
  },
  actions: {
    async fetchInstitutions(context) {
      const res = await getInstitutions()
      context.commit('SET_INSTITUTIONS', res.data)
    }
  },
  getters: {
    userInstitutionNamesById(state) {
      if (state.institutions) {
        return state.institutions.reduce((obj, elem) => {
          obj[elem.id] = elem.name
          return obj
        }, {})
      } else {
        return {}
      }
    }
  }
}

export default institutions
