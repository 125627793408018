export default function formatFields(fields) {
  const components = []
  let temp = []
  let perRow = 0
  let current = 0
  for (let i = 0; i < fields.length; i++) {
    perRow = 6 / fields[i].colspan

    if (temp.length !== current && perRow !== current) {
      components.push(temp)
      temp = []
    }

    current = perRow

    const field = fields[i].items[0]
    field.colspan = fields[i].colspan
    temp.push(field)

    if (temp.length === current || i === fields.length - 1) {
      components.push(temp)
      temp = []
      current = 0
    }
  }
  return components
}
